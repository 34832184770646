@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1.5rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, .datepicker .ngb-dp-weekday, h5, .h5, .datepicker .ngb-dp-month-name, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1.5;
}

h1, .h1 {
  font-size: calc(1.405rem + 1.86vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.8rem;
  }
}

h2, .h2 {
  font-size: calc(1.349rem + 1.188vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.24rem;
  }
}

h3, .h3 {
  font-size: calc(1.335rem + 1.02vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2.1rem;
  }
}

h4, .h4 {
  font-size: calc(1.307rem + 0.684vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.82rem;
  }
}

h5, .h5, .datepicker .ngb-dp-month-name {
  font-size: calc(1.286rem + 0.432vw);
}
@media (min-width: 1200px) {
  h5, .h5, .datepicker .ngb-dp-month-name {
    font-size: 1.61rem;
  }
}

h6, .h6, .datepicker .ngb-dp-weekday {
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  h6, .h6, .datepicker .ngb-dp-weekday {
    font-size: 1.4rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: none;
}
a:hover {
  color: shift-color(#0d6efd, 20%);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.4rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

:root {
  --bs-white-red: 255;
  --bs-white-green: 255;
  --bs-white-blue: 255;
  --bs-black-red: 0;
  --bs-black-green: 0;
  --bs-black-blue: 0;
  --bs-white-rgb: var(--bs-white-red), var(--bs-white-green),
      var(--bs-white-blue);
  --bs-white: rgb(var(--bs-white-rgb));
  --bs-black-rgb: var(--bs-black-red), var(--bs-black-green),
      var(--bs-black-blue);
  --bs-black: rgb(var(--bs-black-rgb));
  --bs-gray-100-red: 246;
  --bs-gray-100-green: 246;
  --bs-gray-100-blue: 246;
  --bs-gray-100-rgb: var(--bs-gray-100-red), var(--bs-gray-100-green),
      var(--bs-gray-100-blue);
  --bs-gray-100: rgb(var(--bs-gray-100-rgb));
  --bs-gray-200-red: 231;
  --bs-gray-200-green: 231;
  --bs-gray-200-blue: 231;
  --bs-gray-200-rgb: var(--bs-gray-200-red), var(--bs-gray-200-green),
      var(--bs-gray-200-blue);
  --bs-gray-200: rgb(var(--bs-gray-200-rgb));
  --bs-gray-300-red: 211;
  --bs-gray-300-green: 211;
  --bs-gray-300-blue: 211;
  --bs-gray-300-rgb: var(--bs-gray-300-red), var(--bs-gray-300-green),
      var(--bs-gray-300-blue);
  --bs-gray-300: rgb(var(--bs-gray-300-rgb));
  --bs-gray-400-red: 204;
  --bs-gray-400-green: 204;
  --bs-gray-400-blue: 204;
  --bs-gray-400-rgb: var(--bs-gray-400-red), var(--bs-gray-400-green),
      var(--bs-gray-400-blue);
  --bs-gray-400: rgb(var(--bs-gray-400-rgb));
  --bs-gray-500-red: 166;
  --bs-gray-500-green: 166;
  --bs-gray-500-blue: 166;
  --bs-gray-500-rgb: var(--bs-gray-500-red), var(--bs-gray-500-green),
      var(--bs-gray-500-blue);
  --bs-gray-500: rgb(var(--bs-gray-500-rgb));
  --bs-gray-600-red: 117;
  --bs-gray-600-green: 117;
  --bs-gray-600-blue: 117;
  --bs-gray-600-rgb: var(--bs-gray-600-red), var(--bs-gray-600-green),
      var(--bs-gray-600-blue);
  --bs-gray-600: rgb(var(--bs-gray-600-rgb));
  --bs-gray-700-red: 55;
  --bs-gray-700-green: 55;
  --bs-gray-700-blue: 55;
  --bs-gray-700-rgb: var(--bs-gray-700-red), var(--bs-gray-700-green),
      var(--bs-gray-700-blue);
  --bs-gray-700: rgb(var(--bs-gray-700-rgb));
  --bs-gray-800-red: 41;
  --bs-gray-800-green: 41;
  --bs-gray-800-blue: 41;
  --bs-gray-800-rgb: var(--bs-gray-800-red), var(--bs-gray-800-green),
      var(--bs-gray-800-blue);
  --bs-gray-800: rgb(var(--bs-gray-800-rgb));
  --bs-gray-900-red: 31;
  --bs-gray-900-green: 31;
  --bs-gray-900-blue: 31;
  --bs-gray-900-rgb: var(--bs-gray-900-red), var(--bs-gray-900-green),
      var(--bs-gray-900-blue);
  --bs-gray-900: rgb(var(--bs-gray-900-rgb));
  --bs-light-red: var(--bs-gray-100-red);
  --bs-light-green: var(--bs-gray-100-green);
  --bs-light-blue: var(--bs-gray-100-blue);
  --bs-dark-red: var(--bs-gray-800-red);
  --bs-dark-green: var(--bs-gray-800-green);
  --bs-dark-blue: var(--bs-gray-800-blue);
  --bs-primary-rgb: var(--bs-primary-red), var(--bs-primary-green),
      var(--bs-primary-blue);
  --bs-primary: rgb(var(--bs-primary-rgb));
  --bs-primary-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-primary-red) * 299) +
                                  (var(--bs-primary-green) * 587) +
                                  (var(--bs-primary-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-primary-contrast-rgb: var(--bs-primary-contrast-index),
      var(--bs-primary-contrast-index), var(--bs-primary-contrast-index);
  --bs-primary-contrast: rgb(var(--bs-primary-contrast-rgb));
  --bs-primary-hover-red: calc(var(--bs-primary-red) * var(--bs-hover-shade-modifier));
  --bs-primary-hover-green: calc(var(--bs-primary-green) * var(--bs-hover-shade-modifier));
  --bs-primary-hover-blue: calc(var(--bs-primary-blue) * var(--bs-hover-shade-modifier));
  --bs-primary-hover: rgb(
      var(--bs-primary-hover-red),
      var(--bs-primary-hover-green),
      var(--bs-primary-hover-blue)
  );
  --bs-primary-hover-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-primary-hover-red) * 299) +
                                  (var(--bs-primary-hover-green) * 587) +
                                  (var(--bs-primary-hover-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-primary-hover-contrast-rgb: var(--bs-primary-hover-contrast-index),
      var(--bs-primary-hover-contrast-index), var(--bs-primary-hover-contrast-index);
  --bs-primary-hover-contrast: rgb(var(--bs-primary-hover-contrast-rgb));
  --bs-secondary-rgb: var(--bs-secondary-red), var(--bs-secondary-green),
      var(--bs-secondary-blue);
  --bs-secondary: rgb(var(--bs-secondary-rgb));
  --bs-secondary-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-secondary-red) * 299) +
                                  (var(--bs-secondary-green) * 587) +
                                  (var(--bs-secondary-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-secondary-contrast-rgb: var(--bs-secondary-contrast-index),
      var(--bs-secondary-contrast-index), var(--bs-secondary-contrast-index);
  --bs-secondary-contrast: rgb(var(--bs-secondary-contrast-rgb));
  --bs-secondary-hover-red: calc(var(--bs-secondary-red) * var(--bs-hover-shade-modifier));
  --bs-secondary-hover-green: calc(var(--bs-secondary-green) * var(--bs-hover-shade-modifier));
  --bs-secondary-hover-blue: calc(var(--bs-secondary-blue) * var(--bs-hover-shade-modifier));
  --bs-secondary-hover: rgb(
      var(--bs-secondary-hover-red),
      var(--bs-secondary-hover-green),
      var(--bs-secondary-hover-blue)
  );
  --bs-secondary-hover-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-secondary-hover-red) * 299) +
                                  (var(--bs-secondary-hover-green) * 587) +
                                  (var(--bs-secondary-hover-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-secondary-hover-contrast-rgb: var(--bs-secondary-hover-contrast-index),
      var(--bs-secondary-hover-contrast-index), var(--bs-secondary-hover-contrast-index);
  --bs-secondary-hover-contrast: rgb(var(--bs-secondary-hover-contrast-rgb));
  --bs-success-rgb: var(--bs-success-red), var(--bs-success-green),
      var(--bs-success-blue);
  --bs-success: rgb(var(--bs-success-rgb));
  --bs-success-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-success-red) * 299) +
                                  (var(--bs-success-green) * 587) +
                                  (var(--bs-success-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-success-contrast-rgb: var(--bs-success-contrast-index),
      var(--bs-success-contrast-index), var(--bs-success-contrast-index);
  --bs-success-contrast: rgb(var(--bs-success-contrast-rgb));
  --bs-success-hover-red: calc(var(--bs-success-red) * var(--bs-hover-shade-modifier));
  --bs-success-hover-green: calc(var(--bs-success-green) * var(--bs-hover-shade-modifier));
  --bs-success-hover-blue: calc(var(--bs-success-blue) * var(--bs-hover-shade-modifier));
  --bs-success-hover: rgb(
      var(--bs-success-hover-red),
      var(--bs-success-hover-green),
      var(--bs-success-hover-blue)
  );
  --bs-success-hover-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-success-hover-red) * 299) +
                                  (var(--bs-success-hover-green) * 587) +
                                  (var(--bs-success-hover-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-success-hover-contrast-rgb: var(--bs-success-hover-contrast-index),
      var(--bs-success-hover-contrast-index), var(--bs-success-hover-contrast-index);
  --bs-success-hover-contrast: rgb(var(--bs-success-hover-contrast-rgb));
  --bs-info-rgb: var(--bs-info-red), var(--bs-info-green),
      var(--bs-info-blue);
  --bs-info: rgb(var(--bs-info-rgb));
  --bs-info-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-info-red) * 299) +
                                  (var(--bs-info-green) * 587) +
                                  (var(--bs-info-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-info-contrast-rgb: var(--bs-info-contrast-index),
      var(--bs-info-contrast-index), var(--bs-info-contrast-index);
  --bs-info-contrast: rgb(var(--bs-info-contrast-rgb));
  --bs-info-hover-red: calc(var(--bs-info-red) * var(--bs-hover-shade-modifier));
  --bs-info-hover-green: calc(var(--bs-info-green) * var(--bs-hover-shade-modifier));
  --bs-info-hover-blue: calc(var(--bs-info-blue) * var(--bs-hover-shade-modifier));
  --bs-info-hover: rgb(
      var(--bs-info-hover-red),
      var(--bs-info-hover-green),
      var(--bs-info-hover-blue)
  );
  --bs-info-hover-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-info-hover-red) * 299) +
                                  (var(--bs-info-hover-green) * 587) +
                                  (var(--bs-info-hover-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-info-hover-contrast-rgb: var(--bs-info-hover-contrast-index),
      var(--bs-info-hover-contrast-index), var(--bs-info-hover-contrast-index);
  --bs-info-hover-contrast: rgb(var(--bs-info-hover-contrast-rgb));
  --bs-warning-rgb: var(--bs-warning-red), var(--bs-warning-green),
      var(--bs-warning-blue);
  --bs-warning: rgb(var(--bs-warning-rgb));
  --bs-warning-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-warning-red) * 299) +
                                  (var(--bs-warning-green) * 587) +
                                  (var(--bs-warning-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-warning-contrast-rgb: var(--bs-warning-contrast-index),
      var(--bs-warning-contrast-index), var(--bs-warning-contrast-index);
  --bs-warning-contrast: rgb(var(--bs-warning-contrast-rgb));
  --bs-warning-hover-red: calc(var(--bs-warning-red) * var(--bs-hover-shade-modifier));
  --bs-warning-hover-green: calc(var(--bs-warning-green) * var(--bs-hover-shade-modifier));
  --bs-warning-hover-blue: calc(var(--bs-warning-blue) * var(--bs-hover-shade-modifier));
  --bs-warning-hover: rgb(
      var(--bs-warning-hover-red),
      var(--bs-warning-hover-green),
      var(--bs-warning-hover-blue)
  );
  --bs-warning-hover-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-warning-hover-red) * 299) +
                                  (var(--bs-warning-hover-green) * 587) +
                                  (var(--bs-warning-hover-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-warning-hover-contrast-rgb: var(--bs-warning-hover-contrast-index),
      var(--bs-warning-hover-contrast-index), var(--bs-warning-hover-contrast-index);
  --bs-warning-hover-contrast: rgb(var(--bs-warning-hover-contrast-rgb));
  --bs-danger-rgb: var(--bs-danger-red), var(--bs-danger-green),
      var(--bs-danger-blue);
  --bs-danger: rgb(var(--bs-danger-rgb));
  --bs-danger-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-danger-red) * 299) +
                                  (var(--bs-danger-green) * 587) +
                                  (var(--bs-danger-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-danger-contrast-rgb: var(--bs-danger-contrast-index),
      var(--bs-danger-contrast-index), var(--bs-danger-contrast-index);
  --bs-danger-contrast: rgb(var(--bs-danger-contrast-rgb));
  --bs-danger-hover-red: calc(var(--bs-danger-red) * var(--bs-hover-shade-modifier));
  --bs-danger-hover-green: calc(var(--bs-danger-green) * var(--bs-hover-shade-modifier));
  --bs-danger-hover-blue: calc(var(--bs-danger-blue) * var(--bs-hover-shade-modifier));
  --bs-danger-hover: rgb(
      var(--bs-danger-hover-red),
      var(--bs-danger-hover-green),
      var(--bs-danger-hover-blue)
  );
  --bs-danger-hover-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-danger-hover-red) * 299) +
                                  (var(--bs-danger-hover-green) * 587) +
                                  (var(--bs-danger-hover-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-danger-hover-contrast-rgb: var(--bs-danger-hover-contrast-index),
      var(--bs-danger-hover-contrast-index), var(--bs-danger-hover-contrast-index);
  --bs-danger-hover-contrast: rgb(var(--bs-danger-hover-contrast-rgb));
  --bs-light-rgb: var(--bs-light-red), var(--bs-light-green),
      var(--bs-light-blue);
  --bs-light: rgb(var(--bs-light-rgb));
  --bs-light-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-light-red) * 299) +
                                  (var(--bs-light-green) * 587) +
                                  (var(--bs-light-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-light-contrast-rgb: var(--bs-light-contrast-index),
      var(--bs-light-contrast-index), var(--bs-light-contrast-index);
  --bs-light-contrast: rgb(var(--bs-light-contrast-rgb));
  --bs-light-hover-red: calc(var(--bs-light-red) * var(--bs-hover-shade-modifier));
  --bs-light-hover-green: calc(var(--bs-light-green) * var(--bs-hover-shade-modifier));
  --bs-light-hover-blue: calc(var(--bs-light-blue) * var(--bs-hover-shade-modifier));
  --bs-light-hover: rgb(
      var(--bs-light-hover-red),
      var(--bs-light-hover-green),
      var(--bs-light-hover-blue)
  );
  --bs-light-hover-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-light-hover-red) * 299) +
                                  (var(--bs-light-hover-green) * 587) +
                                  (var(--bs-light-hover-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-light-hover-contrast-rgb: var(--bs-light-hover-contrast-index),
      var(--bs-light-hover-contrast-index), var(--bs-light-hover-contrast-index);
  --bs-light-hover-contrast: rgb(var(--bs-light-hover-contrast-rgb));
  --bs-dark-rgb: var(--bs-dark-red), var(--bs-dark-green),
      var(--bs-dark-blue);
  --bs-dark: rgb(var(--bs-dark-rgb));
  --bs-dark-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-dark-red) * 299) +
                                  (var(--bs-dark-green) * 587) +
                                  (var(--bs-dark-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-dark-contrast-rgb: var(--bs-dark-contrast-index),
      var(--bs-dark-contrast-index), var(--bs-dark-contrast-index);
  --bs-dark-contrast: rgb(var(--bs-dark-contrast-rgb));
  --bs-dark-hover-red: calc(var(--bs-dark-red) * var(--bs-hover-shade-modifier));
  --bs-dark-hover-green: calc(var(--bs-dark-green) * var(--bs-hover-shade-modifier));
  --bs-dark-hover-blue: calc(var(--bs-dark-blue) * var(--bs-hover-shade-modifier));
  --bs-dark-hover: rgb(
      var(--bs-dark-hover-red),
      var(--bs-dark-hover-green),
      var(--bs-dark-hover-blue)
  );
  --bs-dark-hover-contrast-index: calc(
      (
              (
                      (
                              (var(--bs-dark-hover-red) * 299) +
                                  (var(--bs-dark-hover-green) * 587) +
                                  (var(--bs-dark-hover-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --bs-dark-hover-contrast-rgb: var(--bs-dark-hover-contrast-index),
      var(--bs-dark-hover-contrast-index), var(--bs-dark-hover-contrast-index);
  --bs-dark-hover-contrast: rgb(var(--bs-dark-hover-contrast-rgb));
  --bs-primary-light-rgb: var(--bs-primary-light-red), var(--bs-primary-light-green),
      var(--bs-primary-light-blue);
  --bs-primary-light: rgb(var(--bs-primary-light-rgb));
  --bs-primary-light-contrast-rgb: var(--bs-dark-red),
      var(--bs-dark-green),
      var(--bs-dark-blue);
  --bs-primary-light-contrast: rgb(var(--bs-primary-light-contrast-rgb));
  --bs-secondary-light-rgb: var(--bs-secondary-light-red), var(--bs-secondary-light-green),
      var(--bs-secondary-light-blue);
  --bs-secondary-light: rgb(var(--bs-secondary-light-rgb));
  --bs-secondary-light-contrast-rgb: var(--bs-dark-red),
      var(--bs-dark-green),
      var(--bs-dark-blue);
  --bs-secondary-light-contrast: rgb(var(--bs-secondary-light-contrast-rgb));
  --bs-success-light-rgb: var(--bs-success-light-red), var(--bs-success-light-green),
      var(--bs-success-light-blue);
  --bs-success-light: rgb(var(--bs-success-light-rgb));
  --bs-success-light-contrast-rgb: var(--bs-dark-red),
      var(--bs-dark-green),
      var(--bs-dark-blue);
  --bs-success-light-contrast: rgb(var(--bs-success-light-contrast-rgb));
  --bs-info-light-rgb: var(--bs-info-light-red), var(--bs-info-light-green),
      var(--bs-info-light-blue);
  --bs-info-light: rgb(var(--bs-info-light-rgb));
  --bs-info-light-contrast-rgb: var(--bs-dark-red),
      var(--bs-dark-green),
      var(--bs-dark-blue);
  --bs-info-light-contrast: rgb(var(--bs-info-light-contrast-rgb));
  --bs-warning-light-rgb: var(--bs-warning-light-red), var(--bs-warning-light-green),
      var(--bs-warning-light-blue);
  --bs-warning-light: rgb(var(--bs-warning-light-rgb));
  --bs-warning-light-contrast-rgb: var(--bs-dark-red),
      var(--bs-dark-green),
      var(--bs-dark-blue);
  --bs-warning-light-contrast: rgb(var(--bs-warning-light-contrast-rgb));
  --bs-danger-light-rgb: var(--bs-danger-light-red), var(--bs-danger-light-green),
      var(--bs-danger-light-blue);
  --bs-danger-light: rgb(var(--bs-danger-light-rgb));
  --bs-danger-light-contrast-rgb: var(--bs-dark-red),
      var(--bs-dark-green),
      var(--bs-dark-blue);
  --bs-danger-light-contrast: rgb(var(--bs-danger-light-contrast-rgb));
  --bs-primary-dark-rgb: var(--bs-primary-dark-red), var(--bs-primary-dark-green),
      var(--bs-primary-dark-blue);
  --bs-primary-dark: rgb(var(--bs-primary-dark-rgb));
  --bs-primary-dark-contrast-rgb: var(--bs-light-red),
      var(--bs-light-green),
      var(--bs-light-blue);
  --bs-primary-dark-contrast: rgb(var(--bs-primary-dark-contrast-rgb));
  --bs-secondary-dark-rgb: var(--bs-secondary-dark-red), var(--bs-secondary-dark-green),
      var(--bs-secondary-dark-blue);
  --bs-secondary-dark: rgb(var(--bs-secondary-dark-rgb));
  --bs-secondary-dark-contrast-rgb: var(--bs-light-red),
      var(--bs-light-green),
      var(--bs-light-blue);
  --bs-secondary-dark-contrast: rgb(var(--bs-secondary-dark-contrast-rgb));
  --bs-success-dark-rgb: var(--bs-success-dark-red), var(--bs-success-dark-green),
      var(--bs-success-dark-blue);
  --bs-success-dark: rgb(var(--bs-success-dark-rgb));
  --bs-success-dark-contrast-rgb: var(--bs-light-red),
      var(--bs-light-green),
      var(--bs-light-blue);
  --bs-success-dark-contrast: rgb(var(--bs-success-dark-contrast-rgb));
  --bs-info-dark-rgb: var(--bs-info-dark-red), var(--bs-info-dark-green),
      var(--bs-info-dark-blue);
  --bs-info-dark: rgb(var(--bs-info-dark-rgb));
  --bs-info-dark-contrast-rgb: var(--bs-light-red),
      var(--bs-light-green),
      var(--bs-light-blue);
  --bs-info-dark-contrast: rgb(var(--bs-info-dark-contrast-rgb));
  --bs-warning-dark-rgb: var(--bs-warning-dark-red), var(--bs-warning-dark-green),
      var(--bs-warning-dark-blue);
  --bs-warning-dark: rgb(var(--bs-warning-dark-rgb));
  --bs-warning-dark-contrast-rgb: var(--bs-light-red),
      var(--bs-light-green),
      var(--bs-light-blue);
  --bs-warning-dark-contrast: rgb(var(--bs-warning-dark-contrast-rgb));
  --bs-danger-dark-rgb: var(--bs-danger-dark-red), var(--bs-danger-dark-green),
      var(--bs-danger-dark-blue);
  --bs-danger-dark: rgb(var(--bs-danger-dark-rgb));
  --bs-danger-dark-contrast-rgb: var(--bs-light-red),
      var(--bs-light-green),
      var(--bs-light-blue);
  --bs-danger-dark-contrast: rgb(var(--bs-danger-dark-contrast-rgb));
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3745;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-gray: var(--bs-gray-500);
  --bs-blue-contrast: var(--bs-white);
  --bs-indigo-contrast: var(--bs-white);
  --bs-purple-contrast: var(--bs-white);
  --bs-pink-contrast: var(--bs-white);
  --bs-red-contrast: var(--bs-white);
  --bs-orange-contrast: var(--bs-black);
  --bs-yellow-contrast: var(--bs-black);
  --bs-green-contrast: var(--bs-white);
  --bs-teal-contrast: var(--bs-black);
  --bs-cyan-contrast: var(--bs-black);
  --bs-gray-contrast: var(--bs-black);
  --bs-font-sans-serif: "Montserrat", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1.4rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-bg: var(--bs-white);
  --bs-body-bg-rgb: var(--bs-white-rgb);
  --bs-body-bg-muted: var(--bs-gray-100);
  --bs-body-bg-muted-rgb: var(--bs-gray-100-rgb);
  --bs-body-bg-strong: var(--bs-gray-200);
  --bs-body-bg-strong-rgb: var(--bs-gray-200-rgb);
  --bs-body-color: var(--bs-gray-900);
  --bs-body-color-rgb: var(--bs-gray-900-rgb);
  --bs-body-color-muted: var(--bs-gray-600);
  --bs-body-color-muted-rgb: var(--bs-gray-600-rgb);
  --bs-body-color-strong: var(--bs-gray-700);
  --bs-body-color-strong-rgb: var(--bs-gray-700-rgb);
  --bs-link-color: var(--bs-primary);
  --bs-link-color-hover: var(--bs-primary-hover);
  --bs-border-color: var(--bs-gray-300);
  --bs-border-color-muted: var(--bs-gray-200);
  --bs-avatar-group-border-color: var(--bs-body-bg);
  --bs-component-active-bg: var(
      --bs-secondary-light
  );
  --bs-component-active-bg-rgb: var(
      --bs-secondary-light-rgb
  );
  --bs-component-active-color: var(
      --bs-secondary-light-contrast
  );
  --bs-primary-accessible: var(--bs-primary);
  --bs-secondary-accessible: var(--bs-secondary);
  --bs-success-accessible: var(--bs-success);
  --bs-warning-accessible: var(--bs-warning);
  --bs-danger-accessible: var(--bs-danger);
  --bs-info-accessible: var(--bs-info);
  --bs-primary-bg-accessible: var(
      --bs-primary-light
  );
  --bs-secondary-bg-accessible: var(--bs-secondary-light);
  --bs-success-bg-accessible: var(
      --bs-success-light
  );
  --bs-warning-bg-accessible: var(
      --bs-warning-light
  );
  --bs-danger-bg-accessible: var(
      --bs-danger-light
  );
  --bs-info-bg-accessible: var(
      --bs-info-light
  );
  --bs-hover-shade-modifier: 0.8;
  --bs-luminance: 95%;
  --bs-luminance-dark: 20%;
}

:root {
  --clmi-primary-red: 227;
  --clmi-primary-green: 190;
  --clmi-primary-blue: 0;
  --clmi-primary-rgb: var(--clmi-primary-red), var(--clmi-primary-green),
      var(--clmi-primary-blue);
  --clmi-primary: rgb(var(--clmi-primary-rgb));
  --clmi-primary-contrast-index: calc(
      (
              (
                      (
                              (var(--clmi-primary-red) * 299) +
                                  (var(--clmi-primary-green) * 587) +
                                  (var(--clmi-primary-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --clmi-primary-contrast-rgb: var(--clmi-primary-contrast-index),
      var(--clmi-primary-contrast-index), var(--clmi-primary-contrast-index);
  --clmi-primary-contrast: rgb(var(--clmi-primary-contrast-rgb));
  --clmi-primary-hover-red: calc(var(--clmi-primary-red) * var(--bs-hover-shade-modifier));
  --clmi-primary-hover-green: calc(var(--clmi-primary-green) * var(--bs-hover-shade-modifier));
  --clmi-primary-hover-blue: calc(var(--clmi-primary-blue) * var(--bs-hover-shade-modifier));
  --clmi-primary-hover: rgb(
      var(--clmi-primary-hover-red),
      var(--clmi-primary-hover-green),
      var(--clmi-primary-hover-blue)
  );
  --clmi-primary-hover-contrast-index: calc(
      (
              (
                      (
                              (var(--clmi-primary-hover-red) * 299) +
                                  (var(--clmi-primary-hover-green) * 587) +
                                  (var(--clmi-primary-hover-blue) * 114)
                          ) / 1000
                  ) - 128
          ) * -1000
  );
  --clmi-primary-hover-contrast-rgb: var(--clmi-primary-hover-contrast-index),
      var(--clmi-primary-hover-contrast-index), var(--clmi-primary-hover-contrast-index);
  --clmi-primary-hover-contrast: rgb(var(--clmi-primary-hover-contrast-rgb));
}

:root {
  --bs-root-font-size: 50%;
}
@media (min-width: 425px) {
  :root {
    --bs-root-font-size: 50%;
  }
}
@media (min-width: 768px) {
  :root {
    --bs-root-font-size: 56.25%;
  }
}
@media (min-width: 1024px) {
  :root {
    --bs-root-font-size: 56.25%;
  }
}
@media (min-width: 1440px) {
  :root {
    --bs-root-font-size: 62.5%;
  }
}
@media (min-width: 2560px) {
  :root {
    --bs-root-font-size: 62.5%;
  }
}

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
}

a {
  color: var(--bs-link-color);
  font-weight: 600;
}
a:hover {
  color: var(--bs-link-color-hover);
}
a:not([href]) {
  color: inherit;
  text-decoration: none;
  font-weight: inherit;
}
a.link {
  color: var(--bs-link-color);
  font-weight: 600;
}
a.link:hover {
  text-decoration: underline;
}

caption {
  color: var(--bs-body-color-muted);
}

@keyframes dot-loader {
  0% {
    background-color: var(--bs-primary);
  }
  50%, 100% {
    background-color: var(--bs-body-bg-strong);
  }
}
@keyframes skeleton-loader {
  0% {
    left: -100%;
  }
  50% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
@keyframes wobble {
  0% {
    transform: rotate(-1deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(1.5deg);
    animation-timing-function: ease-out;
  }
}
.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1rem);
}
.grid .g-col-1, .grid .global-nav-menu-collapsed .g-collapsed-col-1, .global-nav-menu-collapsed .grid .g-collapsed-col-1 {
  grid-column: auto/span 1;
}
.grid .g-col-2, .grid .global-nav-menu-collapsed .g-collapsed-col-2, .global-nav-menu-collapsed .grid .g-collapsed-col-2 {
  grid-column: auto/span 2;
}
.grid .g-col-3, .grid .global-nav-menu-collapsed .g-collapsed-col-3, .global-nav-menu-collapsed .grid .g-collapsed-col-3 {
  grid-column: auto/span 3;
}
.grid .g-col-4, .grid .global-nav-menu-collapsed .g-collapsed-col-4, .global-nav-menu-collapsed .grid .g-collapsed-col-4 {
  grid-column: auto/span 4;
}
.grid .g-col-5 {
  grid-column: auto/span 5;
}
.grid .g-col-6, .grid .global-nav-menu-collapsed .g-collapsed-col-6, .global-nav-menu-collapsed .grid .g-collapsed-col-6 {
  grid-column: auto/span 6;
}
.grid .g-col-7 {
  grid-column: auto/span 7;
}
.grid .g-col-8 {
  grid-column: auto/span 8;
}
.grid .g-col-9 {
  grid-column: auto/span 9;
}
.grid .g-col-10 {
  grid-column: auto/span 10;
}
.grid .g-col-11 {
  grid-column: auto/span 11;
}
.grid .g-col-12, .grid .global-nav-menu-collapsed .g-collapsed-col-12, .global-nav-menu-collapsed .grid .g-collapsed-col-12 {
  grid-column: auto/span 12;
}
.grid .g-start-1 {
  grid-column-start: 1;
}
.grid .g-start-2 {
  grid-column-start: 2;
}
.grid .g-start-3 {
  grid-column-start: 3;
}
.grid .g-start-4 {
  grid-column-start: 4;
}
.grid .g-start-5 {
  grid-column-start: 5;
}
.grid .g-start-6 {
  grid-column-start: 6;
}
.grid .g-start-7 {
  grid-column-start: 7;
}
.grid .g-start-8 {
  grid-column-start: 8;
}
.grid .g-start-9 {
  grid-column-start: 9;
}
.grid .g-start-10 {
  grid-column-start: 10;
}
.grid .g-start-11 {
  grid-column-start: 11;
}
@media (min-width: 425px) {
  .grid .g-col-sm-1, .grid .global-nav-menu-collapsed .g-collapsed-col-sm-1, .global-nav-menu-collapsed .grid .g-collapsed-col-sm-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-sm-2, .grid .global-nav-menu-collapsed .g-collapsed-col-sm-2, .global-nav-menu-collapsed .grid .g-collapsed-col-sm-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-sm-3, .grid .global-nav-menu-collapsed .g-collapsed-col-sm-3, .global-nav-menu-collapsed .grid .g-collapsed-col-sm-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-sm-4, .grid .global-nav-menu-collapsed .g-collapsed-col-sm-4, .global-nav-menu-collapsed .grid .g-collapsed-col-sm-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-sm-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-sm-6, .grid .global-nav-menu-collapsed .g-collapsed-col-sm-6, .global-nav-menu-collapsed .grid .g-collapsed-col-sm-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-sm-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-sm-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-sm-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-sm-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-sm-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-sm-12, .grid .global-nav-menu-collapsed .g-collapsed-col-sm-12, .global-nav-menu-collapsed .grid .g-collapsed-col-sm-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }
  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }
  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }
  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }
  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }
  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }
  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }
  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }
  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }
  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }
  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 768px) {
  .grid .g-col-md-1, .grid .global-nav-menu-collapsed .g-collapsed-col-md-1, .global-nav-menu-collapsed .grid .g-collapsed-col-md-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-md-2, .grid .global-nav-menu-collapsed .g-collapsed-col-md-2, .global-nav-menu-collapsed .grid .g-collapsed-col-md-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-md-3, .grid .global-nav-menu-collapsed .g-collapsed-col-md-3, .global-nav-menu-collapsed .grid .g-collapsed-col-md-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-md-4, .grid .global-nav-menu-collapsed .g-collapsed-col-md-4, .global-nav-menu-collapsed .grid .g-collapsed-col-md-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-md-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-md-6, .grid .global-nav-menu-collapsed .g-collapsed-col-md-6, .global-nav-menu-collapsed .grid .g-collapsed-col-md-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-md-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-md-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-md-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-md-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-md-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-md-12, .grid .global-nav-menu-collapsed .g-collapsed-col-md-12, .global-nav-menu-collapsed .grid .g-collapsed-col-md-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-md-1 {
    grid-column-start: 1;
  }
  .grid .g-start-md-2 {
    grid-column-start: 2;
  }
  .grid .g-start-md-3 {
    grid-column-start: 3;
  }
  .grid .g-start-md-4 {
    grid-column-start: 4;
  }
  .grid .g-start-md-5 {
    grid-column-start: 5;
  }
  .grid .g-start-md-6 {
    grid-column-start: 6;
  }
  .grid .g-start-md-7 {
    grid-column-start: 7;
  }
  .grid .g-start-md-8 {
    grid-column-start: 8;
  }
  .grid .g-start-md-9 {
    grid-column-start: 9;
  }
  .grid .g-start-md-10 {
    grid-column-start: 10;
  }
  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1024px) {
  .grid .g-col-lg-1, .grid .global-nav-menu-collapsed .g-collapsed-col-lg-1, .global-nav-menu-collapsed .grid .g-collapsed-col-lg-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-lg-2, .grid .global-nav-menu-collapsed .g-collapsed-col-lg-2, .global-nav-menu-collapsed .grid .g-collapsed-col-lg-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-lg-3, .grid .global-nav-menu-collapsed .g-collapsed-col-lg-3, .global-nav-menu-collapsed .grid .g-collapsed-col-lg-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-lg-4, .grid .global-nav-menu-collapsed .g-collapsed-col-lg-4, .global-nav-menu-collapsed .grid .g-collapsed-col-lg-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-lg-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-lg-6, .grid .global-nav-menu-collapsed .g-collapsed-col-lg-6, .global-nav-menu-collapsed .grid .g-collapsed-col-lg-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-lg-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-lg-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-lg-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-lg-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-lg-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-lg-12, .grid .global-nav-menu-collapsed .g-collapsed-col-lg-12, .global-nav-menu-collapsed .grid .g-collapsed-col-lg-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }
  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }
  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }
  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }
  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }
  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }
  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }
  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }
  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }
  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }
  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1440px) {
  .grid .g-col-xl-1, .grid .global-nav-menu-collapsed .g-collapsed-col-xl-1, .global-nav-menu-collapsed .grid .g-collapsed-col-xl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xl-2, .grid .global-nav-menu-collapsed .g-collapsed-col-xl-2, .global-nav-menu-collapsed .grid .g-collapsed-col-xl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xl-3, .grid .global-nav-menu-collapsed .g-collapsed-col-xl-3, .global-nav-menu-collapsed .grid .g-collapsed-col-xl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xl-4, .grid .global-nav-menu-collapsed .g-collapsed-col-xl-4, .global-nav-menu-collapsed .grid .g-collapsed-col-xl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xl-6, .grid .global-nav-menu-collapsed .g-collapsed-col-xl-6, .global-nav-menu-collapsed .grid .g-collapsed-col-xl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xl-12, .grid .global-nav-menu-collapsed .g-collapsed-col-xl-12, .global-nav-menu-collapsed .grid .g-collapsed-col-xl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 2560px) {
  .grid .g-col-xxl-1, .grid .global-nav-menu-collapsed .g-collapsed-col-xxl-1, .global-nav-menu-collapsed .grid .g-collapsed-col-xxl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xxl-2, .grid .global-nav-menu-collapsed .g-collapsed-col-xxl-2, .global-nav-menu-collapsed .grid .g-collapsed-col-xxl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xxl-3, .grid .global-nav-menu-collapsed .g-collapsed-col-xxl-3, .global-nav-menu-collapsed .grid .g-collapsed-col-xxl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xxl-4, .grid .global-nav-menu-collapsed .g-collapsed-col-xxl-4, .global-nav-menu-collapsed .grid .g-collapsed-col-xxl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xxl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xxl-6, .grid .global-nav-menu-collapsed .g-collapsed-col-xxl-6, .global-nav-menu-collapsed .grid .g-collapsed-col-xxl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xxl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xxl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xxl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xxl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xxl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xxl-12, .grid .global-nav-menu-collapsed .g-collapsed-col-xxl-12, .global-nav-menu-collapsed .grid .g-collapsed-col-xxl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}

.grid {
  --bs-columns: 12;
  --bs-rows: null;
}

.grid-auto-rows {
  grid-template-rows: none;
  grid-auto-rows: minmax(min-content, max-content);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: calc(1.265rem + 0.18vw);
  color: var(--bs-body-color);
  text-align: left;
  background-color: var(--bs-body-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (min-width: 1200px) {
  .accordion-button {
    font-size: 1.4rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.4rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: var(--bs-body-bg);
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.6rem - 1px);
  border-top-right-radius: calc(0.6rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.6rem - 1px);
  border-bottom-left-radius: calc(0.6rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse, .accordion-widget .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item, .accordion-widget .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child, .accordion-widget .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child, .accordion-widget .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-widget .accordion-item .accordion-button {
  border-radius: 0;
}

.accordion-button::after {
  margin-left: 1.25rem;
}
.accordion-button.disabled::after {
  display: none;
}
.accordion-button::after {
  content: "\f078";
  font-family: "Font Awesome 6 Pro" !important;
  font-weight: 300;
  font-size: 1.4rem;
  color: inherit;
  background: rgba(0, 0, 0, 0);
  height: unset;
  width: unset;
}
.accordion-button:not(.collapsed)::after {
  background: rgba(0, 0, 0, 0);
}

.accordion-footer {
  display: none;
}
.accordion-footer.show {
  display: block;
}
.accordion-footer .accordion-button {
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-footer .accordion-button::after {
  transform: rotate(270deg);
}

.accordion-widget .accordion-button,
.accordion-widget .accordion-body {
  padding: 1.5rem 2rem;
}
.accordion-widget .widget.widget-body, .accordion-widget .widget.widget-body .widget.widget-body,
.accordion-widget .widget-body .widget.widget-body,
.accordion-widget .widget-body .widget.widget-body,
.accordion-widget .widget-body,
.accordion-widget .widget.widget-body .widget-body,
.accordion-widget .widget-body .widget.widget-body,
.accordion-widget .widget-body .widget-body {
  padding: 0;
}
.alert {
  --alert-bg: var(--bs-body-bg-muted);
  --alert-color: var(--bs-body-color);
  --alert-border-color: rgba(0, 0, 0, 0);
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--alert-border-color);
  color: var(--alert-color);
  background-color: var(--alert-bg);
  border-radius: 0.6rem;
}
.alert .alert-link {
  color: var(--alert-color);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --alert-bg: var(--bs-primary);
  --alert-color: var(--bs-primary-contrast);
  --alert-border-color: var(--bs-primary);
}

.alert-secondary {
  --alert-bg: var(--bs-secondary);
  --alert-color: var(--bs-secondary-contrast);
  --alert-border-color: var(--bs-secondary);
}

.alert-success {
  --alert-bg: var(--bs-success);
  --alert-color: var(--bs-success-contrast);
  --alert-border-color: var(--bs-success);
}

.alert-info {
  --alert-bg: var(--bs-info);
  --alert-color: var(--bs-info-contrast);
  --alert-border-color: var(--bs-info);
}

.alert-warning {
  --alert-bg: var(--bs-warning);
  --alert-color: var(--bs-warning-contrast);
  --alert-border-color: var(--bs-warning);
}

.alert-danger {
  --alert-bg: var(--bs-danger);
  --alert-color: var(--bs-danger-contrast);
  --alert-border-color: var(--bs-danger);
}

.alert-light {
  --alert-bg: var(--bs-light);
  --alert-color: var(--bs-light-contrast);
  --alert-border-color: var(--bs-light);
}

.alert-dark {
  --alert-bg: var(--bs-dark);
  --alert-color: var(--bs-dark-contrast);
  --alert-border-color: var(--bs-dark);
}

.alert-primary-light {
  --alert-bg: var(--bs-primary-light);
  --alert-color: var(--bs-primary-light-contrast);
  --alert-border-color: var(--bs-primary-light);
}

.alert-secondary-light {
  --alert-bg: var(--bs-secondary-light);
  --alert-color: var(--bs-secondary-light-contrast);
  --alert-border-color: var(--bs-secondary-light);
}

.alert-success-light {
  --alert-bg: var(--bs-success-light);
  --alert-color: var(--bs-success-light-contrast);
  --alert-border-color: var(--bs-success-light);
}

.alert-info-light {
  --alert-bg: var(--bs-info-light);
  --alert-color: var(--bs-info-light-contrast);
  --alert-border-color: var(--bs-info-light);
}

.alert-warning-light {
  --alert-bg: var(--bs-warning-light);
  --alert-color: var(--bs-warning-light-contrast);
  --alert-border-color: var(--bs-warning-light);
}

.alert-danger-light {
  --alert-bg: var(--bs-danger-light);
  --alert-color: var(--bs-danger-light-contrast);
  --alert-border-color: var(--bs-danger-light);
}

.alert-primary-dark {
  --alert-bg: var(--bs-primary-dark);
  --alert-color: var(--bs-primary-dark-contrast);
  --alert-border-color: var(--bs-primary-dark);
}

.alert-secondary-dark {
  --alert-bg: var(--bs-secondary-dark);
  --alert-color: var(--bs-secondary-dark-contrast);
  --alert-border-color: var(--bs-secondary-dark);
}

.alert-success-dark {
  --alert-bg: var(--bs-success-dark);
  --alert-color: var(--bs-success-dark-contrast);
  --alert-border-color: var(--bs-success-dark);
}

.alert-info-dark {
  --alert-bg: var(--bs-info-dark);
  --alert-color: var(--bs-info-dark-contrast);
  --alert-border-color: var(--bs-info-dark);
}

.alert-warning-dark {
  --alert-bg: var(--bs-warning-dark);
  --alert-color: var(--bs-warning-dark-contrast);
  --alert-border-color: var(--bs-warning-dark);
}

.alert-danger-dark {
  --alert-bg: var(--bs-danger-dark);
  --alert-color: var(--bs-danger-dark-contrast);
  --alert-border-color: var(--bs-danger-dark);
}

.avatar {
  --avatar-size: 3.6rem;
  --avatar-alt-initials-font-size: 1.4rem;
  --avatar-alt-text-font-size: 0.798rem;
  position: relative;
  z-index: 0;
  text-align: center;
  background-color: var(--bs-body-bg-strong);
  color: var(--bs-body-color-strong);
  font-weight: 500;
  overflow: hidden;
  border-radius: 100%;
  height: var(--avatar-size);
  width: var(--avatar-size);
}
.avatar .avatar-alt-initials {
  font-size: var(--avatar-alt-initials-font-size);
}
.avatar .avatar-alt-text {
  font-size: var(--avatar-alt-text-font-size);
}
.avatar.avatar-xxs {
  --avatar-size: 1.6rem;
  --avatar-alt-initials-font-size: ;
  --avatar-alt-text-font-size: ;
}
.avatar.avatar-xs {
  --avatar-size: 2rem;
  --avatar-alt-initials-font-size: 0.8rem;
  --avatar-alt-text-font-size: 0.49rem;
}
.avatar.avatar-sm {
  --avatar-size: 2.6rem;
  --avatar-alt-initials-font-size: 1rem;
  --avatar-alt-text-font-size: 0.588rem;
}
.avatar.avatar-md {
  --avatar-size: 3.6rem;
  --avatar-alt-initials-font-size: 1.4rem;
  --avatar-alt-text-font-size: 0.798rem;
}
.avatar.avatar-lg {
  --avatar-size: 4.6rem;
  --avatar-alt-initials-font-size: 1.8rem;
  --avatar-alt-text-font-size: 1.4rem;
}
.avatar.avatar-xl {
  --avatar-size: 7rem;
  --avatar-alt-initials-font-size: 2.8rem;
  --avatar-alt-text-font-size: 1.4rem;
}
.avatar.avatar-xxl {
  --avatar-size: 10rem;
  --avatar-alt-initials-font-size: 4rem;
  --avatar-alt-text-font-size: 1.4rem;
}
.avatar.avatar-xxxl {
  --avatar-size: 14rem;
  --avatar-alt-initials-font-size: 6rem;
  --avatar-alt-text-font-size: 1.4rem;
}
.avatar-alt-text, .avatar-alt-icon, .avatar-alt-initials {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.avatar-image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.avatar-group-xxs > *:not(:first-child) {
  margin-left: calc(1.6rem * 0.25 * -1);
}
.avatar-group-xxs.fixed-width {
  width: auto;
  min-width: calc((1.6rem + 2px * (2 + 1)) * 3 - 1.6rem * 0.25 * 2);
}
.avatar-group-xs > *:not(:first-child) {
  margin-left: calc(2rem * 0.25 * -1);
}
.avatar-group-xs.fixed-width {
  width: auto;
  min-width: calc((2rem + 2px * (2 + 1)) * 3 - 2rem * 0.25 * 2);
}
.avatar-group-sm > *:not(:first-child) {
  margin-left: calc(2.6rem * 0.25 * -1);
}
.avatar-group-sm.fixed-width {
  width: auto;
  min-width: calc((2.6rem + 2px * (2 + 1)) * 3 - 2.6rem * 0.25 * 2);
}
.avatar-group-md > *:not(:first-child) {
  margin-left: calc(3.6rem * 0.25 * -1);
}
.avatar-group-md.fixed-width {
  width: auto;
  min-width: calc((3.6rem + 2px * (2 + 1)) * 3 - 3.6rem * 0.25 * 2);
}
.avatar-group-lg > *:not(:first-child) {
  margin-left: calc(4.6rem * 0.25 * -1);
}
.avatar-group-lg.fixed-width {
  width: auto;
  min-width: calc((4.6rem + 2px * (2 + 1)) * 3 - 4.6rem * 0.25 * 2);
}
.avatar-group-xl > *:not(:first-child) {
  margin-left: calc(7rem * 0.25 * -1);
}
.avatar-group-xl.fixed-width {
  width: auto;
  min-width: calc((7rem + 2px * (2 + 1)) * 3 - 7rem * 0.25 * 2);
}
.avatar-group-xxl > *:not(:first-child) {
  margin-left: calc(10rem * 0.25 * -1);
}
.avatar-group-xxl.fixed-width {
  width: auto;
  min-width: calc((10rem + 2px * (2 + 1)) * 3 - 10rem * 0.25 * 2);
}
.avatar-group-xxxl > *:not(:first-child) {
  margin-left: calc(14rem * 0.25 * -1);
}
.avatar-group-xxxl.fixed-width {
  width: auto;
  min-width: calc((14rem + 2px * (2 + 1)) * 3 - 14rem * 0.25 * 2);
}
.avatar-group-avatar {
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .avatar-group-avatar {
    transition: none;
  }
}
.avatar-group-avatar:first-child {
  margin-left: -2px;
}
.avatar-group-avatar:hover {
  z-index: 999 !important;
  transform: scale(1.1);
}
.avatar-group-avatar .avatar {
  border: 2px solid var(--bs-avatar-group-border-color);
  box-sizing: content-box;
}

.avatar-group-container {
  margin: -2px;
  overflow: visible;
}

.avatar-group-border-transparent {
  border: 2px solid transparent;
}

.avatar-info {
  min-width: 30rem;
}

.badge {
  display: inline-block;
  padding: 0.3em 0.5em;
  font-size: 0.85em;
  font-weight: 500;
  line-height: 1;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.6rem;
}
.badge:empty {
  display: none;
}

.btn .badge, .modal-header .close .badge, .datepicker .form-select .badge {
  position: relative;
  top: -1px;
}

.badge {
  background-color: rgba(0, 0, 0, 0);
  border: 0.1em solid var(--bs-body-color);
}

.badge-primary {
  background-color: var(--bs-primary);
  color: var(--bs-primary-contrast);
  border-color: var(--bs-primary);
}

.badge-secondary {
  background-color: var(--bs-secondary);
  color: var(--bs-secondary-contrast);
  border-color: var(--bs-secondary);
}

.badge-success {
  background-color: var(--bs-success);
  color: var(--bs-success-contrast);
  border-color: var(--bs-success);
}

.badge-info {
  background-color: var(--bs-info);
  color: var(--bs-info-contrast);
  border-color: var(--bs-info);
}

.badge-warning {
  background-color: var(--bs-warning);
  color: var(--bs-warning-contrast);
  border-color: var(--bs-warning);
}

.badge-danger {
  background-color: var(--bs-danger);
  color: var(--bs-danger-contrast);
  border-color: var(--bs-danger);
}

.badge-light {
  background-color: var(--bs-light);
  color: var(--bs-light-contrast);
  border-color: var(--bs-light);
}

.badge-dark {
  background-color: var(--bs-dark);
  color: var(--bs-dark-contrast);
  border-color: var(--bs-dark);
}

.badge-primary-light {
  background-color: var(--bs-primary-light);
  color: var(--bs-primary-light-contrast);
  border-color: var(--bs-primary-light);
}

.badge-secondary-light {
  background-color: var(--bs-secondary-light);
  color: var(--bs-secondary-light-contrast);
  border-color: var(--bs-secondary-light);
}

.badge-success-light {
  background-color: var(--bs-success-light);
  color: var(--bs-success-light-contrast);
  border-color: var(--bs-success-light);
}

.badge-info-light {
  background-color: var(--bs-info-light);
  color: var(--bs-info-light-contrast);
  border-color: var(--bs-info-light);
}

.badge-warning-light {
  background-color: var(--bs-warning-light);
  color: var(--bs-warning-light-contrast);
  border-color: var(--bs-warning-light);
}

.badge-danger-light {
  background-color: var(--bs-danger-light);
  color: var(--bs-danger-light-contrast);
  border-color: var(--bs-danger-light);
}

.badge-primary-dark {
  background-color: var(--bs-primary-dark);
  color: var(--bs-primary-dark-contrast);
  border-color: var(--bs-primary-dark);
}

.badge-secondary-dark {
  background-color: var(--bs-secondary-dark);
  color: var(--bs-secondary-dark-contrast);
  border-color: var(--bs-secondary-dark);
}

.badge-success-dark {
  background-color: var(--bs-success-dark);
  color: var(--bs-success-dark-contrast);
  border-color: var(--bs-success-dark);
}

.badge-info-dark {
  background-color: var(--bs-info-dark);
  color: var(--bs-info-dark-contrast);
  border-color: var(--bs-info-dark);
}

.badge-warning-dark {
  background-color: var(--bs-warning-dark);
  color: var(--bs-warning-dark-contrast);
  border-color: var(--bs-warning-dark);
}

.badge-danger-dark {
  background-color: var(--bs-danger-dark);
  color: var(--bs-danger-dark-contrast);
  border-color: var(--bs-danger-dark);
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: var(--bs-body-color-muted);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-body-color-muted);
}

.btn, .modal-header .close, .datepicker .form-select {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.6rem 1.2rem;
  font-size: calc(1.265rem + 0.18vw);
  border-radius: 0.6rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .btn, .modal-header .close, .datepicker .form-select {
    font-size: 1.4rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn, .modal-header .close, .datepicker .form-select {
    transition: none;
  }
}
.btn:hover, .modal-header .close:hover, .datepicker .form-select:hover {
  text-decoration: none;
}
.btn-check:focus + .btn, .modal-header .btn-check:focus + .close, .datepicker .btn-check:focus + .form-select, .btn:focus, .modal-header .close:focus, .datepicker .form-select:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}
.btn-check:checked + .btn, .modal-header .btn-check:checked + .close, .datepicker .btn-check:checked + .form-select, .btn-check:active + .btn, .modal-header .btn-check:active + .close, .datepicker .btn-check:active + .form-select, .btn:active, .modal-header .close:active, .datepicker .form-select:active, .btn.active, .modal-header .active.close, .datepicker .active.form-select {
  box-shadow: inset 0 3px 5px rgba(var(--bs-black-rgb), 0.125);
}
.btn-check:checked + .btn:focus, .modal-header .btn-check:checked + .close:focus, .datepicker .btn-check:checked + .form-select:focus, .btn-check:active + .btn:focus, .modal-header .btn-check:active + .close:focus, .datepicker .btn-check:active + .form-select:focus, .btn:active:focus, .modal-header .close:active:focus, .datepicker .form-select:active:focus, .btn.active:focus, .modal-header .active.close:focus, .datepicker .active.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25), inset 0 3px 5px rgba(var(--bs-black-rgb), 0.125);
}
.btn:disabled, .modal-header .close:disabled, .datepicker .form-select:disabled, .btn.disabled, .modal-header .disabled.close, .datepicker .disabled.form-select, fieldset:disabled .btn, fieldset:disabled .modal-header .close, .modal-header fieldset:disabled .close, fieldset:disabled .datepicker .form-select, .datepicker fieldset:disabled .form-select {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary, .modal-header .close {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: var(--bs-primary-contrast);
}
.btn-primary:hover, .modal-header .close:hover {
  background-color: var(--bs-primary-hover);
  border-color: var(--bs-primary-hover);
  color: var(--bs-primary-hover-contrast);
}

.btn-outline-primary {
  background-color: var(--bs-body-bg);
  border-color: var(--bs-primary-accessible);
  color: var(--bs-primary-accessible);
}
.btn-outline-primary:hover {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
  color: var(--bs-primary-contrast);
}

.btn-secondary {
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
  color: var(--bs-secondary-contrast);
}
.btn-secondary:hover {
  background-color: var(--bs-secondary-hover);
  border-color: var(--bs-secondary-hover);
  color: var(--bs-secondary-hover-contrast);
}

.btn-outline-secondary {
  background-color: var(--bs-body-bg);
  border-color: var(--bs-secondary-accessible);
  color: var(--bs-secondary-accessible);
}
.btn-outline-secondary:hover {
  border-color: var(--bs-secondary);
  background-color: var(--bs-secondary);
  color: var(--bs-secondary-contrast);
}

.btn-success {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
  color: var(--bs-success-contrast);
}
.btn-success:hover {
  background-color: var(--bs-success-hover);
  border-color: var(--bs-success-hover);
  color: var(--bs-success-hover-contrast);
}

.btn-outline-success {
  background-color: var(--bs-body-bg);
  border-color: var(--bs-success-accessible);
  color: var(--bs-success-accessible);
}
.btn-outline-success:hover {
  border-color: var(--bs-success);
  background-color: var(--bs-success);
  color: var(--bs-success-contrast);
}

.btn-info {
  background-color: var(--bs-info);
  border-color: var(--bs-info);
  color: var(--bs-info-contrast);
}
.btn-info:hover {
  background-color: var(--bs-info-hover);
  border-color: var(--bs-info-hover);
  color: var(--bs-info-hover-contrast);
}

.btn-outline-info {
  background-color: var(--bs-body-bg);
  border-color: var(--bs-info-accessible);
  color: var(--bs-info-accessible);
}
.btn-outline-info:hover {
  border-color: var(--bs-info);
  background-color: var(--bs-info);
  color: var(--bs-info-contrast);
}

.btn-warning {
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
  color: var(--bs-warning-contrast);
}
.btn-warning:hover {
  background-color: var(--bs-warning-hover);
  border-color: var(--bs-warning-hover);
  color: var(--bs-warning-hover-contrast);
}

.btn-outline-warning {
  background-color: var(--bs-body-bg);
  border-color: var(--bs-warning-accessible);
  color: var(--bs-warning-accessible);
}
.btn-outline-warning:hover {
  border-color: var(--bs-warning);
  background-color: var(--bs-warning);
  color: var(--bs-warning-contrast);
}

.btn-danger {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
  color: var(--bs-danger-contrast);
}
.btn-danger:hover {
  background-color: var(--bs-danger-hover);
  border-color: var(--bs-danger-hover);
  color: var(--bs-danger-hover-contrast);
}

.btn-outline-danger {
  background-color: var(--bs-body-bg);
  border-color: var(--bs-danger-accessible);
  color: var(--bs-danger-accessible);
}
.btn-outline-danger:hover {
  border-color: var(--bs-danger);
  background-color: var(--bs-danger);
  color: var(--bs-danger-contrast);
}

.btn-light {
  background-color: var(--bs-light);
  border-color: var(--bs-light);
  color: var(--bs-light-contrast);
}
.btn-light:hover {
  background-color: var(--bs-light-hover);
  border-color: var(--bs-light-hover);
  color: var(--bs-light-hover-contrast);
}

.btn-outline-light {
  background-color: var(--bs-body-bg);
}
.btn-outline-light:hover {
  border-color: var(--bs-light);
  background-color: var(--bs-light);
  color: var(--bs-light-contrast);
}

.btn-dark {
  background-color: var(--bs-dark);
  border-color: var(--bs-dark);
  color: var(--bs-dark-contrast);
}
.btn-dark:hover {
  background-color: var(--bs-dark-hover);
  border-color: var(--bs-dark-hover);
  color: var(--bs-dark-hover-contrast);
}

.btn-outline-dark {
  background-color: var(--bs-body-bg);
}
.btn-outline-dark:hover {
  border-color: var(--bs-dark);
  background-color: var(--bs-dark);
  color: var(--bs-dark-contrast);
}

.btn-inline {
  --btn-inline-max-width: 30rem;
  font-size: inherit;
  line-height: inherit;
  vertical-align: baseline;
  padding: 0px;
  margin: 0px;
  max-width: var(--btn-inline-max-width);
}

.btn-lg, .btn-group-lg > .btn, .modal-header .btn-group-lg > .close, .datepicker .btn-group-lg > .form-select {
  padding: 0.5rem 1rem;
  font-size: calc(1.3rem + 0.6vw);
  border-radius: 0.6rem;
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn, .modal-header .btn-group-lg > .close, .datepicker .btn-group-lg > .form-select {
    font-size: 1.75rem;
  }
}

.btn-sm, .btn-group-sm > .btn, .modal-header .btn-group-sm > .close, .datepicker .btn-group-sm > .form-select {
  padding: 0.25rem 0.5rem;
  font-size: 1.225rem;
  border-radius: 0.4rem;
}

.btn-link, .ngb-tp .btn-outline-primary:not(:disabled):not(.disabled), .datepicker .form-select, .btn-inline {
  font-weight: 600;
  color: var(--bs-link-color);
  text-decoration: none;
  padding: 0;
  border: none;
  border-radius: 0;
}
.btn-link:hover, .ngb-tp .btn-outline-primary:hover:not(:disabled):not(.disabled), .datepicker .form-select:hover, .btn-inline:hover {
  color: var(--bs-link-color-hover);
  text-decoration: underline;
}
.btn-link:focus, .ngb-tp .btn-outline-primary:focus:not(:disabled):not(.disabled), .datepicker .form-select:focus, .btn-inline:focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .ngb-tp .btn-outline-primary:disabled:not(:disabled):not(.disabled), .datepicker .form-select:disabled, .btn-inline:disabled, .btn-link.disabled, .ngb-tp .disabled.btn-outline-primary:not(:disabled):not(.disabled), .datepicker .disabled.form-select, .disabled.btn-inline {
  color: var(--bs-body-color-muted);
}
.btn-link.active, .ngb-tp .active.btn-outline-primary:not(:disabled):not(.disabled), .datepicker .active.form-select, .active.btn-inline {
  box-shadow: none;
}

.btn-icon {
  padding: 0;
  margin: 0;
  border-radius: 100%;
  overflow: hidden;
}

[wdxIconButton] {
  --icon-size: 1.6rem;
  --icon-container-size: 3.6rem;
  --fa-display: block;
  font-size: var(--icon-size);
  height: var(--icon-container-size);
  width: var(--icon-container-size);
  display: flex;
  align-items: center;
  justify-content: center;
}
[wdxIconButton].btn-sm, .btn-group-sm > [wdxIconButton].btn, .modal-header .btn-group-sm > [wdxIconButton].close, .datepicker .btn-group-sm > [wdxIconButton].form-select {
  --icon-size: 1.4rem;
  --icon-container-size: 2.6rem;
}
[wdxIconButton].btn-lg, .btn-group-lg > [wdxIconButton].btn, .modal-header .btn-group-lg > [wdxIconButton].close, .datepicker .btn-group-lg > [wdxIconButton].form-select {
  --icon-size: 2.4rem;
  --icon-container-size: 4.6rem;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .modal-header .btn-group > .close, .datepicker .btn-group > .form-select,
.btn-group-vertical > .btn,
.modal-header .btn-group-vertical > .close,
.datepicker .btn-group-vertical > .form-select {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn, .modal-header .btn-group > .btn-check:checked + .close, .datepicker .btn-group > .btn-check:checked + .form-select,
.btn-group > .btn-check:focus + .btn,
.modal-header .btn-group > .btn-check:focus + .close,
.datepicker .btn-group > .btn-check:focus + .form-select,
.btn-group > .btn:hover,
.modal-header .btn-group > .close:hover,
.datepicker .btn-group > .form-select:hover,
.btn-group > .btn:focus,
.modal-header .btn-group > .close:focus,
.datepicker .btn-group > .form-select:focus,
.btn-group > .btn:active,
.modal-header .btn-group > .close:active,
.datepicker .btn-group > .form-select:active,
.btn-group > .btn.active,
.modal-header .btn-group > .active.close,
.datepicker .btn-group > .active.form-select,
.btn-group-vertical > .btn-check:checked + .btn,
.modal-header .btn-group-vertical > .btn-check:checked + .close,
.datepicker .btn-group-vertical > .btn-check:checked + .form-select,
.btn-group-vertical > .btn-check:focus + .btn,
.modal-header .btn-group-vertical > .btn-check:focus + .close,
.datepicker .btn-group-vertical > .btn-check:focus + .form-select,
.btn-group-vertical > .btn:hover,
.modal-header .btn-group-vertical > .close:hover,
.datepicker .btn-group-vertical > .form-select:hover,
.btn-group-vertical > .btn:focus,
.modal-header .btn-group-vertical > .close:focus,
.datepicker .btn-group-vertical > .form-select:focus,
.btn-group-vertical > .btn:active,
.modal-header .btn-group-vertical > .close:active,
.datepicker .btn-group-vertical > .form-select:active,
.btn-group-vertical > .btn.active,
.modal-header .btn-group-vertical > .active.close,
.datepicker .btn-group-vertical > .active.form-select {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .modal-header .btn-group > .close:not(:first-child), .datepicker .btn-group > .form-select:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .modal-header .btn-group > .close:not(:last-child):not(.dropdown-toggle), .datepicker .btn-group > .form-select:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.modal-header .btn-group > .btn-group:not(:last-child) > .close,
.datepicker .btn-group > .btn-group:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3), .modal-header .btn-group > .close:nth-child(n+3), .datepicker .btn-group > .form-select:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.modal-header .btn-group > :not(.btn-check) + .close,
.datepicker .btn-group > :not(.btn-check) + .form-select,
.btn-group > .btn-group:not(:first-child) > .btn,
.modal-header .btn-group > .btn-group:not(:first-child) > .close,
.datepicker .btn-group > .btn-group:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9rem;
  padding-left: 0.9rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .modal-header .btn-group-sm > .close + .dropdown-toggle-split, .datepicker .btn-group-sm > .form-select + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .modal-header .btn-group-lg > .close + .dropdown-toggle-split, .datepicker .btn-group-lg > .form-select + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(var(--bs-black-rgb), 0.125);
}
.btn-group.show .dropdown-toggle.btn-link, .btn-group.show .ngb-tp .dropdown-toggle.btn-outline-primary:not(:disabled):not(.disabled), .ngb-tp .btn-group.show .dropdown-toggle.btn-outline-primary:not(:disabled):not(.disabled), .btn-group.show .datepicker .dropdown-toggle.form-select, .datepicker .btn-group.show .dropdown-toggle.form-select, .btn-group.show .dropdown-toggle.btn-inline {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .modal-header .btn-group-vertical > .close, .datepicker .btn-group-vertical > .form-select,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .modal-header .btn-group-vertical > .close:not(:first-child), .datepicker .btn-group-vertical > .form-select:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .modal-header .btn-group-vertical > .close:not(:last-child):not(.dropdown-toggle), .datepicker .btn-group-vertical > .form-select:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.modal-header .btn-group-vertical > .btn-group:not(:last-child) > .close,
.datepicker .btn-group-vertical > .btn-group:not(:last-child) > .form-select {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn, .modal-header .btn-group-vertical > .close ~ .btn, .modal-header .btn-group-vertical > .btn ~ .close, .modal-header .btn-group-vertical > .close ~ .close, .datepicker .btn-group-vertical > .form-select ~ .btn, .datepicker .modal-header .btn-group-vertical > .form-select ~ .close, .modal-header .datepicker .btn-group-vertical > .form-select ~ .close, .datepicker .btn-group-vertical > .btn ~ .form-select, .datepicker .modal-header .btn-group-vertical > .close ~ .form-select, .modal-header .datepicker .btn-group-vertical > .close ~ .form-select, .datepicker .btn-group-vertical > .form-select ~ .form-select,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.modal-header .btn-group-vertical > .btn-group:not(:first-child) > .close,
.datepicker .btn-group-vertical > .btn-group:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card, .widget, .radio-tile label,
.check-tile label {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--bs-body-bg);
  background-clip: border-box;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.6rem;
}
.card > hr, .widget > hr, .radio-tile label > hr,
.check-tile label > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group, .widget > .list-group, .radio-tile label > .list-group,
.check-tile label > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child, .widget > .list-group:first-child, .radio-tile label > .list-group:first-child,
.check-tile label > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.6rem - 1px);
  border-top-right-radius: calc(0.6rem - 1px);
}
.card > .list-group:last-child, .widget > .list-group:last-child, .radio-tile label > .list-group:last-child,
.check-tile label > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.6rem - 1px);
  border-bottom-left-radius: calc(0.6rem - 1px);
}
.card > .card-header + .list-group, .card > .widget-header + .list-group, .widget > .card-header + .list-group, .widget > .widget-header + .list-group, .radio-tile label > .card-header + .list-group, .radio-tile label > .widget-header + .list-group,
.check-tile label > .card-header + .list-group,
.check-tile label > .widget-header + .list-group,
.card > .list-group + .card-footer,
.card > .list-group + .widget-footer,
.widget > .list-group + .card-footer,
.widget > .list-group + .widget-footer,
.radio-tile label > .list-group + .card-footer,
.radio-tile label > .list-group + .widget-footer,
.check-tile label > .list-group + .card-footer,
.check-tile label > .list-group + .widget-footer {
  border-top: 0;
}

.card-body, .widget-body, .accordion-widget .widget.widget-body .widget.widget-body,
.accordion-widget .widget.widget-body .widget-body,
.accordion-widget .widget-body .widget.widget-body,
.accordion-widget .widget-body .widget-body, .radio-tile .tile-icon,
.radio-tile .tile-label,
.check-tile .tile-icon,
.check-tile .tile-label {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  color: var(--bs-body-color);
}

.card-title, .widget-title {
  margin-bottom: 1rem;
}

.card-subtitle, .widget-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child, .widget-text:last-child {
  margin-bottom: 0;
}

.card-link:hover, .widget-link:hover {
  text-decoration: none;
}
.card-link + .card-link, .widget-link + .card-link, .card-link + .widget-link, .widget-link + .widget-link {
  margin-left: 1rem;
}

.card-header, .widget-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-bottom: 1px solid var(--bs-border-color);
}
.card-header:first-child, .widget-header:first-child {
  border-radius: calc(0.6rem - 1px) calc(0.6rem - 1px) 0 0;
}

.card-footer, .widget-footer {
  padding: 1rem 1rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-top: 1px solid var(--bs-border-color);
}
.card-footer:last-child, .widget-footer:last-child {
  border-radius: 0 0 calc(0.6rem - 1px) calc(0.6rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -1rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-body-bg);
  border-bottom-color: var(--bs-body-bg);
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.6rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.6rem - 1px);
  border-top-right-radius: calc(0.6rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.6rem - 1px);
  border-bottom-left-radius: calc(0.6rem - 1px);
}

.card-group > .card, .card-group > .widget, .radio-tile .card-group > label,
.check-tile .card-group > label {
  margin-bottom: 0.5rem;
}
@media (min-width: 425px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card, .card-group > .widget, .radio-tile .card-group > label,
  .check-tile .card-group > label {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card, .card-group > .widget + .card, .card-group > .card + .widget, .card-group > .widget + .widget, .radio-tile .card-group > label + .card, .radio-tile .card-group > label + .widget,
  .check-tile .card-group > label + .card,
  .check-tile .card-group > label + .widget, .radio-tile .card-group > .card + label, .radio-tile .card-group > .widget + label, .radio-tile .card-group > label + label,
  .check-tile .card-group > .card + label,
  .check-tile .card-group > .widget + label,
  .check-tile .card-group > label + label {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child), .card-group > .widget:not(:last-child), .radio-tile .card-group > label:not(:last-child),
  .check-tile .card-group > label:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top, .card-group > .widget:not(:last-child) .card-img-top, .radio-tile .card-group > label:not(:last-child) .card-img-top,
  .check-tile .card-group > label:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .widget-header,
  .card-group > .widget:not(:last-child) .card-header,
  .card-group > .widget:not(:last-child) .widget-header,
  .radio-tile .card-group > label:not(:last-child) .card-header,
  .radio-tile .card-group > label:not(:last-child) .widget-header,
  .check-tile .card-group > label:not(:last-child) .card-header,
  .check-tile .card-group > label:not(:last-child) .widget-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .widget:not(:last-child) .card-img-bottom, .radio-tile .card-group > label:not(:last-child) .card-img-bottom,
  .check-tile .card-group > label:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .widget-footer,
  .card-group > .widget:not(:last-child) .card-footer,
  .card-group > .widget:not(:last-child) .widget-footer,
  .radio-tile .card-group > label:not(:last-child) .card-footer,
  .radio-tile .card-group > label:not(:last-child) .widget-footer,
  .check-tile .card-group > label:not(:last-child) .card-footer,
  .check-tile .card-group > label:not(:last-child) .widget-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child), .card-group > .widget:not(:first-child), .radio-tile .card-group > label:not(:first-child),
  .check-tile .card-group > label:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top, .card-group > .widget:not(:first-child) .card-img-top, .radio-tile .card-group > label:not(:first-child) .card-img-top,
  .check-tile .card-group > label:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .widget-header,
  .card-group > .widget:not(:first-child) .card-header,
  .card-group > .widget:not(:first-child) .widget-header,
  .radio-tile .card-group > label:not(:first-child) .card-header,
  .radio-tile .card-group > label:not(:first-child) .widget-header,
  .check-tile .card-group > label:not(:first-child) .card-header,
  .check-tile .card-group > label:not(:first-child) .widget-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .widget:not(:first-child) .card-img-bottom, .radio-tile .card-group > label:not(:first-child) .card-img-bottom,
  .check-tile .card-group > label:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .widget-footer,
  .card-group > .widget:not(:first-child) .card-footer,
  .card-group > .widget:not(:first-child) .widget-footer,
  .radio-tile .card-group > label:not(:first-child) .card-footer,
  .radio-tile .card-group > label:not(:first-child) .widget-footer,
  .check-tile .card-group > label:not(:first-child) .card-footer,
  .check-tile .card-group > label:not(:first-child) .widget-footer {
    border-bottom-left-radius: 0;
  }
}

.card-clickable, .radio-tile label,
.check-tile label {
  cursor: pointer;
}
.card-clickable, .radio-tile label,
.check-tile label,
.card-clickable .card-header,
.card-clickable .widget-header,
.radio-tile label .card-header,
.radio-tile label .widget-header,
.check-tile label .card-header,
.check-tile label .widget-header,
.card-clickable .card-footer,
.card-clickable .widget-footer,
.radio-tile label .card-footer,
.radio-tile label .widget-footer,
.check-tile label .card-footer,
.check-tile label .widget-footer {
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .card-clickable, .radio-tile label,
  .check-tile label,
  .card-clickable .card-header,
  .card-clickable .widget-header,
  .radio-tile label .card-header,
  .radio-tile label .widget-header,
  .check-tile label .card-header,
  .check-tile label .widget-header,
  .card-clickable .card-footer,
  .card-clickable .widget-footer,
  .radio-tile label .card-footer,
  .radio-tile label .widget-footer,
  .check-tile label .card-footer,
  .check-tile label .widget-footer {
    transition: none;
  }
}
.card-clickable:hover, .radio-tile label:hover,
.check-tile label:hover,
.card-clickable:hover .card-header,
.card-clickable:hover .widget-header,
.radio-tile label:hover .card-header,
.radio-tile label:hover .widget-header,
.check-tile label:hover .card-header,
.check-tile label:hover .widget-header,
.card-clickable:hover .card-footer,
.card-clickable:hover .widget-footer,
.radio-tile label:hover .card-footer,
.radio-tile label:hover .widget-footer,
.check-tile label:hover .card-footer,
.check-tile label:hover .widget-footer {
  border-color: var(--bs-primary);
}

.card hr, .widget hr, .radio-tile label hr,
.check-tile label hr {
  background-color: var(--bs-body-color-muted);
  color: var(--bs-body-color-muted);
}

.card-body:empty, .widget-body:empty,
.accordion-widget .widget.widget-body .widget-body:empty,
.accordion-widget .widget-body .widget.widget-body:empty,
.accordion-widget .widget-body .widget-body:empty, .radio-tile .tile-icon:empty,
.radio-tile .tile-label:empty,
.check-tile .tile-icon:empty,
.check-tile .tile-label:empty {
  display: none;
}

.card-muted,
.card-muted > .card-body,
.card-muted > .widget-body,
.accordion-widget .widget.widget-body .card-muted > .widget-body,
.accordion-widget .widget-body .card-muted > .widget.widget-body,
.accordion-widget .widget-body .card-muted > .widget-body,
.radio-tile .card-muted > .tile-icon,
.radio-tile .card-muted > .tile-label,
.check-tile .card-muted > .tile-icon,
.check-tile .card-muted > .tile-label {
  background-color: var(--bs-body-bg-muted);
  color: var(--bs-body-color);
}
.card-muted > .card-header, .card-muted > .widget-header {
  background-color: var(--bs-body-bg-muted);
  color: var(--bs-body-color);
}

.card-bold,
.card-bold > .card-body,
.card-bold > .widget-body,
.accordion-widget .widget.widget-body .card-bold > .widget-body,
.accordion-widget .widget-body .card-bold > .widget.widget-body,
.accordion-widget .widget-body .card-bold > .widget-body,
.radio-tile .card-bold > .tile-icon,
.radio-tile .card-bold > .tile-label,
.check-tile .card-bold > .tile-icon,
.check-tile .card-bold > .tile-label {
  background-color: var(--bs-body-bg-strong);
  color: var(--bs-body-color);
}
.card-bold > .card-header, .card-bold > .widget-header {
  background-color: var(--bs-body-bg-strong);
  color: var(--bs-body-color);
}

.card-primary {
  border-color: var(--bs-primary);
}
.card-primary,
.card-primary > .card-body,
.card-primary > .widget-body,
.accordion-widget .widget.widget-body .card-primary > .widget-body,
.accordion-widget .widget-body .card-primary > .widget.widget-body,
.accordion-widget .widget-body .card-primary > .widget-body,
.radio-tile .card-primary > .tile-icon,
.radio-tile .card-primary > .tile-label,
.check-tile .card-primary > .tile-icon,
.check-tile .card-primary > .tile-label {
  background-color: var(--bs-primary);
  color: var(--bs-primary-contrast);
}
.card-primary > .card-header, .card-primary > .widget-header {
  background-color: var(--bs-primary-light);
  color: var(--bs-primary-light-contrast);
}

.card-secondary {
  border-color: var(--bs-secondary);
}
.card-secondary,
.card-secondary > .card-body,
.card-secondary > .widget-body,
.accordion-widget .widget.widget-body .card-secondary > .widget-body,
.accordion-widget .widget-body .card-secondary > .widget.widget-body,
.accordion-widget .widget-body .card-secondary > .widget-body,
.radio-tile .card-secondary > .tile-icon,
.radio-tile .card-secondary > .tile-label,
.check-tile .card-secondary > .tile-icon,
.check-tile .card-secondary > .tile-label {
  background-color: var(--bs-secondary);
  color: var(--bs-secondary-contrast);
}
.card-secondary > .card-header, .card-secondary > .widget-header {
  background-color: var(--bs-secondary-light);
  color: var(--bs-secondary-light-contrast);
}

.card-success {
  border-color: var(--bs-success);
}
.card-success,
.card-success > .card-body,
.card-success > .widget-body,
.accordion-widget .widget.widget-body .card-success > .widget-body,
.accordion-widget .widget-body .card-success > .widget.widget-body,
.accordion-widget .widget-body .card-success > .widget-body,
.radio-tile .card-success > .tile-icon,
.radio-tile .card-success > .tile-label,
.check-tile .card-success > .tile-icon,
.check-tile .card-success > .tile-label {
  background-color: var(--bs-success);
  color: var(--bs-success-contrast);
}
.card-success > .card-header, .card-success > .widget-header {
  background-color: var(--bs-success-light);
  color: var(--bs-success-light-contrast);
}

.card-info {
  border-color: var(--bs-info);
}
.card-info,
.card-info > .card-body,
.card-info > .widget-body,
.accordion-widget .widget.widget-body .card-info > .widget-body,
.accordion-widget .widget-body .card-info > .widget.widget-body,
.accordion-widget .widget-body .card-info > .widget-body,
.radio-tile .card-info > .tile-icon,
.radio-tile .card-info > .tile-label,
.check-tile .card-info > .tile-icon,
.check-tile .card-info > .tile-label {
  background-color: var(--bs-info);
  color: var(--bs-info-contrast);
}
.card-info > .card-header, .card-info > .widget-header {
  background-color: var(--bs-info-light);
  color: var(--bs-info-light-contrast);
}

.card-warning {
  border-color: var(--bs-warning);
}
.card-warning,
.card-warning > .card-body,
.card-warning > .widget-body,
.accordion-widget .widget.widget-body .card-warning > .widget-body,
.accordion-widget .widget-body .card-warning > .widget.widget-body,
.accordion-widget .widget-body .card-warning > .widget-body,
.radio-tile .card-warning > .tile-icon,
.radio-tile .card-warning > .tile-label,
.check-tile .card-warning > .tile-icon,
.check-tile .card-warning > .tile-label {
  background-color: var(--bs-warning);
  color: var(--bs-warning-contrast);
}
.card-warning > .card-header, .card-warning > .widget-header {
  background-color: var(--bs-warning-light);
  color: var(--bs-warning-light-contrast);
}

.card-danger {
  border-color: var(--bs-danger);
}
.card-danger,
.card-danger > .card-body,
.card-danger > .widget-body,
.accordion-widget .widget.widget-body .card-danger > .widget-body,
.accordion-widget .widget-body .card-danger > .widget.widget-body,
.accordion-widget .widget-body .card-danger > .widget-body,
.radio-tile .card-danger > .tile-icon,
.radio-tile .card-danger > .tile-label,
.check-tile .card-danger > .tile-icon,
.check-tile .card-danger > .tile-label {
  background-color: var(--bs-danger);
  color: var(--bs-danger-contrast);
}
.card-danger > .card-header, .card-danger > .widget-header {
  background-color: var(--bs-danger-light);
  color: var(--bs-danger-light-contrast);
}

.card-light {
  border-color: var(--bs-light);
}
.card-light,
.card-light > .card-body,
.card-light > .widget-body,
.accordion-widget .widget.widget-body .card-light > .widget-body,
.accordion-widget .widget-body .card-light > .widget.widget-body,
.accordion-widget .widget-body .card-light > .widget-body,
.radio-tile .card-light > .tile-icon,
.radio-tile .card-light > .tile-label,
.check-tile .card-light > .tile-icon,
.check-tile .card-light > .tile-label {
  background-color: var(--bs-light);
  color: var(--bs-light-contrast);
}
.card-light > .card-header, .card-light > .widget-header {
  background-color: var(--bs-light);
  color: var(--bs-light-contrast);
}

.card-dark {
  border-color: var(--bs-dark);
}
.card-dark,
.card-dark > .card-body,
.card-dark > .widget-body,
.accordion-widget .widget.widget-body .card-dark > .widget-body,
.accordion-widget .widget-body .card-dark > .widget.widget-body,
.accordion-widget .widget-body .card-dark > .widget-body,
.radio-tile .card-dark > .tile-icon,
.radio-tile .card-dark > .tile-label,
.check-tile .card-dark > .tile-icon,
.check-tile .card-dark > .tile-label {
  background-color: var(--bs-dark);
  color: var(--bs-dark-contrast);
}
.card-dark > .card-header, .card-dark > .widget-header {
  background-color: var(--bs-dark);
  color: var(--bs-dark-contrast);
}

.card-img-bg-cover {
  min-height: 23rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-text + .card-text, .widget-text + .card-text, .card-text + .widget-text, .widget-text + .widget-text {
  margin-top: 1rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: var(--bs-body-color);
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: var(--bs-body-color);
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--bs-primary);
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--bs-light);
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--bs-primary);
}
.carousel-dark .carousel-caption {
  color: var(--bs-dark);
}

.carousel-inner,
.carousel-item {
  height: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  max-width: 10rem;
  z-index: 100;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background: rgba(0, 0, 0, 0);
  font-family: "Font Awesome 6 Pro" !important;
  font-weight: 300;
  color: inherit;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    transition: none;
  }
}
.carousel-control-prev-icon::after,
.carousel-control-next-icon::after {
  font-size: 2rem;
}

.carousel-control-prev-icon::after {
  content: "\f053";
}

.carousel-control-next-icon::after {
  content: "\f054";
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-black);
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-black%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.6rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: var(--bs-black);
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.dashboard-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  --bs-columns: 2;
}
@media (max-width: 1023.98px) {
  .dashboard-container {
    --bs-columns: 1;
  }
}

.dashboard-grid {
  grid-template-columns: 1fr 2fr;
  overflow: hidden;
}
@media (max-width: 1023.98px) {
  .dashboard-grid {
    grid-template-columns: 100%;
    overflow: auto;
  }
}
@media (max-width: 1023.98px) {
  .dashboard-grid:has(.dashboard-grid-scroll-column) {
    gap: 0;
  }
}

.dashboard-grid-filters {
  grid-template-columns: min-content 1fr;
  overflow: hidden;
}
.dashboard-grid-filters .filters-column,
.dashboard-grid-filters .filters-column-fixed {
  display: block;
  height: 100%;
  width: 35rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-grid-filters .filters-column,
  .dashboard-grid-filters .filters-column-fixed {
    transition: none;
  }
}
.dashboard-grid-filters.collapsed {
  gap: 0px;
}
.dashboard-grid-filters.collapsed .filters-column {
  width: 0px;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 1023.98px) {
  .dashboard-grid-filters {
    grid-template-columns: 100%;
  }
  .dashboard-grid-filters .filters-column {
    display: none;
  }
}

.dashboard-grid-collapsible {
  grid-template-columns: min-content 1fr;
  overflow: hidden;
}
.dashboard-grid-collapsible .collapsible-col-body {
  display: block;
  height: 100%;
  width: 60rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-grid-collapsible .collapsible-col-body {
    transition: none;
  }
}
.dashboard-grid-collapsible .collapsible-col {
  overflow: hidden;
}
.dashboard-grid-collapsible .collapsible-col.collapsed .collapsible-col-body {
  width: 7rem;
}
@media (max-width: 1023.98px) {
  .dashboard-grid-collapsible {
    grid-template-columns: 1fr 2fr;
  }
  .dashboard-grid-collapsible .collapsible-col-body {
    width: 100%;
  }
}

.dashboard-grid-scroll-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 1024px) {
  .dashboard-grid-scroll-column {
    max-height: 100%;
    overflow: auto;
  }
}

.dashboard-tab-content {
  display: block;
  height: 100%;
  overflow: hidden;
  padding-top: 0.5rem;
}

.datepicker.dropdown-menu {
  padding: 1rem 1rem;
}
.datepicker,
.datepicker .ngb-dp-header,
.datepicker .ngb-dp-content,
.datepicker .ngb-dp-week,
.datepicker .ngb-dp-weekdays,
.datepicker .ngb-dp-month-name {
  background-color: var(--bs-body-bg);
}
.datepicker .ngb-dp-day,
.datepicker .ngb-dp-weekday,
.datepicker .ngb-dp-week-number {
  margin: 0;
  height: 3.4rem;
  width: 3.4rem;
}
.datepicker .ngb-dp-navigation-select {
  flex: 1 1 11rem;
}
.datepicker .form-select {
  padding: 0;
  box-shadow: none;
  text-align: start;
}
.datepicker .form-select:first-child {
  margin-right: 0.5rem;
}
.datepicker .form-select.active, .datepicker .form-select:focus, .datepicker .form-select:active {
  border: none;
  box-shadow: none;
}
.datepicker .btn[disabled], .datepicker .modal-header [disabled].close, .modal-header .datepicker [disabled].close, .datepicker [disabled].form-select {
  border: none;
}
.datepicker .ngb-dp-day, .datepicker .ngb-dp-day.active, .datepicker .ngb-dp-day:focus, .datepicker .ngb-dp-day:active,
.datepicker .ngb-dp-today,
.datepicker .ngb-dp-today.active,
.datepicker .ngb-dp-today:focus,
.datepicker .ngb-dp-today:active {
  border: none;
  outline: none;
}
.datepicker .ngb-dp-day > [ngbdatepickerdayview],
.datepicker .ngb-dp-day > .custom-day,
.datepicker .ngb-dp-today > [ngbdatepickerdayview],
.datepicker .ngb-dp-today > .custom-day {
  height: 3.4rem;
  width: 3.4rem;
  padding: 0.7rem;
  border-radius: 0;
}
.datepicker .ngb-dp-day > [ngbdatepickerdayview].btn-light,
.datepicker .ngb-dp-day > .custom-day.btn-light,
.datepicker .ngb-dp-today > [ngbdatepickerdayview].btn-light,
.datepicker .ngb-dp-today > .custom-day.btn-light {
  background-color: rgba(0, 0, 0, 0);
  color: var(--bs-body-color);
}
.datepicker .ngb-dp-day > [ngbdatepickerdayview].btn-light:hover, .datepicker .ngb-dp-day > [ngbdatepickerdayview].btn-light:not(:disabled):not(.disabled):active, .datepicker .ngb-dp-day > [ngbdatepickerdayview].btn-light:not(:disabled):not(.disabled).active,
.datepicker .ngb-dp-day > .custom-day.btn-light:hover,
.datepicker .ngb-dp-day > .custom-day.btn-light:not(:disabled):not(.disabled):active,
.datepicker .ngb-dp-day > .custom-day.btn-light:not(:disabled):not(.disabled).active,
.datepicker .ngb-dp-today > [ngbdatepickerdayview].btn-light:hover,
.datepicker .ngb-dp-today > [ngbdatepickerdayview].btn-light:not(:disabled):not(.disabled):active,
.datepicker .ngb-dp-today > [ngbdatepickerdayview].btn-light:not(:disabled):not(.disabled).active,
.datepicker .ngb-dp-today > .custom-day.btn-light:hover,
.datepicker .ngb-dp-today > .custom-day.btn-light:not(:disabled):not(.disabled):active,
.datepicker .ngb-dp-today > .custom-day.btn-light:not(:disabled):not(.disabled).active {
  background-color: var(--bs-component-active-bg);
  color: var(--bs-component-active-color);
}
.datepicker .ngb-dp-day > [ngbdatepickerdayview].btn-light.bg-primary,
.datepicker .ngb-dp-day > .custom-day.btn-light.bg-primary,
.datepicker .ngb-dp-today > [ngbdatepickerdayview].btn-light.bg-primary,
.datepicker .ngb-dp-today > .custom-day.btn-light.bg-primary {
  background-color: var(--bs-primary) !important;
  color: var(--bs-primary-contrast);
}
.datepicker .ngb-dp-day > .custom-day,
.datepicker .ngb-dp-today > .custom-day {
  display: inline-block;
  text-align: center;
}
.datepicker .ngb-dp-day > .custom-day:hover,
.datepicker .ngb-dp-today > .custom-day:hover {
  background-color: var(--bs-component-active-bg);
  color: var(--bs-component-active-color);
}
.datepicker .ngb-dp-day > .custom-day.range,
.datepicker .ngb-dp-today > .custom-day.range {
  background-color: var(--bs-primary);
  color: var(--bs-primary-contrast);
}
.datepicker .ngb-dp-day > .custom-day.range.faded,
.datepicker .ngb-dp-today > .custom-day.range.faded {
  background-color: var(--bs-component-active-bg);
  color: var(--bs-component-active-color);
}
.datepicker .ngb-dp-weekdays {
  border-bottom: none;
  margin-top: 0.5rem;
}
.datepicker .ngb-dp-weekday {
  font-style: normal;
  color: var(--bs-body-color);
  margin-bottom: 0;
}
.datepicker .ngb-dp-months {
  margin-top: 1rem;
}
.datepicker .ngb-dp-months .ngb-dp-month:not(:last-child) {
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  border-right: var(--bs-border-color) solid 1px;
}
.datepicker .ngb-tp {
  display: flex;
  justify-content: center;
  border-top: var(--bs-border-color) solid 1px;
  margin-top: 1rem;
  padding-top: 1rem;
}
.datepicker .ngb-tp .ngb-tp-input.form-control {
  padding: 0;
}

.ngb-tp .btn-link, .ngb-tp .btn-outline-primary:not(:disabled):not(.disabled), .ngb-tp .btn-inline, .ngb-tp .datepicker .form-select, .datepicker .ngb-tp .form-select,
.ngb-tp .btn-outline-primary {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  padding: 1px 1.2rem;
}
.ngb-tp .btn-link:disabled, .ngb-tp .btn-outline-primary:disabled:not(:disabled):not(.disabled), .ngb-tp .btn-inline:disabled, .ngb-tp .datepicker .form-select:disabled, .datepicker .ngb-tp .form-select:disabled, .ngb-tp .btn-link[disabled], .ngb-tp [disabled].btn-outline-primary:not(:disabled):not(.disabled), .ngb-tp [disabled].btn-inline, .ngb-tp .datepicker [disabled].form-select, .datepicker .ngb-tp [disabled].form-select,
.ngb-tp .btn-outline-primary:disabled,
.ngb-tp .btn-outline-primary[disabled] {
  cursor: not-allowed;
}
.ngb-tp .btn-link:disabled:hover, .ngb-tp .btn-inline:disabled:hover, .ngb-tp .datepicker .form-select:disabled:hover, .datepicker .ngb-tp .form-select:disabled:hover, .ngb-tp .btn-link:disabled:focus, .ngb-tp .btn-inline:disabled:focus, .ngb-tp .datepicker .form-select:disabled:focus, .datepicker .ngb-tp .form-select:disabled:focus, .ngb-tp .btn-link:disabled:active, .ngb-tp .btn-inline:disabled:active, .ngb-tp .datepicker .form-select:disabled:active, .datepicker .ngb-tp .form-select:disabled:active, .ngb-tp .btn-link[disabled]:hover, .ngb-tp [disabled].btn-inline:hover, .ngb-tp .datepicker [disabled].form-select:hover, .datepicker .ngb-tp [disabled].form-select:hover, .ngb-tp .btn-link[disabled]:focus, .ngb-tp [disabled].btn-inline:focus, .ngb-tp .datepicker [disabled].form-select:focus, .datepicker .ngb-tp [disabled].form-select:focus, .ngb-tp .btn-link[disabled]:active, .ngb-tp [disabled].btn-inline:active, .ngb-tp .datepicker [disabled].form-select:active, .datepicker .ngb-tp [disabled].form-select:active,
.ngb-tp .btn-outline-primary:disabled:hover,
.ngb-tp .btn-outline-primary:disabled:focus,
.ngb-tp .btn-outline-primary:disabled:active,
.ngb-tp .btn-outline-primary[disabled]:hover,
.ngb-tp .btn-outline-primary[disabled]:focus,
.ngb-tp .btn-outline-primary[disabled]:active {
  border: 0;
}
.ngb-tp .btn-outline-primary:not(:disabled):not(.disabled):hover, .ngb-tp .btn-outline-primary:not(:disabled):not(.disabled):focus, .ngb-tp .btn-outline-primary:not(:disabled):not(.disabled):active, .ngb-tp .btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: 0;
  text-decoration: none;
  color: var(--bs-link-color-hover);
  font-weight: 600;
}
.ngb-tp .ngb-tp-input.form-control {
  padding: 0;
}

.dot-loader {
  --bs-dot-loader-margin-y: 0.2rem;
  position: relative;
  width: var(--dot-loader-size, 1rem);
  height: var(--dot-loader-size, 1rem);
  border-radius: 100%;
  background-color: var(--bs-primary);
  color: var(--bs-primary);
  animation: dot-loader 0.66s infinite linear alternate;
  animation-delay: 0.33s;
  margin-left: calc(var(--dot-loader-size, 1rem) + 0.5rem);
  margin-right: calc(var(--dot-loader-size, 1rem) + 0.5rem);
  margin-top: var(--bs-dot-loader-margin-y);
  margin-bottom: var(--bs-dot-loader-margin-y);
}
.dot-loader:empty {
  display: block !important;
}

.dot-loader::before,
.dot-loader::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-loader::before {
  left: calc((var(--dot-loader-size, 1rem) + 0.5rem) * -1);
  width: var(--dot-loader-size, 1rem);
  height: var(--dot-loader-size, 1rem);
  border-radius: 100%;
  background-color: var(--bs-primary);
  color: var(--bs-primary);
  animation: dot-loader 0.66s infinite alternate;
  animation-delay: 0s;
}

.dot-loader::after {
  left: calc(var(--dot-loader-size, 1rem) + 0.5rem);
  width: var(--dot-loader-size, 1rem);
  height: var(--dot-loader-size, 1rem);
  border-radius: 100%;
  background-color: var(--bs-primary);
  color: var(--bs-primary);
  animation: dot-loader 0.66s infinite alternate;
  animation-delay: 0.66s;
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 22.5rem;
  padding: 0 0;
  margin: 0;
  font-size: calc(1.265rem + 0.18vw);
  color: var(--bs-body-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.6rem;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-black-rgb), 0.15);
}
@media (min-width: 1200px) {
  .dropdown-menu {
    font-size: 1.4rem;
  }
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 425px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 2560px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-border-color-muted);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 1rem 1rem;
  clear: both;
  font-weight: 400;
  color: var(--bs-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:first-child {
  border-top-left-radius: calc(0.6rem - 1px);
  border-top-right-radius: calc(0.6rem - 1px);
}
.dropdown-item:last-child {
  border-bottom-right-radius: calc(0.6rem - 1px);
  border-bottom-left-radius: calc(0.6rem - 1px);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-link-color-hover);
  text-decoration: none;
  background-color: var(--bs-body-bg-muted);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-component-active-color);
  text-decoration: none;
  background-color: var(--bs-component-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-gray-500);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1rem;
  margin-bottom: 0;
  font-size: 1.225rem;
  color: var(--bs-body-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 1rem 1rem;
  color: var(--bs-link-color);
}

.dropdown-menu-dark {
  color: var(--bs-gray-300);
  background-color: var(--bs-gray-800);
  border-color: rgba(0, 0, 0, 0);
}
.dropdown-menu-dark .dropdown-item {
  color: var(--bs-gray-300);
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: var(--bs-white);
  background-color: rgba(var(--bs-white), 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: var(--bs-component-active-color);
  background-color: var(--bs-component-active-bg);
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: var(--bs-gray-500);
}
.dropdown-menu-dark .dropdown-divider {
  border-color: var(--bs-border-color-muted);
}
.dropdown-menu-dark .dropdown-item-text {
  color: var(--bs-gray-300);
}
.dropdown-menu-dark .dropdown-header {
  color: var(--bs-gray-500);
}

.dropdown-menu {
  --scrollbar-bg: var(--bs-body-bg);
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}
.dropdown-menu.dropdown-static {
  display: block;
  position: relative;
}
.dropdown-menu.dropdown-flush {
  display: block;
  position: relative;
  min-width: unset;
  box-shadow: none;
  border-radius: 0;
  border: none;
}
.dropdown-menu.dropdown-flush li,
.dropdown-menu.dropdown-flush li > .dropdown-item {
  border-radius: 0;
}
.dropdown-menu.popover {
  font-size: 1.4rem;
}
.dropdown-menu:not(.dropdown-static):not(.dropdown-flush) {
  min-width: 22.5rem;
  max-width: 30rem;
  max-height: 50rem;
  overflow-y: auto;
}
.dropdown-menu:not(.dropdown-static):not(.dropdown-flush).dropdown-menu-lg {
  min-width: 60rem;
}
.dropdown-menu:not(.dropdown-static):not(.dropdown-flush) .dropdown-scroll-wrapper {
  height: 100%;
  max-height: 50rem;
  overflow: hidden;
}
.dropdown-menu li:not(:first-child) .dropdown-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown-menu li:not(:last-child) .dropdown-item {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-header {
  font-size: 1.4rem;
  font-weight: 600;
}
.dropdown-header:not(:first-child) {
  border-top: 1px solid var(--bs-border-color-muted);
}

.dropdown-item {
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dropdown-item {
    transition: none;
  }
}

.dropdown-header,
.dropdown-item:not(:last-child),
li .dropdown-item {
  border-bottom: 1px solid var(--bs-border-color-muted);
}

.divider-top, .divider-bottom, .divider-start, .divider-end {
  position: relative;
}
.divider-top::before, .divider-bottom::before, .divider-start::before, .divider-end::before {
  content: "";
  background-color: var(--bs-border-color);
  display: block;
  position: absolute;
}
.divider-0::before {
  display: none;
}
.divider-top::before, .divider-bottom::before {
  height: 1px;
  width: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
.divider-start::before, .divider-end::before {
  height: 2rem;
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
}
.divider-top {
  margin-top: 2rem;
}
.divider-top::before {
  top: -1rem;
}
.divider-start {
  margin-left: 2rem;
}
.divider-start::before {
  left: -1rem;
}
.divider-bottom {
  margin-bottom: 2rem;
}
.divider-bottom::before {
  bottom: -1rem;
}
.divider-end {
  margin-right: 2rem;
}
.divider-end::before {
  right: -1rem;
}
.divider--md.divider-top {
  margin-top: 4rem;
}
.divider--md.divider-top::before {
  top: -2rem;
}
.divider--md.divider-start {
  margin-left: 4rem;
}
.divider--md.divider-start::before {
  left: -2rem;
}
.divider--md.divider-bottom {
  margin-bottom: 4rem;
}
.divider--md.divider-bottom::before {
  bottom: -2rem;
}
.divider--md.divider-end {
  margin-right: 4rem;
}
.divider--md.divider-end::before {
  right: -2rem;
}
.divider--lg.divider-top {
  margin-top: 8rem;
}
.divider--lg.divider-top::before {
  top: -4rem;
}
.divider--lg.divider-start {
  margin-left: 8rem;
}
.divider--lg.divider-start::before {
  left: -4rem;
}
.divider--lg.divider-bottom {
  margin-bottom: 8rem;
}
.divider--lg.divider-bottom::before {
  bottom: -4rem;
}
.divider--lg.divider-end {
  margin-right: 8rem;
}
.divider--lg.divider-end::before {
  right: -4rem;
}

.divider-xs-top, .divider-xs-bottom, .divider-xs-start, .divider-xs-end {
  position: relative;
}
.divider-xs-top::before, .divider-xs-bottom::before, .divider-xs-start::before, .divider-xs-end::before {
  content: "";
  background-color: var(--bs-border-color);
  display: block;
  position: absolute;
}
.divider-xs-0::before {
  display: none;
}
.divider-xs-top::before, .divider-xs-bottom::before {
  height: 1px;
  width: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
.divider-xs-start::before, .divider-xs-end::before {
  height: 2rem;
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
}
.divider-xs-top {
  margin-top: 2rem;
}
.divider-xs-top::before {
  top: -1rem;
}
.divider-xs-top.divider--md {
  margin-top: 4rem;
}
.divider-xs-top.divider--md::before {
  top: -2rem;
}
.divider-xs-top.divider--lg {
  margin-top: 8rem;
}
.divider-xs-top.divider--lg::before {
  top: -4rem;
}
.divider-xs-start {
  margin-left: 2rem;
}
.divider-xs-start::before {
  left: -1rem;
}
.divider-xs-start.divider--md {
  margin-left: 4rem;
}
.divider-xs-start.divider--md::before {
  left: -2rem;
}
.divider-xs-start.divider--lg {
  margin-left: 8rem;
}
.divider-xs-start.divider--lg::before {
  left: -4rem;
}
.divider-xs-bottom {
  margin-bottom: 2rem;
}
.divider-xs-bottom::before {
  bottom: -1rem;
}
.divider-xs-bottom.divider--md {
  margin-bottom: 4rem;
}
.divider-xs-bottom.divider--md::before {
  bottom: -2rem;
}
.divider-xs-bottom.divider--lg {
  margin-bottom: 8rem;
}
.divider-xs-bottom.divider--lg::before {
  bottom: -4rem;
}
.divider-xs-end {
  margin-right: 2rem;
}
.divider-xs-end::before {
  right: -1rem;
}
.divider-xs-end.divider--md {
  margin-right: 4rem;
}
.divider-xs-end.divider--md::before {
  right: -2rem;
}
.divider-xs-end.divider--lg {
  margin-right: 8rem;
}
.divider-xs-end.divider--lg::before {
  right: -4rem;
}
.divider-xs--md.divider-top {
  margin-top: 4rem;
}
.divider-xs--md.divider-top::before {
  top: -2rem;
}
.divider-xs--md.divider-start {
  margin-left: 4rem;
}
.divider-xs--md.divider-start::before {
  left: -2rem;
}
.divider-xs--md.divider-bottom {
  margin-bottom: 4rem;
}
.divider-xs--md.divider-bottom::before {
  bottom: -2rem;
}
.divider-xs--md.divider-end {
  margin-right: 4rem;
}
.divider-xs--md.divider-end::before {
  right: -2rem;
}
.divider-xs--lg.divider-top {
  margin-top: 8rem;
}
.divider-xs--lg.divider-top::before {
  top: -4rem;
}
.divider-xs--lg.divider-start {
  margin-left: 8rem;
}
.divider-xs--lg.divider-start::before {
  left: -4rem;
}
.divider-xs--lg.divider-bottom {
  margin-bottom: 8rem;
}
.divider-xs--lg.divider-bottom::before {
  bottom: -4rem;
}
.divider-xs--lg.divider-end {
  margin-right: 8rem;
}
.divider-xs--lg.divider-end::before {
  right: -4rem;
}

@media (min-width: 425px) {
  .divider-sm-top, .divider-sm-bottom, .divider-sm-start, .divider-sm-end {
    position: relative;
  }
  .divider-sm-top::before, .divider-sm-bottom::before, .divider-sm-start::before, .divider-sm-end::before {
    content: "";
    background-color: var(--bs-border-color);
    display: block;
    position: absolute;
  }
  .divider-sm-0::before {
    display: none;
  }
  .divider-sm-top::before, .divider-sm-bottom::before {
    height: 1px;
    width: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider-sm-start::before, .divider-sm-end::before {
    height: 2rem;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
  }
  .divider-sm-top {
    margin-top: 2rem;
  }
  .divider-sm-top::before {
    top: -1rem;
  }
  .divider-sm-top.divider--md {
    margin-top: 4rem;
  }
  .divider-sm-top.divider--md::before {
    top: -2rem;
  }
  .divider-sm-top.divider--lg {
    margin-top: 8rem;
  }
  .divider-sm-top.divider--lg::before {
    top: -4rem;
  }
  .divider-sm-start {
    margin-left: 2rem;
  }
  .divider-sm-start::before {
    left: -1rem;
  }
  .divider-sm-start.divider--md {
    margin-left: 4rem;
  }
  .divider-sm-start.divider--md::before {
    left: -2rem;
  }
  .divider-sm-start.divider--lg {
    margin-left: 8rem;
  }
  .divider-sm-start.divider--lg::before {
    left: -4rem;
  }
  .divider-sm-bottom {
    margin-bottom: 2rem;
  }
  .divider-sm-bottom::before {
    bottom: -1rem;
  }
  .divider-sm-bottom.divider--md {
    margin-bottom: 4rem;
  }
  .divider-sm-bottom.divider--md::before {
    bottom: -2rem;
  }
  .divider-sm-bottom.divider--lg {
    margin-bottom: 8rem;
  }
  .divider-sm-bottom.divider--lg::before {
    bottom: -4rem;
  }
  .divider-sm-end {
    margin-right: 2rem;
  }
  .divider-sm-end::before {
    right: -1rem;
  }
  .divider-sm-end.divider--md {
    margin-right: 4rem;
  }
  .divider-sm-end.divider--md::before {
    right: -2rem;
  }
  .divider-sm-end.divider--lg {
    margin-right: 8rem;
  }
  .divider-sm-end.divider--lg::before {
    right: -4rem;
  }
  .divider-sm--md.divider-top {
    margin-top: 4rem;
  }
  .divider-sm--md.divider-top::before {
    top: -2rem;
  }
  .divider-sm--md.divider-start {
    margin-left: 4rem;
  }
  .divider-sm--md.divider-start::before {
    left: -2rem;
  }
  .divider-sm--md.divider-bottom {
    margin-bottom: 4rem;
  }
  .divider-sm--md.divider-bottom::before {
    bottom: -2rem;
  }
  .divider-sm--md.divider-end {
    margin-right: 4rem;
  }
  .divider-sm--md.divider-end::before {
    right: -2rem;
  }
  .divider-sm--lg.divider-top {
    margin-top: 8rem;
  }
  .divider-sm--lg.divider-top::before {
    top: -4rem;
  }
  .divider-sm--lg.divider-start {
    margin-left: 8rem;
  }
  .divider-sm--lg.divider-start::before {
    left: -4rem;
  }
  .divider-sm--lg.divider-bottom {
    margin-bottom: 8rem;
  }
  .divider-sm--lg.divider-bottom::before {
    bottom: -4rem;
  }
  .divider-sm--lg.divider-end {
    margin-right: 8rem;
  }
  .divider-sm--lg.divider-end::before {
    right: -4rem;
  }
}

@media (min-width: 768px) {
  .divider-md-top, .divider-md-bottom, .divider-md-start, .divider-md-end {
    position: relative;
  }
  .divider-md-top::before, .divider-md-bottom::before, .divider-md-start::before, .divider-md-end::before {
    content: "";
    background-color: var(--bs-border-color);
    display: block;
    position: absolute;
  }
  .divider-md-0::before {
    display: none;
  }
  .divider-md-top::before, .divider-md-bottom::before {
    height: 1px;
    width: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider-md-start::before, .divider-md-end::before {
    height: 2rem;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
  }
  .divider-md-top {
    margin-top: 2rem;
  }
  .divider-md-top::before {
    top: -1rem;
  }
  .divider-md-top.divider--md {
    margin-top: 4rem;
  }
  .divider-md-top.divider--md::before {
    top: -2rem;
  }
  .divider-md-top.divider--lg {
    margin-top: 8rem;
  }
  .divider-md-top.divider--lg::before {
    top: -4rem;
  }
  .divider-md-start {
    margin-left: 2rem;
  }
  .divider-md-start::before {
    left: -1rem;
  }
  .divider-md-start.divider--md {
    margin-left: 4rem;
  }
  .divider-md-start.divider--md::before {
    left: -2rem;
  }
  .divider-md-start.divider--lg {
    margin-left: 8rem;
  }
  .divider-md-start.divider--lg::before {
    left: -4rem;
  }
  .divider-md-bottom {
    margin-bottom: 2rem;
  }
  .divider-md-bottom::before {
    bottom: -1rem;
  }
  .divider-md-bottom.divider--md {
    margin-bottom: 4rem;
  }
  .divider-md-bottom.divider--md::before {
    bottom: -2rem;
  }
  .divider-md-bottom.divider--lg {
    margin-bottom: 8rem;
  }
  .divider-md-bottom.divider--lg::before {
    bottom: -4rem;
  }
  .divider-md-end {
    margin-right: 2rem;
  }
  .divider-md-end::before {
    right: -1rem;
  }
  .divider-md-end.divider--md {
    margin-right: 4rem;
  }
  .divider-md-end.divider--md::before {
    right: -2rem;
  }
  .divider-md-end.divider--lg {
    margin-right: 8rem;
  }
  .divider-md-end.divider--lg::before {
    right: -4rem;
  }
  .divider-md--md.divider-top {
    margin-top: 4rem;
  }
  .divider-md--md.divider-top::before {
    top: -2rem;
  }
  .divider-md--md.divider-start {
    margin-left: 4rem;
  }
  .divider-md--md.divider-start::before {
    left: -2rem;
  }
  .divider-md--md.divider-bottom {
    margin-bottom: 4rem;
  }
  .divider-md--md.divider-bottom::before {
    bottom: -2rem;
  }
  .divider-md--md.divider-end {
    margin-right: 4rem;
  }
  .divider-md--md.divider-end::before {
    right: -2rem;
  }
  .divider-md--lg.divider-top {
    margin-top: 8rem;
  }
  .divider-md--lg.divider-top::before {
    top: -4rem;
  }
  .divider-md--lg.divider-start {
    margin-left: 8rem;
  }
  .divider-md--lg.divider-start::before {
    left: -4rem;
  }
  .divider-md--lg.divider-bottom {
    margin-bottom: 8rem;
  }
  .divider-md--lg.divider-bottom::before {
    bottom: -4rem;
  }
  .divider-md--lg.divider-end {
    margin-right: 8rem;
  }
  .divider-md--lg.divider-end::before {
    right: -4rem;
  }
}

@media (min-width: 1024px) {
  .divider-lg-top, .divider-lg-bottom, .divider-lg-start, .divider-lg-end {
    position: relative;
  }
  .divider-lg-top::before, .divider-lg-bottom::before, .divider-lg-start::before, .divider-lg-end::before {
    content: "";
    background-color: var(--bs-border-color);
    display: block;
    position: absolute;
  }
  .divider-lg-0::before {
    display: none;
  }
  .divider-lg-top::before, .divider-lg-bottom::before {
    height: 1px;
    width: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider-lg-start::before, .divider-lg-end::before {
    height: 2rem;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
  }
  .divider-lg-top {
    margin-top: 2rem;
  }
  .divider-lg-top::before {
    top: -1rem;
  }
  .divider-lg-top.divider--md {
    margin-top: 4rem;
  }
  .divider-lg-top.divider--md::before {
    top: -2rem;
  }
  .divider-lg-top.divider--lg {
    margin-top: 8rem;
  }
  .divider-lg-top.divider--lg::before {
    top: -4rem;
  }
  .divider-lg-start {
    margin-left: 2rem;
  }
  .divider-lg-start::before {
    left: -1rem;
  }
  .divider-lg-start.divider--md {
    margin-left: 4rem;
  }
  .divider-lg-start.divider--md::before {
    left: -2rem;
  }
  .divider-lg-start.divider--lg {
    margin-left: 8rem;
  }
  .divider-lg-start.divider--lg::before {
    left: -4rem;
  }
  .divider-lg-bottom {
    margin-bottom: 2rem;
  }
  .divider-lg-bottom::before {
    bottom: -1rem;
  }
  .divider-lg-bottom.divider--md {
    margin-bottom: 4rem;
  }
  .divider-lg-bottom.divider--md::before {
    bottom: -2rem;
  }
  .divider-lg-bottom.divider--lg {
    margin-bottom: 8rem;
  }
  .divider-lg-bottom.divider--lg::before {
    bottom: -4rem;
  }
  .divider-lg-end {
    margin-right: 2rem;
  }
  .divider-lg-end::before {
    right: -1rem;
  }
  .divider-lg-end.divider--md {
    margin-right: 4rem;
  }
  .divider-lg-end.divider--md::before {
    right: -2rem;
  }
  .divider-lg-end.divider--lg {
    margin-right: 8rem;
  }
  .divider-lg-end.divider--lg::before {
    right: -4rem;
  }
  .divider-lg--md.divider-top {
    margin-top: 4rem;
  }
  .divider-lg--md.divider-top::before {
    top: -2rem;
  }
  .divider-lg--md.divider-start {
    margin-left: 4rem;
  }
  .divider-lg--md.divider-start::before {
    left: -2rem;
  }
  .divider-lg--md.divider-bottom {
    margin-bottom: 4rem;
  }
  .divider-lg--md.divider-bottom::before {
    bottom: -2rem;
  }
  .divider-lg--md.divider-end {
    margin-right: 4rem;
  }
  .divider-lg--md.divider-end::before {
    right: -2rem;
  }
  .divider-lg--lg.divider-top {
    margin-top: 8rem;
  }
  .divider-lg--lg.divider-top::before {
    top: -4rem;
  }
  .divider-lg--lg.divider-start {
    margin-left: 8rem;
  }
  .divider-lg--lg.divider-start::before {
    left: -4rem;
  }
  .divider-lg--lg.divider-bottom {
    margin-bottom: 8rem;
  }
  .divider-lg--lg.divider-bottom::before {
    bottom: -4rem;
  }
  .divider-lg--lg.divider-end {
    margin-right: 8rem;
  }
  .divider-lg--lg.divider-end::before {
    right: -4rem;
  }
}

@media (min-width: 1440px) {
  .divider-xl-top, .divider-xl-bottom, .divider-xl-start, .divider-xl-end {
    position: relative;
  }
  .divider-xl-top::before, .divider-xl-bottom::before, .divider-xl-start::before, .divider-xl-end::before {
    content: "";
    background-color: var(--bs-border-color);
    display: block;
    position: absolute;
  }
  .divider-xl-0::before {
    display: none;
  }
  .divider-xl-top::before, .divider-xl-bottom::before {
    height: 1px;
    width: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider-xl-start::before, .divider-xl-end::before {
    height: 2rem;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
  }
  .divider-xl-top {
    margin-top: 2rem;
  }
  .divider-xl-top::before {
    top: -1rem;
  }
  .divider-xl-top.divider--md {
    margin-top: 4rem;
  }
  .divider-xl-top.divider--md::before {
    top: -2rem;
  }
  .divider-xl-top.divider--lg {
    margin-top: 8rem;
  }
  .divider-xl-top.divider--lg::before {
    top: -4rem;
  }
  .divider-xl-start {
    margin-left: 2rem;
  }
  .divider-xl-start::before {
    left: -1rem;
  }
  .divider-xl-start.divider--md {
    margin-left: 4rem;
  }
  .divider-xl-start.divider--md::before {
    left: -2rem;
  }
  .divider-xl-start.divider--lg {
    margin-left: 8rem;
  }
  .divider-xl-start.divider--lg::before {
    left: -4rem;
  }
  .divider-xl-bottom {
    margin-bottom: 2rem;
  }
  .divider-xl-bottom::before {
    bottom: -1rem;
  }
  .divider-xl-bottom.divider--md {
    margin-bottom: 4rem;
  }
  .divider-xl-bottom.divider--md::before {
    bottom: -2rem;
  }
  .divider-xl-bottom.divider--lg {
    margin-bottom: 8rem;
  }
  .divider-xl-bottom.divider--lg::before {
    bottom: -4rem;
  }
  .divider-xl-end {
    margin-right: 2rem;
  }
  .divider-xl-end::before {
    right: -1rem;
  }
  .divider-xl-end.divider--md {
    margin-right: 4rem;
  }
  .divider-xl-end.divider--md::before {
    right: -2rem;
  }
  .divider-xl-end.divider--lg {
    margin-right: 8rem;
  }
  .divider-xl-end.divider--lg::before {
    right: -4rem;
  }
  .divider-xl--md.divider-top {
    margin-top: 4rem;
  }
  .divider-xl--md.divider-top::before {
    top: -2rem;
  }
  .divider-xl--md.divider-start {
    margin-left: 4rem;
  }
  .divider-xl--md.divider-start::before {
    left: -2rem;
  }
  .divider-xl--md.divider-bottom {
    margin-bottom: 4rem;
  }
  .divider-xl--md.divider-bottom::before {
    bottom: -2rem;
  }
  .divider-xl--md.divider-end {
    margin-right: 4rem;
  }
  .divider-xl--md.divider-end::before {
    right: -2rem;
  }
  .divider-xl--lg.divider-top {
    margin-top: 8rem;
  }
  .divider-xl--lg.divider-top::before {
    top: -4rem;
  }
  .divider-xl--lg.divider-start {
    margin-left: 8rem;
  }
  .divider-xl--lg.divider-start::before {
    left: -4rem;
  }
  .divider-xl--lg.divider-bottom {
    margin-bottom: 8rem;
  }
  .divider-xl--lg.divider-bottom::before {
    bottom: -4rem;
  }
  .divider-xl--lg.divider-end {
    margin-right: 8rem;
  }
  .divider-xl--lg.divider-end::before {
    right: -4rem;
  }
}

@media (min-width: 2560px) {
  .divider-xxl-top, .divider-xxl-bottom, .divider-xxl-start, .divider-xxl-end {
    position: relative;
  }
  .divider-xxl-top::before, .divider-xxl-bottom::before, .divider-xxl-start::before, .divider-xxl-end::before {
    content: "";
    background-color: var(--bs-border-color);
    display: block;
    position: absolute;
  }
  .divider-xxl-0::before {
    display: none;
  }
  .divider-xxl-top::before, .divider-xxl-bottom::before {
    height: 1px;
    width: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider-xxl-start::before, .divider-xxl-end::before {
    height: 2rem;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
  }
  .divider-xxl-top {
    margin-top: 2rem;
  }
  .divider-xxl-top::before {
    top: -1rem;
  }
  .divider-xxl-top.divider--md {
    margin-top: 4rem;
  }
  .divider-xxl-top.divider--md::before {
    top: -2rem;
  }
  .divider-xxl-top.divider--lg {
    margin-top: 8rem;
  }
  .divider-xxl-top.divider--lg::before {
    top: -4rem;
  }
  .divider-xxl-start {
    margin-left: 2rem;
  }
  .divider-xxl-start::before {
    left: -1rem;
  }
  .divider-xxl-start.divider--md {
    margin-left: 4rem;
  }
  .divider-xxl-start.divider--md::before {
    left: -2rem;
  }
  .divider-xxl-start.divider--lg {
    margin-left: 8rem;
  }
  .divider-xxl-start.divider--lg::before {
    left: -4rem;
  }
  .divider-xxl-bottom {
    margin-bottom: 2rem;
  }
  .divider-xxl-bottom::before {
    bottom: -1rem;
  }
  .divider-xxl-bottom.divider--md {
    margin-bottom: 4rem;
  }
  .divider-xxl-bottom.divider--md::before {
    bottom: -2rem;
  }
  .divider-xxl-bottom.divider--lg {
    margin-bottom: 8rem;
  }
  .divider-xxl-bottom.divider--lg::before {
    bottom: -4rem;
  }
  .divider-xxl-end {
    margin-right: 2rem;
  }
  .divider-xxl-end::before {
    right: -1rem;
  }
  .divider-xxl-end.divider--md {
    margin-right: 4rem;
  }
  .divider-xxl-end.divider--md::before {
    right: -2rem;
  }
  .divider-xxl-end.divider--lg {
    margin-right: 8rem;
  }
  .divider-xxl-end.divider--lg::before {
    right: -4rem;
  }
  .divider-xxl--md.divider-top {
    margin-top: 4rem;
  }
  .divider-xxl--md.divider-top::before {
    top: -2rem;
  }
  .divider-xxl--md.divider-start {
    margin-left: 4rem;
  }
  .divider-xxl--md.divider-start::before {
    left: -2rem;
  }
  .divider-xxl--md.divider-bottom {
    margin-bottom: 4rem;
  }
  .divider-xxl--md.divider-bottom::before {
    bottom: -2rem;
  }
  .divider-xxl--md.divider-end {
    margin-right: 4rem;
  }
  .divider-xxl--md.divider-end::before {
    right: -2rem;
  }
  .divider-xxl--lg.divider-top {
    margin-top: 8rem;
  }
  .divider-xxl--lg.divider-top::before {
    top: -4rem;
  }
  .divider-xxl--lg.divider-start {
    margin-left: 8rem;
  }
  .divider-xxl--lg.divider-start::before {
    left: -4rem;
  }
  .divider-xxl--lg.divider-bottom {
    margin-bottom: 8rem;
  }
  .divider-xxl--lg.divider-bottom::before {
    bottom: -4rem;
  }
  .divider-xxl--lg.divider-end {
    margin-right: 8rem;
  }
  .divider-xxl--lg.divider-end::before {
    right: -4rem;
  }
}

.divider--short.divider-top::before, .divider--short.divider-bottom::before {
  width: 1.5rem;
}
.divider--short.divider-start::before, .divider--short.divider-end::before {
  height: 1.5rem;
}

.form-label {
  margin-bottom: 1rem;
}

.col-form-label {
  padding-top: calc(0.6rem + 1px);
  padding-bottom: calc(0.6rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.75rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.225rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-body-color-muted);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6rem 1.2rem;
  font-size: calc(1.265rem + 0.18vw);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-gray-400);
  appearance: none;
  border-radius: 0.6rem;
  box-shadow: inset 0 1px 2px rgba(var(--bs-black-rgb), 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .form-control {
    font-size: 1.4rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(var(--bs-black-rgb), 0.075), 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: var(--bs-body-color-muted);
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: var(--bs-body-bg-strong);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.6rem 1.2rem;
  margin: -0.6rem -1.2rem;
  margin-inline-end: 1.2rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg-strong);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-body-bg-strong);
}
.form-control::-webkit-file-upload-button {
  padding: 0.6rem 1.2rem;
  margin: -0.6rem -1.2rem;
  margin-inline-end: 1.2rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg-strong);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--bs-body-bg-strong);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 1.225rem;
  border-radius: 0.4rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.3rem + 0.6vw);
  border-radius: 0.6rem;
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.75rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.2rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.6rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.6rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.6rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.6rem 3.6rem 0.6rem 1.2rem;
  -moz-padding-start: calc(1.2rem - 3px);
  font-size: calc(1.265rem + 0.18vw);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='var%28--bs-gray-800%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.2rem center;
  background-size: 16px 12px;
  border: 1px solid var(--bs-gray-400);
  border-radius: 0.6rem;
  box-shadow: inset 0 1px 2px rgba(var(--bs-black-rgb), 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (min-width: 1200px) {
  .form-select {
    font-size: 1.4rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(var(--bs-black-rgb), 0.075), 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.2rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-gray-200);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.225rem;
  border-radius: 0.4rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.3rem + 0.6vw);
  border-radius: 0.6rem;
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.75rem;
  }
}

.form-check {
  display: block;
  min-height: 2.1rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-body-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid var(--bs-gray-400);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}
.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='var%28--bs-primary-contrast%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='var%28--bs-primary-contrast%29'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='var%28--bs-primary-contrast%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28var%28--bs-black%29, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--bs-primary%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--bs-component-active-color%29'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .modal-header .btn-check[disabled] + .close, .datepicker .btn-check[disabled] + .form-select, .btn-check:disabled + .btn, .modal-header .btn-check:disabled + .close, .datepicker .btn-check:disabled + .form-select {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px var(--bs-body-bg), 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px var(--bs-body-bg), 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: var(--bs-component-active-bg);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(var(--bs-black), 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: tint-color(var(--bs-component-active-bg), 70%);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-gray-300);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(var(--bs-black-rgb), 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--bs-component-active-bg);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(var(--bs-black), 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: tint-color(var(--bs-component-active-bg), 70%);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-gray-300);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(var(--bs-black-rgb), 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-gray-500);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-gray-500);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1.2rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1.2rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn, .input-group .modal-header .close, .modal-header .input-group .close, .input-group .datepicker .form-select, .datepicker .input-group .form-select {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .modal-header .close:focus, .modal-header .input-group .close:focus, .input-group .datepicker .form-select:focus, .datepicker .input-group .form-select:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  font-size: calc(1.265rem + 0.18vw);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-body-bg-strong);
  border: 1px solid var(--bs-gray-400);
  border-radius: 0.6rem;
}
@media (min-width: 1200px) {
  .input-group-text {
    font-size: 1.4rem;
  }
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.modal-header .input-group-lg > .close {
  padding: 0.5rem 1rem;
  font-size: calc(1.3rem + 0.6vw);
  border-radius: 0.6rem;
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn,
  .modal-header .input-group-lg > .close {
    font-size: 1.75rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.modal-header .input-group-sm > .close {
  padding: 0.25rem 0.5rem;
  font-size: 1.225rem;
  border-radius: 0.4rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4.8rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-success);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.225rem;
  color: color-contrast(var(--bs-success));
  background-color: rgba(var(--bs-success), 0.9);
  border-radius: 0.6rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-success);
  padding-right: calc(1.5em + 1.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='var%28--bs-success%29' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3rem) center;
  background-size: calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.2rem);
  background-position: top calc(0.375em + 0.3rem) right calc(0.375em + 0.3rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-success);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 6.6rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='var%28--bs-gray-800%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='var%28--bs-success%29' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.2rem center, center right 3.6rem;
  background-size: 16px 12px, calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success), 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-success);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-success);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-success);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-danger);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.225rem;
  color: color-contrast(var(--bs-danger));
  background-color: rgba(var(--bs-danger), 0.9);
  border-radius: 0.6rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-danger);
  padding-right: calc(1.5em + 1.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='var%28--bs-danger%29'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='var%28--bs-danger%29' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3rem) center;
  background-size: calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.2rem);
  background-position: top calc(0.375em + 0.3rem) right calc(0.375em + 0.3rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-danger);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 6.6rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='var%28--bs-gray-800%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='var%28--bs-danger%29'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='var%28--bs-danger%29' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.2rem center, center right 3.6rem;
  background-size: 16px 12px, calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger), 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-danger);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-danger);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-danger);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.form-label .form-label,
.form-label .form-label > span > p {
  margin-bottom: 0;
}

.form-label {
  display: flex;
  align-items: baseline;
}

.form-check label,
.form-check input,
.form-check input::after {
  cursor: pointer;
}
.form-check label {
  white-space: normal;
}

.form-check-input:not(.disabled), .form-check-input:not([disabled]),
.radio-tile > input:not(.disabled),
.radio-tile > input:not([disabled]),
.check-tile > input:not(.disabled),
.check-tile > input:not([disabled]) {
  cursor: pointer;
}
.form-check-input,
.form-check-input + label > .bullet,
.radio-tile > input,
.radio-tile > input + label > .bullet,
.check-tile > input,
.check-tile > input + label > .bullet {
  position: relative;
  background-color: var(--bs-body-bg);
  border: 1px solid var(--bs-gray-400);
  height: 1em;
  width: 1em;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input,
  .form-check-input + label > .bullet,
  .radio-tile > input,
  .radio-tile > input + label > .bullet,
  .check-tile > input,
  .check-tile > input + label > .bullet {
    transition: none;
  }
}
.form-check-input::after,
.form-check-input + label > .bullet::after,
.radio-tile > input::after,
.radio-tile > input + label > .bullet::after,
.check-tile > input::after,
.check-tile > input + label > .bullet::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input::after,
  .form-check-input + label > .bullet::after,
  .radio-tile > input::after,
  .radio-tile > input + label > .bullet::after,
  .check-tile > input::after,
  .check-tile > input + label > .bullet::after {
    transition: none;
  }
}
.form-check-input[type=checkbox], .form-check-input[type=checkbox] + label > .bullet,
.radio-tile > input[type=checkbox],
.radio-tile > input[type=checkbox] + label > .bullet,
.check-tile > input[type=checkbox],
.check-tile > input[type=checkbox] + label > .bullet {
  border-radius: 0.25em;
}
.form-check-input[type=radio], .form-check-input[type=radio] + label > .bullet,
.radio-tile > input[type=radio],
.radio-tile > input[type=radio] + label > .bullet,
.check-tile > input[type=radio],
.check-tile > input[type=radio] + label > .bullet {
  border-radius: 50%;
}
.form-check-input[type=checkbox]::after,
.form-check-input[type=checkbox] + label > .bullet::after,
.radio-tile > input[type=checkbox]::after,
.radio-tile > input[type=checkbox] + label > .bullet::after,
.check-tile > input[type=checkbox]::after,
.check-tile > input[type=checkbox] + label > .bullet::after {
  font-family: "Font Awesome 6 Pro" !important;
  font-weight: bold;
  color: var(--bs-primary-contrast);
  font-size: 0.7em;
}
.form-check-input[type=checkbox]:checked::after,
.form-check-input[type=checkbox] + label > .bullet::after,
.radio-tile > input[type=checkbox]:checked::after,
.radio-tile > input[type=checkbox] + label > .bullet::after,
.check-tile > input[type=checkbox]:checked::after,
.check-tile > input[type=checkbox] + label > .bullet::after {
  content: "\f00c";
}
.form-check-input[type=radio]::after,
.form-check-input[type=radio] + label > .bullet::after,
.radio-tile > input[type=radio]::after,
.radio-tile > input[type=radio] + label > .bullet::after,
.check-tile > input[type=radio]::after,
.check-tile > input[type=radio] + label > .bullet::after {
  content: "";
  border-radius: 100%;
  height: 0.5em;
  width: 0.5em;
  background-color: var(--bs-primary-contrast);
}
.form-check-input:checked, .form-check-input:checked + label > .bullet,
.radio-tile > input:checked,
.radio-tile > input:checked + label > .bullet,
.check-tile > input:checked,
.check-tile > input:checked + label > .bullet {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.form-check-input:checked[type=checkbox], .form-check-input:checked[type=radio], .form-check-input:checked[type=checkbox] + label > .bullet, .form-check-input:checked[type=radio] + label > .bullet, .form-check-input:checked + label > .bullet[type=checkbox], .form-check-input:checked + label > .bullet[type=radio], .form-check-input:checked + label > .bullet[type=checkbox] + label > .bullet, .form-check-input:checked + label > .bullet[type=radio] + label > .bullet,
.radio-tile > input:checked[type=checkbox],
.radio-tile > input:checked[type=radio],
.radio-tile > input:checked[type=checkbox] + label > .bullet,
.radio-tile > input:checked[type=radio] + label > .bullet,
.radio-tile > input:checked + label > .bullet[type=checkbox],
.radio-tile > input:checked + label > .bullet[type=radio],
.radio-tile > input:checked + label > .bullet[type=checkbox] + label > .bullet,
.radio-tile > input:checked + label > .bullet[type=radio] + label > .bullet,
.check-tile > input:checked[type=checkbox],
.check-tile > input:checked[type=radio],
.check-tile > input:checked[type=checkbox] + label > .bullet,
.check-tile > input:checked[type=radio] + label > .bullet,
.check-tile > input:checked + label > .bullet[type=checkbox],
.check-tile > input:checked + label > .bullet[type=radio],
.check-tile > input:checked + label > .bullet[type=checkbox] + label > .bullet,
.check-tile > input:checked + label > .bullet[type=radio] + label > .bullet {
  background-image: none;
}
.form-check-input:checked[type=checkbox]::after, .form-check-input:checked[type=radio]::after, .form-check-input:checked[type=checkbox] + label > .bullet::after, .form-check-input:checked[type=radio] + label > .bullet::after, .form-check-input:checked + label > .bullet[type=checkbox]::after, .form-check-input:checked + label > .bullet[type=radio]::after, .form-check-input:checked + label > .bullet[type=checkbox] + label > .bullet::after, .form-check-input:checked + label > .bullet[type=radio] + label > .bullet::after,
.radio-tile > input:checked[type=checkbox]::after,
.radio-tile > input:checked[type=radio]::after,
.radio-tile > input:checked[type=checkbox] + label > .bullet::after,
.radio-tile > input:checked[type=radio] + label > .bullet::after,
.radio-tile > input:checked + label > .bullet[type=checkbox]::after,
.radio-tile > input:checked + label > .bullet[type=radio]::after,
.radio-tile > input:checked + label > .bullet[type=checkbox] + label > .bullet::after,
.radio-tile > input:checked + label > .bullet[type=radio] + label > .bullet::after,
.check-tile > input:checked[type=checkbox]::after,
.check-tile > input:checked[type=radio]::after,
.check-tile > input:checked[type=checkbox] + label > .bullet::after,
.check-tile > input:checked[type=radio] + label > .bullet::after,
.check-tile > input:checked + label > .bullet[type=checkbox]::after,
.check-tile > input:checked + label > .bullet[type=radio]::after,
.check-tile > input:checked + label > .bullet[type=checkbox] + label > .bullet::after,
.check-tile > input:checked + label > .bullet[type=radio] + label > .bullet::after {
  opacity: 1;
}
.form-check-input[type=checkbox]:indeterminate, .form-check-input[type=checkbox]:indeterminate + label > .bullet,
.radio-tile > input[type=checkbox]:indeterminate,
.radio-tile > input[type=checkbox]:indeterminate + label > .bullet,
.check-tile > input[type=checkbox]:indeterminate,
.check-tile > input[type=checkbox]:indeterminate + label > .bullet {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  background-image: none;
}
.form-check-input[type=checkbox]:indeterminate::after, .form-check-input[type=checkbox]:indeterminate + label > .bullet::after,
.radio-tile > input[type=checkbox]:indeterminate::after,
.radio-tile > input[type=checkbox]:indeterminate + label > .bullet::after,
.check-tile > input[type=checkbox]:indeterminate::after,
.check-tile > input[type=checkbox]:indeterminate + label > .bullet::after {
  content: "\e404";
  opacity: 1;
}
.form-check-input:disabled,
.radio-tile > input:disabled,
.check-tile > input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label,
.radio-tile > input[disabled] ~ .form-check-label,
.radio-tile > input:disabled ~ .form-check-label,
.check-tile > input[disabled] ~ .form-check-label,
.check-tile > input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.radio-tile [type=checkbox]:checked,
.radio-tile [type=checkbox]:not(:checked),
.radio-tile [type=radio]:checked,
.radio-tile [type=radio]:not(:checked),
.check-tile [type=checkbox]:checked,
.check-tile [type=checkbox]:not(:checked),
.check-tile [type=radio]:checked,
.check-tile [type=radio]:not(:checked) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.input-radio-tiles,
.input-check-tiles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
}

.radio-tile,
.check-tile {
  display: block;
  margin: 0 0.5rem;
}
.radio-tile label,
.radio-tile input,
.radio-tile input::after,
.radio-tile .bullet,
.radio-tile .bullet::after,
.check-tile label,
.check-tile input,
.check-tile input::after,
.check-tile .bullet,
.check-tile .bullet::after {
  cursor: pointer;
}
.radio-tile label,
.check-tile label {
  white-space: normal;
  min-width: 11.5rem;
}
.radio-tile input:disabled + label,
.radio-tile input[disabled] + label,
.check-tile input:disabled + label,
.check-tile input[disabled] + label {
  background-color: var(--bs-body-bg-strong);
  border-color: var(--bs-body-bg-strong);
}
.radio-tile input:disabled + label,
.radio-tile input:disabled + label .bullet,
.radio-tile input:disabled + label .bullet::after,
.radio-tile input[disabled] + label,
.radio-tile input[disabled] + label .bullet,
.radio-tile input[disabled] + label .bullet::after,
.check-tile input:disabled + label,
.check-tile input:disabled + label .bullet,
.check-tile input:disabled + label .bullet::after,
.check-tile input[disabled] + label,
.check-tile input[disabled] + label .bullet,
.check-tile input[disabled] + label .bullet::after {
  cursor: not-allowed;
}
.radio-tile > input + label > .bullet,
.check-tile > input + label > .bullet {
  display: block;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.radio-tile .tile-icon,
.radio-tile .tile-label,
.check-tile .tile-icon,
.check-tile .tile-label {
  display: flex;
  justify-content: center;
}
.radio-tile .tile-label,
.check-tile .tile-label {
  padding-top: 0;
}
.radio-tile [type=checkbox]:checked:not(:disabled) + label, .radio-tile [type=checkbox]:checked:not([disabled] + label),
.radio-tile [type=checkbox]:indeterminate:not(:disabled) + label,
.radio-tile [type=checkbox]:indeterminate:not([disabled] + label),
.radio-tile [type=checkbox]:hover:not(:disabled) + label,
.radio-tile [type=checkbox]:hover:not([disabled] + label),
.radio-tile [type=checkbox]:focus:not(:disabled) + label,
.radio-tile [type=checkbox]:focus:not([disabled] + label),
.radio-tile [type=radio]:checked:not(:disabled) + label,
.radio-tile [type=radio]:checked:not([disabled] + label),
.radio-tile [type=radio]:hover:not(:disabled) + label,
.radio-tile [type=radio]:hover:not([disabled] + label),
.radio-tile [type=radio]:focus:not(:disabled) + label,
.radio-tile [type=radio]:focus:not([disabled] + label),
.check-tile [type=checkbox]:checked:not(:disabled) + label,
.check-tile [type=checkbox]:checked:not([disabled] + label),
.check-tile [type=checkbox]:indeterminate:not(:disabled) + label,
.check-tile [type=checkbox]:indeterminate:not([disabled] + label),
.check-tile [type=checkbox]:hover:not(:disabled) + label,
.check-tile [type=checkbox]:hover:not([disabled] + label),
.check-tile [type=checkbox]:focus:not(:disabled) + label,
.check-tile [type=checkbox]:focus:not([disabled] + label),
.check-tile [type=radio]:checked:not(:disabled) + label,
.check-tile [type=radio]:checked:not([disabled] + label),
.check-tile [type=radio]:hover:not(:disabled) + label,
.check-tile [type=radio]:hover:not([disabled] + label),
.check-tile [type=radio]:focus:not(:disabled) + label,
.check-tile [type=radio]:focus:not([disabled] + label) {
  border-color: var(--bs-primary);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}
.radio-tile [type=checkbox]:checked:not(:disabled) + label > .bullet, .radio-tile [type=checkbox]:checked:not([disabled] + label) > .bullet,
.radio-tile [type=checkbox]:indeterminate:not(:disabled) + label > .bullet,
.radio-tile [type=checkbox]:indeterminate:not([disabled] + label) > .bullet,
.radio-tile [type=checkbox]:hover:not(:disabled) + label > .bullet,
.radio-tile [type=checkbox]:hover:not([disabled] + label) > .bullet,
.radio-tile [type=checkbox]:focus:not(:disabled) + label > .bullet,
.radio-tile [type=checkbox]:focus:not([disabled] + label) > .bullet,
.radio-tile [type=radio]:checked:not(:disabled) + label > .bullet,
.radio-tile [type=radio]:checked:not([disabled] + label) > .bullet,
.radio-tile [type=radio]:hover:not(:disabled) + label > .bullet,
.radio-tile [type=radio]:hover:not([disabled] + label) > .bullet,
.radio-tile [type=radio]:focus:not(:disabled) + label > .bullet,
.radio-tile [type=radio]:focus:not([disabled] + label) > .bullet,
.check-tile [type=checkbox]:checked:not(:disabled) + label > .bullet,
.check-tile [type=checkbox]:checked:not([disabled] + label) > .bullet,
.check-tile [type=checkbox]:indeterminate:not(:disabled) + label > .bullet,
.check-tile [type=checkbox]:indeterminate:not([disabled] + label) > .bullet,
.check-tile [type=checkbox]:hover:not(:disabled) + label > .bullet,
.check-tile [type=checkbox]:hover:not([disabled] + label) > .bullet,
.check-tile [type=checkbox]:focus:not(:disabled) + label > .bullet,
.check-tile [type=checkbox]:focus:not([disabled] + label) > .bullet,
.check-tile [type=radio]:checked:not(:disabled) + label > .bullet,
.check-tile [type=radio]:checked:not([disabled] + label) > .bullet,
.check-tile [type=radio]:hover:not(:disabled) + label > .bullet,
.check-tile [type=radio]:hover:not([disabled] + label) > .bullet,
.check-tile [type=radio]:focus:not(:disabled) + label > .bullet,
.check-tile [type=radio]:focus:not([disabled] + label) > .bullet {
  border-color: var(--bs-primary);
}

.input-wrapper__content:not(.is-invalid).has-warning .form-control {
  border-color: var(--bs-warning);
}

.form-control:disabled.appear-editable, .form-control[readonly].appear-editable {
  background-color: var(--bs-body-bg);
}

input[type=date].form-control.is-invalid,
input[type=time].form-control.is-invalid,
input[type=datetime-local].form-control.is-invalid,
input[type=month].form-control.is-invalid {
  padding-right: 0;
}

input[type=date],
input[type=datetime-local],
input[type=time] {
  position: relative;
}
input[type=date]::after,
input[type=datetime-local]::after,
input[type=time]::after {
  display: block;
  font-family: "Font Awesome 6 Pro" !important;
  font-weight: 300;
  position: absolute;
  top: 50%;
  right: 1.2rem;
  transform: translateY(-50%);
  font-size: inherit;
  color: inherit;
  z-index: 0;
}
input[type=date]::-webkit-calendar-picker-indicator,
input[type=datetime-local]::-webkit-calendar-picker-indicator,
input[type=time]::-webkit-calendar-picker-indicator {
  opacity: 0;
  z-index: 1;
}

input[type=date]::after,
input[type=datetime-local]::after {
  content: "\f133";
}

input[type=time]::after {
  content: "\f017";
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-right-color: rgba(0, 0, 0, 0);
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-right-color: rgba(0, 0, 0, 0);
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left-color: rgba(0, 0, 0, 0);
}

.input-switch {
  margin: 0;
}
.input-switch .switch-checkbox {
  visibility: hidden;
  position: absolute;
  height: 0;
  width: 0;
}
.input-switch .switch-checkbox:checked ~ .switch {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.input-switch .switch-checkbox:checked ~ .switch::before {
  left: 1.4rem;
}
.input-switch .switch-checkbox:disabled ~ .switch {
  cursor: not-allowed;
}
.input-switch .switch-checkbox:checked:disabled ~ .switch {
  background-color: var(--bs-primary-light);
  border-color: var(--bs-primary-light);
}
.input-switch .switch {
  display: block;
  position: relative;
  height: 1.7rem;
  width: 3rem;
  border: 2px solid var(--bs-body-bg-strong);
  background-color: var(--bs-body-bg-muted);
  border-radius: 1.7rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-switch .switch {
    transition: none;
  }
}
.input-switch .switch::before {
  content: "";
  height: 1.1rem;
  width: 1.1rem;
  display: block;
  position: absolute;
  background-color: var(--bs-body-bg);
  border-radius: 100%;
  border: 2px solid var(--bs-body-bg-strong);
  top: 0.1rem;
  left: 0.2rem;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-black-rgb), 0.15);
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-switch .switch::before {
    transition: none;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: var(--bs-primary);
}
.link-primary:hover, .link-primary:focus {
  color: tint-color(var(--bs-primary), 20%);
}

.link-secondary {
  color: var(--bs-secondary);
}
.link-secondary:hover, .link-secondary:focus {
  color: tint-color(var(--bs-secondary), 20%);
}

.link-success {
  color: var(--bs-success);
}
.link-success:hover, .link-success:focus {
  color: tint-color(var(--bs-success), 20%);
}

.link-info {
  color: var(--bs-info);
}
.link-info:hover, .link-info:focus {
  color: tint-color(var(--bs-info), 20%);
}

.link-warning {
  color: var(--bs-warning);
}
.link-warning:hover, .link-warning:focus {
  color: tint-color(var(--bs-warning), 20%);
}

.link-danger {
  color: var(--bs-danger);
}
.link-danger:hover, .link-danger:focus {
  color: tint-color(var(--bs-danger), 20%);
}

.link-light {
  color: var(--bs-light);
}
.link-light:hover, .link-light:focus {
  color: tint-color(var(--bs-light), 20%);
}

.link-dark {
  color: var(--bs-dark);
}
.link-dark:hover, .link-dark:focus {
  color: tint-color(var(--bs-dark), 20%);
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 425px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1024px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1440px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 2560px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate, .btn-inline {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.icon-size-xxs {
  height: 1rem;
  width: 1rem;
  font-size: 1rem !important;
}

.icon-size-xs {
  height: 1.2rem;
  width: 1.2rem;
  font-size: 1.2rem !important;
}

.icon-size-sm {
  height: 1.4rem;
  width: 1.4rem;
  font-size: 1.4rem !important;
}

.icon-size-md {
  height: 1.6rem;
  width: 1.6rem;
  font-size: 1.6rem !important;
}

.icon-size-lg {
  height: 2.4rem;
  width: 2.4rem;
  font-size: 2.4rem !important;
}

.icon-size-xl {
  height: 3.4rem;
  width: 3.4rem;
  font-size: 3.4rem !important;
}

.icon-size-xxl {
  height: 6rem;
  width: 6rem;
  font-size: 6rem !important;
}

.icon-size-xxxl {
  height: 10rem;
  width: 10rem;
  font-size: 10rem !important;
}

.icon-container-size-xxs {
  height: 1.6rem;
  width: 1.6rem;
  font-size: 1rem;
}

.icon-container-size-xs {
  height: 2rem;
  width: 2rem;
  font-size: 1.2rem;
}

.icon-container-size-sm {
  height: 2.6rem;
  width: 2.6rem;
  font-size: 1.4rem;
}

.icon-container-size-md {
  height: 3.6rem;
  width: 3.6rem;
  font-size: 1.6rem;
}

.icon-container-size-lg {
  height: 4.6rem;
  width: 4.6rem;
  font-size: 2.4rem;
}

.icon-container-size-xl {
  height: 7rem;
  width: 7rem;
  font-size: 3.4rem;
}

.icon-container-size-xxl {
  height: 10rem;
  width: 10rem;
  font-size: 6rem;
}

.icon-container-size-xxxl {
  height: 14rem;
  width: 14rem;
  font-size: 10rem;
}

.bg-icon {
  background-color: var(--bs-body-bg-strong);
  color: var(--bs-body-color-strong);
}

.icon-shift-up {
  margin-bottom: 0.2rem;
}

.icon-shift-down {
  margin-top: 0.2rem;
}

.icon-shift-left {
  margin-right: 0.1rem;
}
@media (max-width: 767.98px) {
  .icon-shift-left {
    margin-right: 0;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: 1px solid var(--bs-gray-300);
  border-radius: 0.6rem;
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-black-rgb), 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-body-color-muted);
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.6rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item.list-group-item-action {
  width: 100%;
  color: var(--bs-link-color);
  text-align: inherit;
  font-weight: 500;
  cursor: pointer;
}
.list-group-item.list-group-item-action:disabled {
  cursor: not-allowed;
}
.list-group-item.list-group-item-action:hover, .list-group-item.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-link-color-hover);
  text-decoration: none;
  background-color: var(--bs-body-bg-muted);
}
.list-group-item.list-group-item-action:active {
  color: var(--bs-link-color);
  background-color: var(--bs-component-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border: 1px solid var(--bs-border-color);
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .list-group-item {
    transition: none;
  }
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-body-color-muted);
  pointer-events: none;
  background-color: var(--bs-body-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-component-active-color);
  background-color: var(--bs-component-active-bg);
  border-color: var(--bs-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.6rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.6rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 425px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.6rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.6rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.6rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.6rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 2560px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.6rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item:last-child,
.list-group-flush > *:not(.list-group-item):last-child > .list-group-item {
  border-bottom-width: 0;
}
.list-group-flush .list-group-item {
  border-width: 0 0 1px;
}

.list-group-item-primary {
  color: var(--bs-primary-light-contrast);
  background-color: var(--bs-primary-light);
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: var(--bs-primary-contrast);
  background-color: var(--bs-primary);
}
.list-group-item-primary.list-group-item-action.active {
  color: var(--bs-primary-contrast);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary-contrast);
}

.list-group-item-secondary {
  color: var(--bs-secondary-light-contrast);
  background-color: var(--bs-secondary-light);
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: var(--bs-secondary-contrast);
  background-color: var(--bs-secondary);
}
.list-group-item-secondary.list-group-item-action.active {
  color: var(--bs-secondary-contrast);
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary-contrast);
}

.list-group-item-success {
  color: var(--bs-success-light-contrast);
  background-color: var(--bs-success-light);
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: var(--bs-success-contrast);
  background-color: var(--bs-success);
}
.list-group-item-success.list-group-item-action.active {
  color: var(--bs-success-contrast);
  background-color: var(--bs-success);
  border-color: var(--bs-success-contrast);
}

.list-group-item-info {
  color: var(--bs-info-light-contrast);
  background-color: var(--bs-info-light);
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: var(--bs-info-contrast);
  background-color: var(--bs-info);
}
.list-group-item-info.list-group-item-action.active {
  color: var(--bs-info-contrast);
  background-color: var(--bs-info);
  border-color: var(--bs-info-contrast);
}

.list-group-item-warning {
  color: var(--bs-warning-light-contrast);
  background-color: var(--bs-warning-light);
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: var(--bs-warning-contrast);
  background-color: var(--bs-warning);
}
.list-group-item-warning.list-group-item-action.active {
  color: var(--bs-warning-contrast);
  background-color: var(--bs-warning);
  border-color: var(--bs-warning-contrast);
}

.list-group-item-danger {
  color: var(--bs-danger-light-contrast);
  background-color: var(--bs-danger-light);
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: var(--bs-danger-contrast);
  background-color: var(--bs-danger);
}
.list-group-item-danger.list-group-item-action.active {
  color: var(--bs-danger-contrast);
  background-color: var(--bs-danger);
  border-color: var(--bs-danger-contrast);
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: var(--bs-light-contrast);
  background-color: var(--bs-light);
}
.list-group-item-light.list-group-item-action.active {
  color: var(--bs-light-contrast);
  background-color: var(--bs-light);
  border-color: var(--bs-light-contrast);
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: var(--bs-dark-contrast);
  background-color: var(--bs-dark);
}
.list-group-item-dark.list-group-item-action.active {
  color: var(--bs-dark-contrast);
  background-color: var(--bs-dark);
  border-color: var(--bs-dark-contrast);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-body-color);
  pointer-events: auto;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.6rem;
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-black-rgb), 0.075);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-black);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid var(--bs-border-color);
  border-top-left-radius: calc(0.6rem - 1px);
  border-top-right-radius: calc(0.6rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid var(--bs-border-color);
  border-bottom-right-radius: calc(0.6rem - 1px);
  border-bottom-left-radius: calc(0.6rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 425px) {
  .modal-dialog {
    max-width: 500px;
    margin: 3.5rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 7rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 7rem);
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(var(--bs-black-rgb), 0.15);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1440px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 424.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1023.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1439.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 2559.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 1rem 2.5rem;
  font-weight: 500;
  color: var(--bs-body-color-muted);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-link-color-hover);
  text-decoration: none;
}
.nav-link.disabled {
  color: var(--bs-body-color-muted);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0 solid rgba(0, 0, 0, 0);
}
.nav-tabs .nav-link {
  margin-bottom: 0;
  background: none;
  border: 0 solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: rgba(0, 0, 0, 0);
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: var(--bs-body-color-muted);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-primary-accessible);
  background-color: var(--bs-body-bg-muted);
  border-color: var(--bs-primary-accessible);
}
.nav-tabs .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.6rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-component-active-color);
  background-color: var(--bs-component-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
.nav-tabs .nav-link {
  padding-top: 1.5rem;
  border-bottom: 0.5rem solid rgba(0, 0, 0, 0);
  white-space: nowrap;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  background-color: var(--bs-body-bg-muted);
}
.nav-tabs .nav-link.active {
  border-bottom-color: var(--bs-primary-accessible);
}
.nav-tabs ~ div .action-buttons {
  padding: 0 2.5rem;
}

.side-nav .nav-tabs .nav-link {
  border-left: 0.5rem solid rgba(0, 0, 0, 0);
  border-bottom: 0;
  padding: 1.5rem 1rem;
}
.side-nav .nav-tabs .nav-link.active {
  border-left-color: var(--bs-primary-accessible);
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.8rem;
  padding-right: 1.5rem;
  padding-bottom: 0.8rem;
  padding-left: 1.5rem;
}
.navbar > .container-xxl, .navbar > .container-xl, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 1.5rem;
  font-size: calc(1.3rem + 0.6vw);
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .navbar-brand {
    font-size: 1.75rem;
  }
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.3rem + 0.6vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.6rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .navbar-toggler {
    font-size: 1.75rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 425px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 2560px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(var(--bs-black), 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(var(--bs-black), 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(var(--bs-black), 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(var(--bs-black), 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(var(--bs-black), 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(var(--bs-black), 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(var(--bs-black), 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(var(--bs-black), 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(var(--bs-black), 0.9);
}

.navbar-dark .navbar-brand {
  color: var(--bs-white);
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: var(--bs-white);
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(var(--bs-white), 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(var(--bs-white), 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(var(--bs-white), 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--bs-white);
}
.navbar-dark .navbar-toggler {
  color: rgba(var(--bs-white), 0.55);
  border-color: rgba(var(--bs-white), 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28var%28--bs-white%29, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(var(--bs-white), 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: var(--bs-white);
}

.navbar {
  height: 6.4rem;
  min-height: 6.4rem;
}
.navbar .container-fluid {
  width: 100%;
}

.navbar-brand {
  display: flex;
  align-items: center;
}
.navbar-brand,
.navbar-brand img {
  max-height: 3.6rem;
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-body-color);
  visibility: hidden;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-black-rgb), 0.075);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1045;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-black);
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--offcanvas-horizontal-width);
  border-right: 0px solid var(--bs-border-color);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--offcanvas-horizontal-width);
  border-left: 0px solid var(--bs-border-color);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0px solid var(--bs-border-color);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0px solid var(--bs-border-color);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.offcanvas {
  --offcanvas-horizontal-width: 500px;
}
.offcanvas-sm {
  --offcanvas-horizontal-width: 300px;
}
.offcanvas-md {
  --offcanvas-horizontal-width: 500px;
}
.offcanvas-lg {
  --offcanvas-horizontal-width: 800px;
}
.offcanvas-xl {
  --offcanvas-horizontal-width: 1140px;
}
.offcanvas.show, .offcanvas.showing, .offcanvas.hiding {
  visibility: visible;
}
.offcanvas.show:not(.hiding), .offcanvas.showing:not(.hiding) {
  transform: none;
}

.offcanvas.hiding.offcanvas-top {
  transform: translateY(-100%);
}
.offcanvas.hiding.offcanvas-start {
  transform: translateX(-100%);
}
.offcanvas.hiding.offcanvas-bottom {
  transform: translateY(100%);
}
.offcanvas.hiding.offcanvas-end {
  transform: translateX(100%);
}

.offcanvas-body {
  --scrollbar-bg: var(--bs-body-bg);
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.offcanvas .form {
  padding: 1rem 1rem;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: var(--bs-link-color);
  background-color: var(--bs-white);
  border: 1px solid var(--bs-gray-300);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-link-color-hover);
  text-decoration: none;
  background-color: var(--bs-gray-200);
  border-color: var(--bs-gray-300);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-link-color-hover);
  background-color: var(--bs-gray-200);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: var(--bs-component-active-color);
  background-color: var(--bs-component-active-bg);
  border-color: var(--bs-component-active-bg);
}
.page-item.disabled .page-link {
  color: var(--bs-body-color-muted);
  pointer-events: none;
  background-color: var(--bs-white);
  border-color: var(--bs-gray-300);
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .pagination-lg .page-link {
    font-size: 1.75rem;
  }
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.225rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.225rem;
  word-wrap: break-word;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-body-bg);
  border-radius: 0.6rem;
  box-shadow: 0 1rem 3rem rgba(var(--bs-black-rgb), 0.175);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: var(--bs-body-bg);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: var(--bs-body-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: var(--bs-body-bg);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: var(--bs-body-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: var(--bs-body-bg);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: var(--bs-body-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid var(--bs-body-bg);
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: var(--bs-body-bg);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: var(--bs-body-bg);
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: calc(1.265rem + 0.18vw);
  background-color: var(--bs-body-bg);
  border-bottom: 1px solid var(--bs-body-bg);
  border-top-left-radius: calc(0.6rem - 1px);
  border-top-right-radius: calc(0.6rem - 1px);
}
@media (min-width: 1200px) {
  .popover-header {
    font-size: 1.4rem;
  }
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: var(--bs-body-color);
}

.popover-dropdown-menu .popover-body,
.popover-no-padding .popover-body {
  padding: 0;
}

.popover-avatar {
  max-width: 500px;
}

.popover-body p:last-of-type {
  padding: 0;
  margin: 0;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 1.05rem;
  background-color: var(--bs-gray-200);
  border-radius: 0.6rem;
  box-shadow: inset 0 1px 2px rgba(var(--bs-black-rgb), 0.075);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-white);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-primary);
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(var(--bs-white), 0.15) 25%, transparent 25%, transparent 50%, rgba(var(--bs-white), 0.15) 50%, rgba(var(--bs-white), 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

@media (hover: hover) {
  :root {
    --scrollbar-bg: var(--bs-body-bg-strong);
    --scrollbar-thumb-bg: var(--bs-gray-400);
    --scrollbar-thumb-bg-hover: var(--bs-gray-500);
  }
  /* The emerging W3C standard
  that is currently Firefox-only */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-bg) var(--scrollbar-bg);
  }
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 1.4rem;
  }
  *::-webkit-scrollbar-track {
    background: var(--scrollbar-bg);
  }
  *::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-bg);
    border-radius: 2rem;
    border: 0.5rem solid var(--scrollbar-bg);
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-bg-hover);
  }
}
.bg-primary {
  --scrollbar-bg: var(--bs-primary);
  --scrollbar-thumb-bg: var(--bs-primary-contrast);
  --scrollbar-thumb-bg-hover: var(--bs-primary-contrast);
}

.bg-secondary {
  --scrollbar-bg: var(--bs-secondary);
  --scrollbar-thumb-bg: var(--bs-secondary-contrast);
  --scrollbar-thumb-bg-hover: var(--bs-secondary-contrast);
}

.bg-success {
  --scrollbar-bg: var(--bs-success);
  --scrollbar-thumb-bg: var(--bs-success-contrast);
  --scrollbar-thumb-bg-hover: var(--bs-success-contrast);
}

.bg-info {
  --scrollbar-bg: var(--bs-info);
  --scrollbar-thumb-bg: var(--bs-info-contrast);
  --scrollbar-thumb-bg-hover: var(--bs-info-contrast);
}

.bg-warning {
  --scrollbar-bg: var(--bs-warning);
  --scrollbar-thumb-bg: var(--bs-warning-contrast);
  --scrollbar-thumb-bg-hover: var(--bs-warning-contrast);
}

.bg-danger {
  --scrollbar-bg: var(--bs-danger);
  --scrollbar-thumb-bg: var(--bs-danger-contrast);
  --scrollbar-thumb-bg-hover: var(--bs-danger-contrast);
}

.bg-light {
  --scrollbar-bg: var(--bs-light);
  --scrollbar-thumb-bg: var(--bs-light-contrast);
  --scrollbar-thumb-bg-hover: var(--bs-light-contrast);
}

.bg-dark {
  --scrollbar-bg: var(--bs-dark);
  --scrollbar-thumb-bg: var(--bs-dark-contrast);
  --scrollbar-thumb-bg-hover: var(--bs-dark-contrast);
}

.skeleton-loader {
  --skeleton-loader-border-radius: 10rem;
  content: " ";
  display: block;
  height: 2.1rem;
  width: 50%;
  overflow: hidden;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  border-radius: var(--skeleton-loader-border-radius);
}
.skeleton-loader.skeleton-loader-short {
  width: 33%;
}
.skeleton-loader.skeleton-loader-long {
  width: 100%;
}
.skeleton-loader::before, .skeleton-loader::after {
  content: "";
  height: calc(100% - 4px);
  width: 100%;
  display: block;
  position: absolute;
  top: 2px;
  border-radius: var(--skeleton-loader-border-radius);
}
.skeleton-loader::before {
  background-color: var(--bs-body-bg-strong);
  left: 0%;
}
.skeleton-loader::after {
  background: linear-gradient(90deg, var(--bs-body-bg-strong) 0%, var(--bs-body-bg-muted) 50%, var(--bs-body-bg-strong) 100%);
  left: -100%;
  animation: skeleton-loader 2s linear infinite;
}
.skeleton-loader:empty {
  display: block !important;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.table {
  --bs-table-bg: var(--bs-body-bg-strong);
  --bs-table-accent-bg: var(--bs-body-bg);
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: var(--bs-body-bg-strong);
  --bs-table-active-color: var(--bs-component-active-color);
  --bs-table-active-bg: var(--bs-component-active-bg);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: var(--bs-body-bg-muted);
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-body-color);
  vertical-align: top;
  border-color: var(--bs-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > tbody th,
.table > tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
.table > thead {
  vertical-align: bottom;
}
.table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 1;
  border-top: none;
}
.table > thead > tr > th:first-child {
  left: 0;
  z-index: 3;
}
.table th {
  font-weight: 500;
}
.table th,
.table td {
  vertical-align: middle;
  border-top: 1px solid var(--bs-border-color);
  border-bottom: 0;
  max-width: 45rem;
}
.table > :not(:first-child) {
  border-top: 2px solid var(--bs-border-color);
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}
.table-sm th,
.table-sm td {
  max-width: 30rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(
      --bs-table-striped-bg
  );
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(
      --bs-table-active-bg
  );
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr {
  cursor: pointer;
}
.table-hover > tbody > tr td {
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .table-hover > tbody > tr td {
    transition: none;
  }
}
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(
      --bs-table-hover-bg
  );
  color: var(--bs-table-hover-color);
}
.table-hover > tbody > tr:hover td, .table-hover > tbody > tr:hover + tr td {
  border-top-color: var(--bs-table-hover-color);
}
.table-hover > tbody > tr:hover td {
  border-bottom-color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: var(--bs-primary);
  --bs-table-striped-bg: var(--bs-primary-hover);
  --bs-table-striped-color: var(--bs-primary-hover-contrast);
  --bs-table-active-bg: var(--bs-primary);
  --bs-table-active-color: var(--bs-primary-contrast);
  --bs-table-accent-bg: var(--bs-primary);
  --bs-table-accent-color: var(--bs-primary-contrast);
  --bs-table-hover-bg: var(--bs-primary-hover);
  --bs-table-hover-color: var(--bs-primary-hover-contrast);
  color: var(--bs-primary-contrast);
  border-color: var(--bs-primary-contrast);
}

.table-secondary {
  --bs-table-bg: var(--bs-secondary);
  --bs-table-striped-bg: var(--bs-secondary-hover);
  --bs-table-striped-color: var(--bs-secondary-hover-contrast);
  --bs-table-active-bg: var(--bs-secondary);
  --bs-table-active-color: var(--bs-secondary-contrast);
  --bs-table-accent-bg: var(--bs-secondary);
  --bs-table-accent-color: var(--bs-secondary-contrast);
  --bs-table-hover-bg: var(--bs-secondary-hover);
  --bs-table-hover-color: var(--bs-secondary-hover-contrast);
  color: var(--bs-secondary-contrast);
  border-color: var(--bs-secondary-contrast);
}

.table-success {
  --bs-table-bg: var(--bs-success);
  --bs-table-striped-bg: var(--bs-success-hover);
  --bs-table-striped-color: var(--bs-success-hover-contrast);
  --bs-table-active-bg: var(--bs-success);
  --bs-table-active-color: var(--bs-success-contrast);
  --bs-table-accent-bg: var(--bs-success);
  --bs-table-accent-color: var(--bs-success-contrast);
  --bs-table-hover-bg: var(--bs-success-hover);
  --bs-table-hover-color: var(--bs-success-hover-contrast);
  color: var(--bs-success-contrast);
  border-color: var(--bs-success-contrast);
}

.table-info {
  --bs-table-bg: var(--bs-info);
  --bs-table-striped-bg: var(--bs-info-hover);
  --bs-table-striped-color: var(--bs-info-hover-contrast);
  --bs-table-active-bg: var(--bs-info);
  --bs-table-active-color: var(--bs-info-contrast);
  --bs-table-accent-bg: var(--bs-info);
  --bs-table-accent-color: var(--bs-info-contrast);
  --bs-table-hover-bg: var(--bs-info-hover);
  --bs-table-hover-color: var(--bs-info-hover-contrast);
  color: var(--bs-info-contrast);
  border-color: var(--bs-info-contrast);
}

.table-warning {
  --bs-table-bg: var(--bs-warning);
  --bs-table-striped-bg: var(--bs-warning-hover);
  --bs-table-striped-color: var(--bs-warning-hover-contrast);
  --bs-table-active-bg: var(--bs-warning);
  --bs-table-active-color: var(--bs-warning-contrast);
  --bs-table-accent-bg: var(--bs-warning);
  --bs-table-accent-color: var(--bs-warning-contrast);
  --bs-table-hover-bg: var(--bs-warning-hover);
  --bs-table-hover-color: var(--bs-warning-hover-contrast);
  color: var(--bs-warning-contrast);
  border-color: var(--bs-warning-contrast);
}

.table-danger {
  --bs-table-bg: var(--bs-danger);
  --bs-table-striped-bg: var(--bs-danger-hover);
  --bs-table-striped-color: var(--bs-danger-hover-contrast);
  --bs-table-active-bg: var(--bs-danger);
  --bs-table-active-color: var(--bs-danger-contrast);
  --bs-table-accent-bg: var(--bs-danger);
  --bs-table-accent-color: var(--bs-danger-contrast);
  --bs-table-hover-bg: var(--bs-danger-hover);
  --bs-table-hover-color: var(--bs-danger-hover-contrast);
  color: var(--bs-danger-contrast);
  border-color: var(--bs-danger-contrast);
}

.table-light {
  --bs-table-bg: var(--bs-light);
  --bs-table-striped-bg: var(--bs-light-hover);
  --bs-table-striped-color: var(--bs-light-hover-contrast);
  --bs-table-active-bg: var(--bs-light);
  --bs-table-active-color: var(--bs-light-contrast);
  --bs-table-accent-bg: var(--bs-light);
  --bs-table-accent-color: var(--bs-light-contrast);
  --bs-table-hover-bg: var(--bs-light-hover);
  --bs-table-hover-color: var(--bs-light-hover-contrast);
  color: var(--bs-light-contrast);
  border-color: var(--bs-light-contrast);
}

.table-dark {
  --bs-table-bg: var(--bs-dark);
  --bs-table-striped-bg: var(--bs-dark-hover);
  --bs-table-striped-color: var(--bs-dark-hover-contrast);
  --bs-table-active-bg: var(--bs-dark);
  --bs-table-active-color: var(--bs-dark-contrast);
  --bs-table-accent-bg: var(--bs-dark);
  --bs-table-accent-color: var(--bs-dark-contrast);
  --bs-table-hover-bg: var(--bs-dark-hover);
  --bs-table-hover-color: var(--bs-dark-hover-contrast);
  color: var(--bs-dark-contrast);
  border-color: var(--bs-dark-contrast);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 424.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1023.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 2559.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
table.timeline {
  position: relative;
  z-index: 0;
}
table.timeline .timeline-cell {
  padding: 0.5rem 0.5rem;
}
table.timeline .timeline-icon-cell {
  position: relative;
  z-index: 0;
}
table.timeline .timeline-icon-cell::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 2px;
  background-color: var(--bs-primary-light);
}
table.timeline .timeline-icon-cell .timeline-icon {
  display: block;
  background-color: var(--bs-body-bg);
  color: var(--bs-primary-light);
  border: 2px solid var(--bs-body-bg);
  border-radius: 100%;
}

.toast {
  width: 420px;
  max-width: 100%;
  font-size: calc(1.265rem + 0.18vw);
  color: var(--bs-body-color);
  pointer-events: auto;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0);
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-black-rgb), 0.15);
  border-radius: 0.6rem;
}
@media (min-width: 1200px) {
  .toast {
    font-size: 1.4rem;
  }
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.5rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border-bottom: 0 solid var(--bs-border-color);
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
}
.toast-header .btn-close {
  margin-right: -0.75rem;
  margin-left: 1.5rem;
}

.toast-body {
  padding: 1.5rem;
  word-wrap: break-word;
}

.toast-container {
  display: block;
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  z-index: 2000;
}

.toast-primary {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: var(--bs-primary-contrast);
}
.toast-primary .toast-header {
  background-color: var(--bs-primary-light);
  border-color: var(--bs-primary);
  color: var(--bs-primary-light-contrast);
}
.toast-primary a,
.toast-primary a:hover,
.toast-primary .toast-header a,
.toast-primary .toast-header a:hover {
  color: var(--bs-primary-contrast);
}

.toast-secondary {
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
  color: var(--bs-secondary-contrast);
}
.toast-secondary .toast-header {
  background-color: var(--bs-secondary-light);
  border-color: var(--bs-secondary);
  color: var(--bs-secondary-light-contrast);
}
.toast-secondary a,
.toast-secondary a:hover,
.toast-secondary .toast-header a,
.toast-secondary .toast-header a:hover {
  color: var(--bs-secondary-contrast);
}

.toast-success {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
  color: var(--bs-success-contrast);
}
.toast-success .toast-header {
  background-color: var(--bs-success-light);
  border-color: var(--bs-success);
  color: var(--bs-success-light-contrast);
}
.toast-success a,
.toast-success a:hover,
.toast-success .toast-header a,
.toast-success .toast-header a:hover {
  color: var(--bs-success-contrast);
}

.toast-info {
  background-color: var(--bs-info);
  border-color: var(--bs-info);
  color: var(--bs-info-contrast);
}
.toast-info .toast-header {
  background-color: var(--bs-info-light);
  border-color: var(--bs-info);
  color: var(--bs-info-light-contrast);
}
.toast-info a,
.toast-info a:hover,
.toast-info .toast-header a,
.toast-info .toast-header a:hover {
  color: var(--bs-info-contrast);
}

.toast-warning {
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
  color: var(--bs-warning-contrast);
}
.toast-warning .toast-header {
  background-color: var(--bs-warning-light);
  border-color: var(--bs-warning);
  color: var(--bs-warning-light-contrast);
}
.toast-warning a,
.toast-warning a:hover,
.toast-warning .toast-header a,
.toast-warning .toast-header a:hover {
  color: var(--bs-warning-contrast);
}

.toast-danger {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
  color: var(--bs-danger-contrast);
}
.toast-danger .toast-header {
  background-color: var(--bs-danger-light);
  border-color: var(--bs-danger);
  color: var(--bs-danger-light-contrast);
}
.toast-danger a,
.toast-danger a:hover,
.toast-danger .toast-header a,
.toast-danger .toast-header a:hover {
  color: var(--bs-danger-contrast);
}

.toast-light {
  background-color: var(--bs-light);
  border-color: var(--bs-light);
  color: var(--bs-light-contrast);
}
.toast-light .toast-header {
  border-color: var(--bs-light);
}
.toast-light a,
.toast-light a:hover,
.toast-light .toast-header a,
.toast-light .toast-header a:hover {
  color: var(--bs-light-contrast);
}

.toast-dark {
  background-color: var(--bs-dark);
  border-color: var(--bs-dark);
  color: var(--bs-dark-contrast);
}
.toast-dark .toast-header {
  border-color: var(--bs-dark);
}
.toast-dark a,
.toast-dark a:hover,
.toast-dark .toast-header a,
.toast-dark .toast-header a:hover {
  color: var(--bs-dark-contrast);
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.225rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: var(--bs-black);
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: var(--bs-black);
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: var(--bs-black);
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: var(--bs-black);
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: var(--bs-white);
  text-align: center;
  background-color: var(--bs-black);
  border-radius: 0.6rem;
}

ngb-tooltip-window.show {
  opacity: 1 !important;
}
ngb-tooltip-window.tooltip-sm {
  max-width: 8rem;
}
ngb-tooltip-window .tooltip-inner {
  padding: 0.5rem 1rem;
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  border-radius: 0.4rem;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-black-rgb), 0.15);
}
ngb-tooltip-window.bs-tooltip-top .tooltip-arrow::before, ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: var(--bs-body-bg) !important;
}
ngb-tooltip-window.bs-tooltip-bottom .tooltip-arrow::before, ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: var(--bs-body-bg) !important;
}
ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: var(--bs-body-bg) !important;
}
ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: var(--bs-body-bg) !important;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.lead {
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.75rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.75rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .datepicker .ngb-dp-month-name, .h6, .datepicker .ngb-dp-weekday {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1.5;
}

h1, .h1 {
  font-size: calc(1.405rem + 1.86vw);
  font-weight: 600;
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.8rem;
  }
}

h2, .h2 {
  font-size: calc(1.349rem + 1.188vw);
  font-weight: 600;
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.24rem;
  }
}

h3, .h3 {
  font-size: calc(1.335rem + 1.02vw);
  font-weight: 600;
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2.1rem;
  }
}

h4, .h4 {
  font-size: calc(1.307rem + 0.684vw);
  font-weight: 500;
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.82rem;
  }
}

h5, .h5, .datepicker .ngb-dp-month-name {
  font-size: calc(1.286rem + 0.432vw);
  font-weight: 600;
}
@media (min-width: 1200px) {
  h5, .h5, .datepicker .ngb-dp-month-name {
    font-size: 1.61rem;
  }
}

h6, .h6, .datepicker .ngb-dp-weekday {
  font-size: calc(1.265rem + 0.18vw);
  font-weight: 600;
}
@media (min-width: 1200px) {
  h6, .h6, .datepicker .ngb-dp-weekday {
    font-size: 1.4rem;
  }
}

a {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  a {
    transition: none;
  }
}

.inline-bullet::after {
  content: "●";
  color: var(--bs-body-color-muted);
  opacity: 50%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.widget {
  background-color: var(--bs-body-bg);
  border: 0 solid rgba(0, 0, 0, 0);
  border-radius: 0;
  --scrollbar-bg: var(--bs-body-bg);
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}
.widget > .list-group:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.widget > .list-group:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.widget.widget-xs {
  height: 26rem;
}
.widget.widget-sm {
  height: 33rem;
}
.widget.widget-md {
  height: 55rem;
}
.widget.widget-4 {
  height: 65rem;
}
.widget.widget-5 {
  height: 80rem;
}
.widget.widget-unset {
  height: unset;
}

.widget-body, .accordion-widget .widget.widget-body .widget.widget-body,
.accordion-widget .widget.widget-body .widget-body,
.accordion-widget .widget-body .widget.widget-body,
.accordion-widget .widget-body .widget-body {
  padding: 1.5rem 2rem;
  color: var(--bs-body-color);
}

.widget-body .widget-body,
.accordion-widget .widget.widget-body .widget-body .widget-body,
.accordion-widget .widget-body .widget.widget-body .widget-body,
.accordion-widget .widget-body .widget-body .widget-body, .accordion-widget .widget.widget-body .widget.widget-body,
.accordion-widget .widget.widget-body .widget-body,
.accordion-widget .widget-body .widget.widget-body,
.accordion-widget .widget-body .widget-body .widget.widget-body,
.accordion-widget .widget-body .widget-body {
  padding: 0;
}

.widget-body .widget-retain-padding .widget-body,
.accordion-widget .widget.widget-body .widget-retain-padding .widget-body,
.accordion-widget .widget-body .widget-retain-padding .widget.widget-body,
.accordion-widget .widget-body .widget-retain-padding .widget-body {
  padding: 1.5rem 2rem;
}
.widget-body .widget-retain-padding .widget-body .widget-body,
.accordion-widget .widget.widget-body .widget-retain-padding .widget-body .widget-body,
.accordion-widget .widget-body .widget-retain-padding .widget.widget-body .widget-body {
  padding: 0;
}

.widget-padding {
  padding: 1.5rem 2rem;
}

.widget-title {
  margin-bottom: 0.75rem;
}

.widget-subtitle {
  margin-top: -0.375rem;
}

.widget-link + .widget-link {
  margin-left: 2rem;
}

.widget-header {
  display: flex;
  padding: 1.5rem 2rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-bottom: 1px solid var(--bs-border-color);
}
.widget-header:first-child {
  border-radius: 0 0 0 0;
}

.widget-footer {
  padding: 1.5rem 2rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-top: 1px solid var(--bs-border-color);
}
.widget-footer:last-child {
  border-radius: 0 0 0 0;
}

.wobble {
  animation-name: wobble;
  animation-iteration-count: infinite;
  transform-origin: 50% 10%;
  animation-duration: 0.25s;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.5rem !important;
}

.gap-2 {
  gap: 1rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.5rem !important;
}

.me-2 {
  margin-right: 1rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.5rem !important;
}

.ms-2 {
  margin-left: 1rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.5rem !important;
}

.m-n2 {
  margin: -1rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.m-n4 {
  margin: -2rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n2 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n3 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n4 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n2 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n4 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.5rem !important;
}

.mt-n2 {
  margin-top: -1rem !important;
}

.mt-n3 {
  margin-top: -1.5rem !important;
}

.mt-n4 {
  margin-top: -2rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.5rem !important;
}

.me-n2 {
  margin-right: -1rem !important;
}

.me-n3 {
  margin-right: -1.5rem !important;
}

.me-n4 {
  margin-right: -2rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.5rem !important;
}

.mb-n2 {
  margin-bottom: -1rem !important;
}

.mb-n3 {
  margin-bottom: -1.5rem !important;
}

.mb-n4 {
  margin-bottom: -2rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.5rem !important;
}

.ms-n2 {
  margin-left: -1rem !important;
}

.ms-n3 {
  margin-left: -1.5rem !important;
}

.ms-n4 {
  margin-left: -2rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.5rem !important;
}

.pe-2 {
  padding-right: 1rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.5rem !important;
}

.ps-2 {
  padding-left: 1rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.405rem + 1.86vw) !important;
}

.fs-2 {
  font-size: calc(1.349rem + 1.188vw) !important;
}

.fs-3 {
  font-size: calc(1.335rem + 1.02vw) !important;
}

.fs-4 {
  font-size: calc(1.307rem + 0.684vw) !important;
}

.fs-5 {
  font-size: calc(1.286rem + 0.432vw) !important;
}

.fs-6 {
  font-size: calc(1.265rem + 0.18vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.6rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.4rem !important;
}

.rounded-2 {
  border-radius: 0.6rem !important;
}

.rounded-3 {
  border-radius: 0.6rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.6rem !important;
  border-top-right-radius: 0.6rem !important;
}

.rounded-end {
  border-top-right-radius: 0.6rem !important;
  border-bottom-right-radius: 0.6rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.6rem !important;
  border-bottom-left-radius: 0.6rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.6rem !important;
  border-top-left-radius: 0.6rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 425px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.5rem !important;
  }
  .gap-sm-2 {
    gap: 1rem !important;
  }
  .gap-sm-3 {
    gap: 1.5rem !important;
  }
  .gap-sm-4 {
    gap: 2rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 2rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.5rem !important;
  }
  .me-sm-2 {
    margin-right: 1rem !important;
  }
  .me-sm-3 {
    margin-right: 1.5rem !important;
  }
  .me-sm-4 {
    margin-right: 2rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-2 {
    margin-left: 1rem !important;
  }
  .ms-sm-3 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-4 {
    margin-left: 2rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.5rem !important;
  }
  .m-sm-n2 {
    margin: -1rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n2 {
    margin-top: -1rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n4 {
    margin-top: -2rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n2 {
    margin-right: -1rem !important;
  }
  .me-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n4 {
    margin-right: -2rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n2 {
    margin-left: -1rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n4 {
    margin-left: -2rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 2rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-2 {
    padding-right: 1rem !important;
  }
  .pe-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-4 {
    padding-right: 2rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-2 {
    padding-left: 1rem !important;
  }
  .ps-sm-3 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-4 {
    padding-left: 2rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.5rem !important;
  }
  .gap-md-2 {
    gap: 1rem !important;
  }
  .gap-md-3 {
    gap: 1.5rem !important;
  }
  .gap-md-4 {
    gap: 2rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 2rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.5rem !important;
  }
  .me-md-2 {
    margin-right: 1rem !important;
  }
  .me-md-3 {
    margin-right: 1.5rem !important;
  }
  .me-md-4 {
    margin-right: 2rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 2rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.5rem !important;
  }
  .ms-md-2 {
    margin-left: 1rem !important;
  }
  .ms-md-3 {
    margin-left: 1.5rem !important;
  }
  .ms-md-4 {
    margin-left: 2rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.5rem !important;
  }
  .m-md-n2 {
    margin: -1rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n2 {
    margin-top: -1rem !important;
  }
  .mt-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n4 {
    margin-top: -2rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.5rem !important;
  }
  .me-md-n2 {
    margin-right: -1rem !important;
  }
  .me-md-n3 {
    margin-right: -1.5rem !important;
  }
  .me-md-n4 {
    margin-right: -2rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n2 {
    margin-left: -1rem !important;
  }
  .ms-md-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n4 {
    margin-left: -2rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 2rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.5rem !important;
  }
  .pe-md-2 {
    padding-right: 1rem !important;
  }
  .pe-md-3 {
    padding-right: 1.5rem !important;
  }
  .pe-md-4 {
    padding-right: 2rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 2rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.5rem !important;
  }
  .ps-md-2 {
    padding-left: 1rem !important;
  }
  .ps-md-3 {
    padding-left: 1.5rem !important;
  }
  .ps-md-4 {
    padding-left: 2rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.5rem !important;
  }
  .gap-lg-2 {
    gap: 1rem !important;
  }
  .gap-lg-3 {
    gap: 1.5rem !important;
  }
  .gap-lg-4 {
    gap: 2rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 2rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.5rem !important;
  }
  .me-lg-2 {
    margin-right: 1rem !important;
  }
  .me-lg-3 {
    margin-right: 1.5rem !important;
  }
  .me-lg-4 {
    margin-right: 2rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-2 {
    margin-left: 1rem !important;
  }
  .ms-lg-3 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-4 {
    margin-left: 2rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.5rem !important;
  }
  .m-lg-n2 {
    margin: -1rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n2 {
    margin-top: -1rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n4 {
    margin-top: -2rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n2 {
    margin-right: -1rem !important;
  }
  .me-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n4 {
    margin-right: -2rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n2 {
    margin-left: -1rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n4 {
    margin-left: -2rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 2rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-2 {
    padding-right: 1rem !important;
  }
  .pe-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-4 {
    padding-right: 2rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-2 {
    padding-left: 1rem !important;
  }
  .ps-lg-3 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-4 {
    padding-left: 2rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.5rem !important;
  }
  .gap-xl-2 {
    gap: 1rem !important;
  }
  .gap-xl-3 {
    gap: 1.5rem !important;
  }
  .gap-xl-4 {
    gap: 2rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-top: 1rem !important;
  }
  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 2rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.5rem !important;
  }
  .me-xl-2 {
    margin-right: 1rem !important;
  }
  .me-xl-3 {
    margin-right: 1.5rem !important;
  }
  .me-xl-4 {
    margin-right: 2rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-2 {
    margin-left: 1rem !important;
  }
  .ms-xl-3 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-4 {
    margin-left: 2rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.5rem !important;
  }
  .m-xl-n2 {
    margin: -1rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n2 {
    margin-top: -1rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n4 {
    margin-top: -2rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n2 {
    margin-right: -1rem !important;
  }
  .me-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n4 {
    margin-right: -2rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n2 {
    margin-left: -1rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n4 {
    margin-left: -2rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-top: 1rem !important;
  }
  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 2rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-2 {
    padding-right: 1rem !important;
  }
  .pe-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-4 {
    padding-right: 2rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-2 {
    padding-left: 1rem !important;
  }
  .ps-xl-3 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-4 {
    padding-left: 2rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 2560px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.5rem !important;
  }
  .gap-xxl-2 {
    gap: 1rem !important;
  }
  .gap-xxl-3 {
    gap: 1.5rem !important;
  }
  .gap-xxl-4 {
    gap: 2rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.5rem !important;
  }
  .m-xxl-2 {
    margin: 1rem !important;
  }
  .m-xxl-3 {
    margin: 1.5rem !important;
  }
  .m-xxl-4 {
    margin: 2rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-2 {
    margin-top: 1rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-4 {
    margin-top: 2rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-2 {
    margin-right: 1rem !important;
  }
  .me-xxl-3 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-4 {
    margin-right: 2rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-2 {
    margin-left: 1rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-4 {
    margin-left: 2rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.5rem !important;
  }
  .m-xxl-n2 {
    margin: -1rem !important;
  }
  .m-xxl-n3 {
    margin: -1.5rem !important;
  }
  .m-xxl-n4 {
    margin: -2rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n2 {
    margin-right: -1rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n4 {
    margin-right: -2rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.5rem !important;
  }
  .p-xxl-2 {
    padding: 1rem !important;
  }
  .p-xxl-3 {
    padding: 1.5rem !important;
  }
  .p-xxl-4 {
    padding: 2rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-2 {
    padding-top: 1rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-4 {
    padding-top: 2rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-2 {
    padding-right: 1rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-4 {
    padding-right: 2rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-2 {
    padding-left: 1rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-4 {
    padding-left: 2rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.8rem !important;
  }
  .fs-2 {
    font-size: 2.24rem !important;
  }
  .fs-3 {
    font-size: 2.1rem !important;
  }
  .fs-4 {
    font-size: 1.82rem !important;
  }
  .fs-5 {
    font-size: 1.61rem !important;
  }
  .fs-6 {
    font-size: 1.4rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.bg-primary-light {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-primary-light-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-primary-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-primary-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-primary-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-light-rgb), var(--bs-text-opacity)) !important;
}

.bg-secondary-light {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-secondary-light-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-secondary-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-secondary-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-secondary-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-light-rgb), var(--bs-text-opacity)) !important;
}

.bg-success-light {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-success-light-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-success-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-success-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-success-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-light-rgb), var(--bs-text-opacity)) !important;
}

.bg-info-light {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-info-light-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-info-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-info-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-info-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-light-rgb), var(--bs-text-opacity)) !important;
}

.bg-warning-light {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-warning-light-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-warning-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-warning-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-warning-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-light-rgb), var(--bs-text-opacity)) !important;
}

.bg-danger-light {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-danger-light-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-danger-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-danger-light-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-danger-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-light-rgb), var(--bs-text-opacity)) !important;
}

.bg-primary-dark {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-primary-dark-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-primary-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-primary-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-primary-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-dark-rgb), var(--bs-text-opacity)) !important;
}

.bg-secondary-dark {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-secondary-dark-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-secondary-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-secondary-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-secondary-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-dark-rgb), var(--bs-text-opacity)) !important;
}

.bg-success-dark {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-success-dark-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-success-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-success-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-success-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-dark-rgb), var(--bs-text-opacity)) !important;
}

.bg-info-dark {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-info-dark-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-info-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-info-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-info-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-dark-rgb), var(--bs-text-opacity)) !important;
}

.bg-warning-dark {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-warning-dark-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-warning-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-warning-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-warning-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-dark-rgb), var(--bs-text-opacity)) !important;
}

.bg-danger-dark {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-danger-dark-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-danger-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-danger-dark-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-danger-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-success-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-info-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-light-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-light-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-light-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-light-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-light-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-success-light-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-light-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-info-light-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-light-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-light-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-light-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-light-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-light-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-dark-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-dark-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-dark-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-dark-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-success-dark-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-dark-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-info-dark-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-dark-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-dark-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-dark-contrast-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-dark-contrast {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-dark-contrast-rgb), var(--bs-text-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.bg-muted {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-body-bg-muted-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-body-bg-muted-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-body-bg-muted-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-muted {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-muted-rgb), var(--bs-text-opacity)) !important;
}

.bg-strong {
  --bs-bg-opacity: 1;
  --bs-avatar-group-border-color: rgba(var(--bs-body-bg-strong-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-body-bg-strong-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-bg: rgba(var(--bs-body-bg-strong-rgb), var(--bs-bg-opacity)) !important;
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.text-strong {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-strong-rgb), var(--bs-text-opacity)) !important;
}

.bg-active-component {
  background-color: var(--bs-component-active-bg);
}

.text-active-component {
  color: var(--bs-component-active-color);
}

.active-component {
  background-color: var(--bs-component-active-bg);
  color: var(--bs-component-active-color);
}

.border-primary {
  border-color: var(--bs-primary) !important;
}

.border-secondary {
  border-color: var(--bs-secondary) !important;
}

.border-success {
  border-color: var(--bs-success) !important;
}

.border-info {
  border-color: var(--bs-info) !important;
}

.border-warning {
  border-color: var(--bs-warning) !important;
}

.border-danger {
  border-color: var(--bs-danger) !important;
}

.border-light {
  border-color: var(--bs-light) !important;
}

.border-dark {
  border-color: var(--bs-dark) !important;
}

.bg-blue {
  background-color: var(--bs-blue);
}

.text-blue {
  color: var(--bs-blue);
}

.text-blue-contrast {
  color: var(--bs-blue-contrast);
}

.bg-indigo {
  background-color: var(--bs-indigo);
}

.text-indigo {
  color: var(--bs-indigo);
}

.text-indigo-contrast {
  color: var(--bs-indigo-contrast);
}

.bg-purple {
  background-color: var(--bs-purple);
}

.text-purple {
  color: var(--bs-purple);
}

.text-purple-contrast {
  color: var(--bs-purple-contrast);
}

.bg-pink {
  background-color: var(--bs-pink);
}

.text-pink {
  color: var(--bs-pink);
}

.text-pink-contrast {
  color: var(--bs-pink-contrast);
}

.bg-red {
  background-color: var(--bs-red);
}

.text-red {
  color: var(--bs-red);
}

.text-red-contrast {
  color: var(--bs-red-contrast);
}

.bg-orange {
  background-color: var(--bs-orange);
}

.text-orange {
  color: var(--bs-orange);
}

.text-orange-contrast {
  color: var(--bs-orange-contrast);
}

.bg-yellow {
  background-color: var(--bs-yellow);
}

.text-yellow {
  color: var(--bs-yellow);
}

.text-yellow-contrast {
  color: var(--bs-yellow-contrast);
}

.bg-green {
  background-color: var(--bs-green);
}

.text-green {
  color: var(--bs-green);
}

.text-green-contrast {
  color: var(--bs-green-contrast);
}

.bg-teal {
  background-color: var(--bs-teal);
}

.text-teal {
  color: var(--bs-teal);
}

.text-teal-contrast {
  color: var(--bs-teal-contrast);
}

.bg-cyan {
  background-color: var(--bs-cyan);
}

.text-cyan {
  color: var(--bs-cyan);
}

.text-cyan-contrast {
  color: var(--bs-cyan-contrast);
}

.bg-gray {
  background-color: var(--bs-gray);
}

.text-gray {
  color: var(--bs-gray);
}

.text-gray-contrast {
  color: var(--bs-gray-contrast);
}

.text-muted {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-muted-rgb), var(--bs-text-opacity)) !important;
}

.flex-0 {
  flex: 0 !important;
}

.flex-1 {
  flex: 1 !important;
}

.h-0 {
  height: 0% !important;
}

.min-h-0 {
  min-height: 0% !important;
}

.max-h-0 {
  max-height: 0% !important;
}

.h-10 {
  height: 10% !important;
}

.min-h-10 {
  min-height: 10% !important;
}

.max-h-10 {
  max-height: 10% !important;
}

.h-25 {
  height: 25% !important;
}

.min-h-25 {
  min-height: 25% !important;
}

.max-h-25 {
  max-height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.min-h-50 {
  min-height: 50% !important;
}

.max-h-50 {
  max-height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.min-h-75 {
  min-height: 75% !important;
}

.max-h-75 {
  max-height: 75% !important;
}

.h-90 {
  height: 90% !important;
}

.min-h-90 {
  min-height: 90% !important;
}

.max-h-90 {
  max-height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.max-h-100 {
  max-height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.min-h-auto {
  min-height: auto !important;
}

.max-h-auto {
  max-height: auto !important;
}

.absolute-center, .center-modal {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pointer {
  cursor: pointer;
}

.scroll-smooth {
  position: relative;
  scroll-behavior: smooth;
}

.hide-when-empty:empty {
  display: none;
}

* > p:last-of-type {
  margin-bottom: 0;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

:root {
  --bs-primary-red: 51;
  --bs-primary-green: 86;
  --bs-primary-blue: 105;
  --bs-secondary-red: 158;
  --bs-secondary-green: 216;
  --bs-secondary-blue: 208;
  --bs-success-red: 75;
  --bs-success-green: 141;
  --bs-success-blue: 139;
  --bs-danger-red: 235;
  --bs-danger-green: 83;
  --bs-danger-blue: 69;
  --bs-warning-red: 253;
  --bs-warning-green: 126;
  --bs-warning-blue: 20;
  --bs-info-red: 108;
  --bs-info-green: 136;
  --bs-info-blue: 131;
  --bs-primary-light-red: 125;
  --bs-primary-light-green: 153;
  --bs-primary-light-blue: 172;
  --bs-secondary-light-red: 234;
  --bs-secondary-light-green: 246;
  --bs-secondary-light-blue: 245;
  --bs-success-light-red: 215;
  --bs-success-light-green: 230;
  --bs-success-light-blue: 229;
  --bs-danger-light-red: 252;
  --bs-danger-light-green: 220;
  --bs-danger-light-blue: 220;
  --bs-warning-light-red: 255;
  --bs-warning-light-green: 231;
  --bs-warning-light-blue: 199;
  --bs-info-light-red: 230;
  --bs-info-light-green: 233;
  --bs-info-light-blue: 234;
  --bs-primary-dark-red: 31;
  --bs-primary-dark-green: 52;
  --bs-primary-dark-blue: 64;
  --bs-secondary-dark-red: 34;
  --bs-secondary-dark-green: 85;
  --bs-secondary-dark-blue: 78;
  --bs-success-dark-red: 46;
  --bs-success-dark-green: 86;
  --bs-success-dark-blue: 85;
  --bs-danger-dark-red: 147;
  --bs-danger-dark-green: 57;
  --bs-danger-dark-blue: 58;
  --bs-warning-dark-red: 143;
  --bs-warning-dark-green: 124;
  --bs-warning-dark-blue: 94;
  --bs-info-dark-red: 87;
  --bs-info-dark-green: 93;
  --bs-info-dark-blue: 98;
}

:root {
  --clmi-lifecycle-stage-advocate: #1abc9c;
  --clmi-lifecycle-stage-advocate-light: #90f0dd;
  --clmi-lifecycle-stage-client: #2ecc71;
  --clmi-lifecycle-stage-client-light: #b5eece;
  --clmi-lifecycle-stage-disqualified: #253038;
  --clmi-lifecycle-stage-disqualified-light: #88a0b2;
  --clmi-lifecycle-stage-engaged: #3498db;
  --clmi-lifecycle-stage-engaged-light: #c4e1f5;
  --clmi-lifecycle-stage-flight-risk: #9b59b6;
  --clmi-lifecycle-stage-flight-risk-light: #e2cfea;
  --clmi-lifecycle-stage-left: #34495e;
  --clmi-lifecycle-stage-left-light: #829eba;
  --clmi-lifecycle-stage-onboarding: #f1c40f;
  --clmi-lifecycle-stage-onboarding-light: #faebaf;
  --clmi-lifecycle-stage-pitching: #e67e22;
  --clmi-lifecycle-stage-pitching-light: #f7d7bb;
  --clmi-lifecycle-stage-switched: #7f8c8d;
  --clmi-lifecycle-stage-switched-light: #ecf0f1;
  --clmi-lifecycle-stage-target: #e74c3c;
  --clmi-lifecycle-stage-target-light: #fad7d3;
  --clmi-lifecycle-stage-unwinding: #fd79a8;
  --clmi-lifecycle-stage-unwinding-light: #fecdde;
  --clmi-lifecycle-stage-potentiallywithoutcontact: #ffa600;
  --clmi-lifecycle-stage-potentiallywithoutcontact-light: #f9dda8;
  --clmi-lifecycle-stage-withoutcontact: #ffa600;
  --clmi-lifecycle-stage-withoutcontact-light: #f9dda8;
  --clmi-lifecycle-stage-dormant: #f14a4b;
  --clmi-lifecycle-stage-dormant-light: #f39d9d;
  --clmi-lifecycle-stage-pendingdeletion: #ff4500;
  --clmi-lifecycle-stage-pendingdeletion-light: #ff5349;
}

:root {
  --clmi-svg-primary: var(--bs-primary);
  --clmi-svg-primary-fill: var(--bs-primary-light);
  --clmi-svg-secondary: var(--bs-secondary);
  --clmi-svg-secondary-fill: var(--bs-secondary-light);
  --clmi-svg-bg: var(--bs-body-bg-muted);
}

.gap-0 {
  gap: 0;
}

.row-gap-0 {
  row-gap: 0;
}

.position-initial {
  position: initial;
}

.block-truncate {
  height: 1.4rem;
  line-height: 1;
}
.block-truncate p:first-child {
  display: block;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.block-truncate p {
  display: none;
}

.cursor-help {
  cursor: help;
}

.info-card-container {
  --gap: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: calc(var(--gap) * -1);
}
.info-card-container > * {
  margin: var(--gap);
}

.data-list-content {
  display: grid;
  grid-template-columns: minmax(100%, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
}

.text-pre-line {
  white-space: pre-line;
}

.stack-icon {
  margin: -0.2em -0.65em;
}

.min-w-0 {
  min-width: 0;
}

.file-type-pdf {
  color: #ff0000;
}

.file-type-excel {
  color: #1f7246;
}

.file-type-image {
  color: #1a4a8b;
}

.file-type-powerpoint {
  color: #d04626;
}

.file-type-word {
  color: #2b5797;
}

.lifecycle-stage-advocate {
  background-color: #1abc9c !important;
  border-color: #1abc9c !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-client {
  background-color: #2ecc71 !important;
  border-color: #2ecc71 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-disqualified {
  background-color: #253038 !important;
  border-color: #253038 !important;
  color: var(--bs-white) !important;
}

.lifecycle-stage-engaged {
  background-color: #3498db !important;
  border-color: #3498db !important;
  color: var(--bs-white) !important;
}

.lifecycle-stage-flight-risk {
  background-color: #9b59b6 !important;
  border-color: #9b59b6 !important;
  color: var(--bs-white) !important;
}

.lifecycle-stage-left {
  background-color: #34495e !important;
  border-color: #34495e !important;
  color: var(--bs-white) !important;
}

.lifecycle-stage-onboarding {
  background-color: #f1c40f !important;
  border-color: #f1c40f !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-pitching {
  background-color: #e67e22 !important;
  border-color: #e67e22 !important;
  color: var(--bs-white) !important;
}

.lifecycle-stage-switched {
  background-color: #7f8c8d !important;
  border-color: #7f8c8d !important;
  color: var(--bs-white) !important;
}

.lifecycle-stage-target {
  background-color: #e74c3c !important;
  border-color: #e74c3c !important;
  color: var(--bs-white) !important;
}

.lifecycle-stage-unwinding {
  background-color: #fd79a8 !important;
  border-color: #fd79a8 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-potentiallywithoutcontact {
  background-color: #ffa600 !important;
  border-color: #ffa600 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-withoutcontact {
  background-color: #ffa600 !important;
  border-color: #ffa600 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-dormant {
  background-color: #f14a4b !important;
  border-color: #f14a4b !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-pendingdeletion {
  background-color: #ff4500 !important;
  border-color: #ff4500 !important;
  color: var(--bs-white) !important;
}

.lifecycle-stage-advocate-light {
  background-color: #90f0dd !important;
  border-color: #90f0dd !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-client-light {
  background-color: #b5eece !important;
  border-color: #b5eece !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-disqualified-light {
  background-color: #88a0b2 !important;
  border-color: #88a0b2 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-engaged-light {
  background-color: #c4e1f5 !important;
  border-color: #c4e1f5 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-flight-risk-light {
  background-color: #e2cfea !important;
  border-color: #e2cfea !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-left-light {
  background-color: #829eba !important;
  border-color: #829eba !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-onboarding-light {
  background-color: #faebaf !important;
  border-color: #faebaf !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-pitching-light {
  background-color: #f7d7bb !important;
  border-color: #f7d7bb !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-switched-light {
  background-color: #ecf0f1 !important;
  border-color: #ecf0f1 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-target-light {
  background-color: #fad7d3 !important;
  border-color: #fad7d3 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-unwinding-light {
  background-color: #fecdde !important;
  border-color: #fecdde !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-potentiallywithoutcontact-light {
  background-color: #f9dda8 !important;
  border-color: #f9dda8 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-withoutcontact-light {
  background-color: #f9dda8 !important;
  border-color: #f9dda8 !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-dormant-light {
  background-color: #f39d9d !important;
  border-color: #f39d9d !important;
  color: var(--bs-black) !important;
}

.lifecycle-stage-pendingdeletion-light {
  background-color: #ff5349 !important;
  border-color: #ff5349 !important;
  color: var(--bs-black) !important;
}

.custom-fade {
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-fade {
    transition: none;
  }
}
.custom-fade .modal-dialog {
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-fade .modal-dialog {
    transition: none;
  }
}

@keyframes modalIn {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.modal-dialog-transition {
  animation-name: modalIn;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

ngb-modal-backdrop:not(.modal-backdrop--ngb), ngb-modal-backdrop:not(.modal-backdrop--ngb).show, ngb-modal-backdrop:not(.modal-backdrop--ngb).custom-fade, ngb-modal-backdrop:not(.modal-backdrop--ngb):not(.fade-in),
ngb-modal-backdrop:not(.modal-backdrop--ngb).modal-backdrop,
ngb-modal-backdrop:not(.modal-backdrop--ngb).modal-backdrop.show,
ngb-modal-backdrop:not(.modal-backdrop--ngb).modal-backdrop.custom-fade,
ngb-modal-backdrop:not(.modal-backdrop--ngb).modal-backdrop:not(.fade-in),
.modal-backdrop:not(.modal-backdrop--ngb),
.modal-backdrop:not(.modal-backdrop--ngb).show,
.modal-backdrop:not(.modal-backdrop--ngb).custom-fade,
.modal-backdrop:not(.modal-backdrop--ngb):not(.fade-in) {
  opacity: 0;
}
ngb-modal-backdrop:not(.modal-backdrop--ngb).fade-in,
ngb-modal-backdrop:not(.modal-backdrop--ngb).modal-backdrop.fade-in,
.modal-backdrop:not(.modal-backdrop--ngb).fade-in {
  opacity: 0.5;
}

.modal:not(.modal-window--ngb) .modal-dialog,
.modal:not(.modal-window--ngb).show .modal-dialog,
.modal.custom-fade.show .modal-dialog {
  transform: translateX(0%);
}

.modal:not(.modal-window--ngb).show.fade-in .modal-dialog, .modal:not(.modal-window--ngb).show.fade-in.right .modal-dialog,
.modal.custom-fade.fade-in.show .modal-dialog,
.modal.custom-fade.fade-in.show.right .modal-dialog {
  transform: translateX(-100%);
}
.modal:not(.modal-window--ngb).show.fade-in.left .modal-dialog,
.modal.custom-fade.fade-in.show.left .modal-dialog {
  transform: translateX(100%);
}

.modal:not(.modal-window--ngb).show .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal:not(.modal-window--ngb).show .modal-dialog, .modal:not(.modal-window--ngb).show.right .modal-dialog {
  height: 100vh;
  max-width: 100vw;
  min-width: unset;
  margin: 0;
  position: fixed;
  top: 0;
  right: unset;
  left: 100%;
}
@media (max-width: 424.98px) {
  .modal:not(.modal-window--ngb).show .modal-dialog:not(.modal-xs), .modal:not(.modal-window--ngb).show.right .modal-dialog:not(.modal-xs) {
    min-width: 100vw;
  }
}
.modal:not(.modal-window--ngb).show.left .modal-dialog {
  left: unset;
  right: 100%;
}
.modal:not(.modal-window--ngb).show .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal:not(.modal-window--ngb).show .modal-padding {
  padding: 1rem;
}
.modal:not(.modal-window--ngb).show .modal-padding--banner {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.modal:not(.modal-window--ngb).show .modal-padding--body {
  padding-top: 1rem;
}

.modal-xs,
.modal-xs.modal-dialog {
  width: 300px;
}

@media (min-width: 425px) {
  .modal-dialog {
    width: 500px;
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    width: 800px;
  }
}
@media (min-width: 1440px) {
  .modal-xl {
    width: 1140px;
  }
}
.modal-full {
  width: calc(100vw - 3.5rem);
}

.modal-header {
  background-color: var(--bs-primary);
  color: var(--bs-primary-contrast);
  display: flex;
  align-items: center;
  height: 6.4rem;
  border: none;
  border-radius: 0;
}
.modal-header .close {
  border-radius: 100%;
}

.modal-banner {
  padding: 1rem;
  background-color: var(--bs-primary-light);
  color: var(--bs-primary-light-contrast);
}

.modal-body {
  height: calc(100vh - 6.4rem);
  overflow: hidden;
  padding: 0;
  --scrollbar-bg: var(--bs-body-bg);
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}
.modal-body .preformatted {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.center-modal__content {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  padding: 1.5rem 2rem;
  transform: translateY(2rem);
  opacity: 0;
  max-width: 500px;
  min-width: 300px;
  transition: all 0.2s ease-in-out;
  border-radius: 0.6rem;
}
@media (prefers-reduced-motion: reduce) {
  .center-modal__content {
    transition: none;
  }
}

.fade-in .center-modal__content {
  opacity: 1;
  transform: translateY(0rem);
}

.modal-dialog-fullscreen {
  margin: auto;
  width: 100%;
  max-width: 2440px;
  padding: 1rem;
  z-index: 9999999;
}
.modal-dialog-fullscreen--no-pd {
  padding: 0;
}

.modal-dialog-xl-fullscreen {
  margin: auto;
  width: 100%;
}

.modal-dialog-centered:not(.modal-dialog-centred-xs) {
  height: 100%;
  max-width: 1200px;
  max-height: 600px;
  padding: 1rem;
}
.modal-dialog-centered:not(.modal-dialog-centred-xs) .modal-content {
  border: none;
  height: 100%;
}

@media (min-width: 768px) {
  .modal-dialog-centered:not(.modal-dialog-centred-xs) {
    width: 75%;
  }
  .modal-dialog-centered:not(.modal-dialog-centred-xs) .modal-content {
    height: 75%;
  }
}
@media (min-width: 1024px) {
  .modal-dialog-centered:not(.modal-dialog-centred-xs) {
    width: 50%;
  }
}
@media (min-width: 1440px) {
  .modal-dialog-fullscreen {
    padding: 3rem;
  }
  .modal-dialog-fullscreen--no-pd {
    padding: 0;
  }
}
mention-list ul.dropdown-menu.mention-menu {
  min-width: 22.5rem;
  padding: 0 0;
  margin: 0;
  font-size: 1.4rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.6rem;
}
mention-list ul.dropdown-menu.mention-menu a.mention-item {
  padding: 1rem 1rem;
  line-height: 1.5;
  font-weight: 400;
  color: var(--bs-link-color);
  cursor: pointer;
}
mention-list ul.dropdown-menu.mention-menu a.mention-item:hover {
  color: var(--bs-link-color-hover);
  background-color: var(--bs-body-bg-muted);
}
mention-list ul.dropdown-menu.mention-menu li.mention-active > a.mention-item {
  color: var(--bs-component-active-color);
  background-color: var(--bs-component-active-bg);
}

.ag-theme-quartz,
.ag-theme-quartz-dark {
  --ag-background-color: var(--bs-body-bg);
  --ag-row-hover-color: var(--bs-body-bg-muted);
  --ag-font-family: wdx.$font-family-base;
  --ag-font-size: 0.875em;
  --ag-header-background-color: var(--bs-body-bg);
  --ag-cell-horizontal-padding: 0.75em;
}
.ag-theme-quartz.wdx-ag-grid-shade-groups .ag-row.ag-row-group,
.ag-theme-quartz-dark.wdx-ag-grid-shade-groups .ag-row.ag-row-group {
  background-color: var(--bs-body-bg-muted);
}
.ag-theme-quartz .ag-filter-filter .ag-input-field-input.ag-text-field-input,
.ag-theme-quartz .ag-ltr .ag-row-group-indent-1,
.ag-theme-quartz-dark .ag-filter-filter .ag-input-field-input.ag-text-field-input,
.ag-theme-quartz-dark .ag-ltr .ag-row-group-indent-1 {
  padding-left: 1rem !important;
}

span.flag {
  width: 1.5em;
  height: 1em;
  display: inline-block;
}

img.flag {
  width: 30px;
}

.flag {
  background: url(../../../../../apps/wdx/src/assets/vendors/flag-sprites/flags_responsive.png) no-repeat;
  background-size: 100%;
}

.flag-ad {
  background-position: 0 0.413223%;
}

.flag-ae {
  background-position: 0 0.826446%;
}

.flag-af {
  background-position: 0 1.239669%;
}

.flag-ag {
  background-position: 0 1.652893%;
}

.flag-ai {
  background-position: 0 2.066116%;
}

.flag-al {
  background-position: 0 2.479339%;
}

.flag-am {
  background-position: 0 2.892562%;
}

.flag-an {
  background-position: 0 3.305785%;
}

.flag-ao {
  background-position: 0 3.719008%;
}

.flag-aq {
  background-position: 0 4.132231%;
}

.flag-ar {
  background-position: 0 4.545455%;
}

.flag-as {
  background-position: 0 4.958678%;
}

.flag-at {
  background-position: 0 5.371901%;
}

.flag-au {
  background-position: 0 5.785124%;
}

.flag-aw {
  background-position: 0 6.198347%;
}

.flag-az {
  background-position: 0 6.61157%;
}

.flag-ba {
  background-position: 0 7.024793%;
}

.flag-bb {
  background-position: 0 7.438017%;
}

.flag-bd {
  background-position: 0 7.85124%;
}

.flag-be {
  background-position: 0 8.264463%;
}

.flag-bf {
  background-position: 0 8.677686%;
}

.flag-bg {
  background-position: 0 9.090909%;
}

.flag-bh {
  background-position: 0 9.504132%;
}

.flag-bi {
  background-position: 0 9.917355%;
}

.flag-bj {
  background-position: 0 10.330579%;
}

.flag-bm {
  background-position: 0 10.743802%;
}

.flag-bn {
  background-position: 0 11.157025%;
}

.flag-bo {
  background-position: 0 11.570248%;
}

.flag-br {
  background-position: 0 11.983471%;
}

.flag-bs {
  background-position: 0 12.396694%;
}

.flag-bt {
  background-position: 0 12.809917%;
}

.flag-bv {
  background-position: 0 13.22314%;
}

.flag-bw {
  background-position: 0 13.636364%;
}

.flag-by {
  background-position: 0 14.049587%;
}

.flag-bz {
  background-position: 0 14.46281%;
}

.flag-ca {
  background-position: 0 14.876033%;
}

.flag-cc {
  background-position: 0 15.289256%;
}

.flag-cd {
  background-position: 0 15.702479%;
}

.flag-cf {
  background-position: 0 16.115702%;
}

.flag-cg {
  background-position: 0 16.528926%;
}

.flag-ch {
  background-position: 0 16.942149%;
}

.flag-ci {
  background-position: 0 17.355372%;
}

.flag-ck {
  background-position: 0 17.768595%;
}

.flag-cl {
  background-position: 0 18.181818%;
}

.flag-cm {
  background-position: 0 18.595041%;
}

.flag-cn {
  background-position: 0 19.008264%;
}

.flag-co {
  background-position: 0 19.421488%;
}

.flag-cr {
  background-position: 0 19.834711%;
}

.flag-cu {
  background-position: 0 20.247934%;
}

.flag-cv {
  background-position: 0 20.661157%;
}

.flag-cx {
  background-position: 0 21.07438%;
}

.flag-cy {
  background-position: 0 21.487603%;
}

.flag-cz {
  background-position: 0 21.900826%;
}

.flag-de {
  background-position: 0 22.31405%;
}

.flag-dj {
  background-position: 0 22.727273%;
}

.flag-dk {
  background-position: 0 23.140496%;
}

.flag-dm {
  background-position: 0 23.553719%;
}

.flag-do {
  background-position: 0 23.966942%;
}

.flag-dz {
  background-position: 0 24.380165%;
}

.flag-ec {
  background-position: 0 24.793388%;
}

.flag-ee {
  background-position: 0 25.206612%;
}

.flag-eg {
  background-position: 0 25.619835%;
}

.flag-eh {
  background-position: 0 26.033058%;
}

.flag-er {
  background-position: 0 26.446281%;
}

.flag-es {
  background-position: 0 26.859504%;
}

.flag-et {
  background-position: 0 27.272727%;
}

.flag-fi {
  background-position: 0 27.68595%;
}

.flag-fj {
  background-position: 0 28.099174%;
}

.flag-fk {
  background-position: 0 28.512397%;
}

.flag-fm {
  background-position: 0 28.92562%;
}

.flag-fo {
  background-position: 0 29.338843%;
}

.flag-fr {
  background-position: 0 29.752066%;
}

.flag-ga {
  background-position: 0 30.165289%;
}

.flag-gd {
  background-position: 0 30.578512%;
}

.flag-ge {
  background-position: 0 30.991736%;
}

.flag-gf {
  background-position: 0 31.404959%;
}

.flag-gh {
  background-position: 0 31.818182%;
}

.flag-gi {
  background-position: 0 32.231405%;
}

.flag-gl {
  background-position: 0 32.644628%;
}

.flag-gm {
  background-position: 0 33.057851%;
}

.flag-gn {
  background-position: 0 33.471074%;
}

.flag-gp {
  background-position: 0 33.884298%;
}

.flag-gq {
  background-position: 0 34.297521%;
}

.flag-gr {
  background-position: 0 34.710744%;
}

.flag-gs {
  background-position: 0 35.123967%;
}

.flag-gt {
  background-position: 0 35.53719%;
}

.flag-gu {
  background-position: 0 35.950413%;
}

.flag-gw {
  background-position: 0 36.363636%;
}

.flag-gy {
  background-position: 0 36.77686%;
}

.flag-hk {
  background-position: 0 37.190083%;
}

.flag-hm {
  background-position: 0 37.603306%;
}

.flag-hn {
  background-position: 0 38.016529%;
}

.flag-hr {
  background-position: 0 38.429752%;
}

.flag-ht {
  background-position: 0 38.842975%;
}

.flag-hu {
  background-position: 0 39.256198%;
}

.flag-id {
  background-position: 0 39.669421%;
}

.flag-ie {
  background-position: 0 40.082645%;
}

.flag-il {
  background-position: 0 40.495868%;
}

.flag-in {
  background-position: 0 40.909091%;
}

.flag-io {
  background-position: 0 41.322314%;
}

.flag-iq {
  background-position: 0 41.735537%;
}

.flag-ir {
  background-position: 0 42.14876%;
}

.flag-is {
  background-position: 0 42.561983%;
}

.flag-it {
  background-position: 0 42.975207%;
}

.flag-jm {
  background-position: 0 43.38843%;
}

.flag-jo {
  background-position: 0 43.801653%;
}

.flag-jp {
  background-position: 0 44.214876%;
}

.flag-ke {
  background-position: 0 44.628099%;
}

.flag-kg {
  background-position: 0 45.041322%;
}

.flag-kh {
  background-position: 0 45.454545%;
}

.flag-ki {
  background-position: 0 45.867769%;
}

.flag-km {
  background-position: 0 46.280992%;
}

.flag-kn {
  background-position: 0 46.694215%;
}

.flag-kp {
  background-position: 0 47.107438%;
}

.flag-kr {
  background-position: 0 47.520661%;
}

.flag-kw {
  background-position: 0 47.933884%;
}

.flag-ky {
  background-position: 0 48.347107%;
}

.flag-kz {
  background-position: 0 48.760331%;
}

.flag-la {
  background-position: 0 49.173554%;
}

.flag-lb {
  background-position: 0 49.586777%;
}

.flag-lc {
  background-position: 0 50%;
}

.flag-li {
  background-position: 0 50.413223%;
}

.flag-lk {
  background-position: 0 50.826446%;
}

.flag-lr {
  background-position: 0 51.239669%;
}

.flag-ls {
  background-position: 0 51.652893%;
}

.flag-lt {
  background-position: 0 52.066116%;
}

.flag-lu {
  background-position: 0 52.479339%;
}

.flag-lv {
  background-position: 0 52.892562%;
}

.flag-ly {
  background-position: 0 53.305785%;
}

.flag-ma {
  background-position: 0 53.719008%;
}

.flag-mc {
  background-position: 0 54.132231%;
}

.flag-md {
  background-position: 0 54.545455%;
}

.flag-me {
  background-position: 0 54.958678%;
}

.flag-mg {
  background-position: 0 55.371901%;
}

.flag-mh {
  background-position: 0 55.785124%;
}

.flag-mk {
  background-position: 0 56.198347%;
}

.flag-ml {
  background-position: 0 56.61157%;
}

.flag-mm {
  background-position: 0 57.024793%;
}

.flag-mn {
  background-position: 0 57.438017%;
}

.flag-mo {
  background-position: 0 57.85124%;
}

.flag-mp {
  background-position: 0 58.264463%;
}

.flag-mq {
  background-position: 0 58.677686%;
}

.flag-mr {
  background-position: 0 59.090909%;
}

.flag-ms {
  background-position: 0 59.504132%;
}

.flag-mt {
  background-position: 0 59.917355%;
}

.flag-mu {
  background-position: 0 60.330579%;
}

.flag-mv {
  background-position: 0 60.743802%;
}

.flag-mw {
  background-position: 0 61.157025%;
}

.flag-mx {
  background-position: 0 61.570248%;
}

.flag-my {
  background-position: 0 61.983471%;
}

.flag-mz {
  background-position: 0 62.396694%;
}

.flag-na {
  background-position: 0 62.809917%;
}

.flag-nc {
  background-position: 0 63.22314%;
}

.flag-ne {
  background-position: 0 63.636364%;
}

.flag-nf {
  background-position: 0 64.049587%;
}

.flag-ng {
  background-position: 0 64.46281%;
}

.flag-ni {
  background-position: 0 64.876033%;
}

.flag-nl {
  background-position: 0 65.289256%;
}

.flag-no {
  background-position: 0 65.702479%;
}

.flag-np {
  background-position: 0 66.115702%;
}

.flag-nr {
  background-position: 0 66.528926%;
}

.flag-nu {
  background-position: 0 66.942149%;
}

.flag-nz {
  background-position: 0 67.355372%;
}

.flag-om {
  background-position: 0 67.768595%;
}

.flag-pa {
  background-position: 0 68.181818%;
}

.flag-pe {
  background-position: 0 68.595041%;
}

.flag-pf {
  background-position: 0 69.008264%;
}

.flag-pg {
  background-position: 0 69.421488%;
}

.flag-ph {
  background-position: 0 69.834711%;
}

.flag-pk {
  background-position: 0 70.247934%;
}

.flag-pl {
  background-position: 0 70.661157%;
}

.flag-pm {
  background-position: 0 71.07438%;
}

.flag-pn {
  background-position: 0 71.487603%;
}

.flag-pr {
  background-position: 0 71.900826%;
}

.flag-pt {
  background-position: 0 72.31405%;
}

.flag-pw {
  background-position: 0 72.727273%;
}

.flag-py {
  background-position: 0 73.140496%;
}

.flag-qa {
  background-position: 0 73.553719%;
}

.flag-re {
  background-position: 0 73.966942%;
}

.flag-ro {
  background-position: 0 74.380165%;
}

.flag-rs {
  background-position: 0 74.793388%;
}

.flag-ru {
  background-position: 0 75.206612%;
}

.flag-rw {
  background-position: 0 75.619835%;
}

.flag-sa {
  background-position: 0 76.033058%;
}

.flag-sb {
  background-position: 0 76.446281%;
}

.flag-sc {
  background-position: 0 76.859504%;
}

.flag-sd {
  background-position: 0 77.272727%;
}

.flag-se {
  background-position: 0 77.68595%;
}

.flag-sg {
  background-position: 0 78.099174%;
}

.flag-sh {
  background-position: 0 78.512397%;
}

.flag-si {
  background-position: 0 78.92562%;
}

.flag-sj {
  background-position: 0 79.338843%;
}

.flag-sk {
  background-position: 0 79.752066%;
}

.flag-sl {
  background-position: 0 80.165289%;
}

.flag-sm {
  background-position: 0 80.578512%;
}

.flag-sn {
  background-position: 0 80.991736%;
}

.flag-so {
  background-position: 0 81.404959%;
}

.flag-sr {
  background-position: 0 81.818182%;
}

.flag-ss {
  background-position: 0 82.231405%;
}

.flag-st {
  background-position: 0 82.644628%;
}

.flag-sv {
  background-position: 0 83.057851%;
}

.flag-sy {
  background-position: 0 83.471074%;
}

.flag-sz {
  background-position: 0 83.884298%;
}

.flag-tc {
  background-position: 0 84.297521%;
}

.flag-td {
  background-position: 0 84.710744%;
}

.flag-tf {
  background-position: 0 85.123967%;
}

.flag-tg {
  background-position: 0 85.53719%;
}

.flag-th {
  background-position: 0 85.950413%;
}

.flag-tj {
  background-position: 0 86.363636%;
}

.flag-tk {
  background-position: 0 86.77686%;
}

.flag-tl {
  background-position: 0 87.190083%;
}

.flag-tm {
  background-position: 0 87.603306%;
}

.flag-tn {
  background-position: 0 88.016529%;
}

.flag-to {
  background-position: 0 88.429752%;
}

.flag-tp {
  background-position: 0 88.842975%;
}

.flag-tr {
  background-position: 0 89.256198%;
}

.flag-tt {
  background-position: 0 89.669421%;
}

.flag-tv {
  background-position: 0 90.082645%;
}

.flag-tw {
  background-position: 0 90.495868%;
}

.flag-ty {
  background-position: 0 90.909091%;
}

.flag-tz {
  background-position: 0 91.322314%;
}

.flag-ua {
  background-position: 0 91.735537%;
}

.flag-ug {
  background-position: 0 92.14876%;
}

.flag-gb,
.flag-uk {
  background-position: 0 92.561983%;
}

.flag-um {
  background-position: 0 92.975207%;
}

.flag-us {
  background-position: 0 93.38843%;
}

.flag-uy {
  background-position: 0 93.801653%;
}

.flag-uz {
  background-position: 0 94.214876%;
}

.flag-va {
  background-position: 0 94.628099%;
}

.flag-vc {
  background-position: 0 95.041322%;
}

.flag-ve {
  background-position: 0 95.454545%;
}

.flag-vg {
  background-position: 0 95.867769%;
}

.flag-vi {
  background-position: 0 96.280992%;
}

.flag-vn {
  background-position: 0 96.694215%;
}

.flag-vu {
  background-position: 0 97.107438%;
}

.flag-wf {
  background-position: 0 97.520661%;
}

.flag-ws {
  background-position: 0 97.933884%;
}

.flag-ye {
  background-position: 0 98.347107%;
}

.flag-za {
  background-position: 0 98.760331%;
}

.flag-zm {
  background-position: 0 99.173554%;
}

.flag-zr {
  background-position: 0 99.586777%;
}

.flag-zw {
  background-position: 0 100%;
}

.fa-stack.fa-fw,
.fa-stack.fa-fw [class*=fa-] {
  width: 1.25em;
}

/**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
.highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0; /* #1072 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 12px;
  user-select: none;
  touch-action: manipulation;
  outline: none;
}

.highcharts-root {
  display: block;
}

.highcharts-root text {
  stroke-width: 0;
}

.highcharts-strong {
  font-weight: bold;
}

.highcharts-emphasized {
  font-style: italic;
}

.highcharts-anchor {
  cursor: pointer;
}

.highcharts-background {
  fill: #ffffff;
}

.highcharts-plot-border, .highcharts-plot-background {
  fill: none;
}

.highcharts-label-box {
  fill: none;
}

.highcharts-button-box {
  fill: inherit;
}

.highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  fill: none;
}

.highcharts-tracker-area {
  fill: rgba(192, 192, 192, 0.0001);
  stroke-width: 0;
}

/* Titles */
.highcharts-title {
  fill: #333333;
  font-size: 1.5em;
}

.highcharts-subtitle {
  fill: #666666;
  font-size: 1em;
}

/* Axes */
.highcharts-axis-line {
  fill: none;
  stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-axis-title {
  fill: #666666;
}

.highcharts-axis-labels {
  fill: #666666;
  cursor: default;
  font-size: 0.9em;
}

.highcharts-grid-line {
  fill: none;
  stroke: #e6e6e6;
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0px;
}

.highcharts-tick {
  stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}

.highcharts-minor-grid-line {
  stroke: #f2f2f2;
}

.highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: #cccccc;
}

.highcharts-crosshair-category {
  stroke: #ccd6eb;
  stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
  cursor: pointer;
  fill: #999999;
  font-size: 0.7em;
  transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
  fill: black;
  font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms;
}

.highcharts-tooltip text {
  fill: #333333;
}

.highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}

.highcharts-tooltip-box {
  stroke-width: 1px;
  fill: #f7f7f7;
  fill-opacity: 0.85;
}

.highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7;
  fill-opacity: 0.85;
}

div.highcharts-tooltip {
  filter: none;
}

.highcharts-selection-marker {
  fill: #335cad;
  fill-opacity: 0.25;
}

.highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.highcharts-empty-series {
  stroke-width: 1px;
  fill: none;
  stroke: #cccccc;
}

.highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}

.highcharts-point-inactive {
  opacity: 0.2;
  transition: opacity 50ms; /* quick in */
}

.highcharts-series-inactive {
  opacity: 0.2;
  transition: opacity 50ms; /* quick in */
}

.highcharts-state-hover path {
  transition: stroke-width 50ms; /* quick in */
}

.highcharts-state-normal path {
  transition: stroke-width 250ms; /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
  transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}

/* Series options */
/* Default colors */
.highcharts-color-0 {
  fill: "blue" var(--bs-blue);
  stroke: "blue" var(--bs-blue);
}

.highcharts-color-1 {
  fill: "indigo" var(--bs-indigo);
  stroke: "indigo" var(--bs-indigo);
}

.highcharts-color-2 {
  fill: "purple" var(--bs-purple);
  stroke: "purple" var(--bs-purple);
}

.highcharts-color-3 {
  fill: "pink" var(--bs-pink);
  stroke: "pink" var(--bs-pink);
}

.highcharts-color-4 {
  fill: "red" var(--bs-red);
  stroke: "red" var(--bs-red);
}

.highcharts-color-5 {
  fill: "orange" var(--bs-orange);
  stroke: "orange" var(--bs-orange);
}

.highcharts-color-6 {
  fill: "yellow" var(--bs-yellow);
  stroke: "yellow" var(--bs-yellow);
}

.highcharts-color-7 {
  fill: "green" var(--bs-green);
  stroke: "green" var(--bs-green);
}

.highcharts-color-8 {
  fill: "teal" var(--bs-teal);
  stroke: "teal" var(--bs-teal);
}

.highcharts-color-9 {
  fill: "cyan" var(--bs-cyan);
  stroke: "cyan" var(--bs-cyan);
}

.highcharts-color-10 {
  fill: "gray" var(--bs-gray);
  stroke: "gray" var(--bs-gray);
}

.highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}

.highcharts-markers {
  stroke-width: 1px;
  stroke: #ffffff;
}

.highcharts-a11y-markers-hidden .highcharts-point:not(.highcharts-point-hover):not(.highcharts-a11y-marker-visible),
.highcharts-a11y-marker-hidden {
  opacity: 0;
}

.highcharts-point {
  stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}

.highcharts-data-label {
  font-size: 0.9em;
  font-weight: bold;
}

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-data-label text, text.highcharts-data-label {
  fill: #333333;
}

.highcharts-data-label-connector {
  fill: none;
}

.highcharts-data-label-hidden {
  pointer-events: none;
}

.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
  fill: #cccccc;
  stroke: #000000;
}

.highcharts-column-series rect.highcharts-point {
  stroke: #ffffff;
}

.highcharts-column-series .highcharts-point {
  transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}

.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}

.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: #e6e6e6;
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
  stroke: #999999;
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
  display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}

.highcharts-vector-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}

.highcharts-windbarb-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}

.highcharts-lollipop-stem {
  stroke: #000000;
}

.highcharts-focus-border {
  fill: none;
  stroke-width: 2px;
}

.highcharts-legend-item-hidden .highcharts-focus-border {
  fill: none !important;
}

/* Legend */
.highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-legend-item > text {
  fill: #333333;
  font-weight: bold;
  font-size: 1.5rem;
  cursor: pointer;
  stroke-width: 0;
}

.highcharts-legend-item:hover text {
  fill: #000000;
}

.highcharts-legend-item-hidden * {
  fill: #cccccc !important;
  stroke: #cccccc !important;
  transition: fill 250ms;
}

.highcharts-legend-nav-active {
  fill: #003399;
  cursor: pointer;
}

.highcharts-legend-nav-inactive {
  fill: #cccccc;
}

circle.highcharts-legend-nav-active, circle.highcharts-legend-nav-inactive { /* tracker */
  fill: rgba(192, 192, 192, 0.0001);
}

.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}

/* Bubble legend */
.highcharts-bubble-legend-symbol {
  stroke-width: 2;
  fill-opacity: 0.5;
}

.highcharts-bubble-legend-connectors {
  stroke-width: 1;
}

.highcharts-bubble-legend-labels {
  fill: #333333;
}

/* Loading */
.highcharts-loading {
  position: absolute;
  background-color: #ffffff;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms;
}

.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band, .highcharts-pane {
  fill: #000000;
  fill-opacity: 0.05;
}

.highcharts-plot-line {
  fill: none;
  stroke: #999999;
  stroke-width: 1px;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
  fill: #ffffff;
}

.highcharts-boxplot-median {
  stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
  stroke: #000000;
}

.highcharts-gauge-series .highcharts-data-label-box {
  stroke: #cccccc;
  stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
  fill: #000000;
  stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
  stroke: #333333;
  stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
  transition: fill 250ms, fill-opacity 250ms;
  fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1;
}

.highcharts-venn-series .highcharts-point {
  fill-opacity: 0.75;
  stroke: #cccccc;
  transition: stroke 250ms, fill-opacity 250ms;
}

.highcharts-venn-series .highcharts-point-hover {
  fill-opacity: 1;
  stroke: #cccccc;
}

/* Highstock */
.highcharts-navigator-mask-outside {
  fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
  fill: #6685c2; /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
}

.highcharts-navigator-outline {
  stroke: #cccccc;
  fill: none;
}

.highcharts-navigator-handle {
  stroke: #cccccc;
  fill: #f2f2f2;
  cursor: ew-resize;
}

.highcharts-navigator-series {
  fill: #335cad;
  stroke: #335cad;
}

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #e6e6e6;
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: #999999;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-scrollbar-thumb {
  fill: #cccccc;
  stroke: #cccccc;
  stroke-width: 1px;
}

.highcharts-scrollbar-button {
  fill: #e6e6e6;
  stroke: #cccccc;
  stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
  fill: #666666;
}

.highcharts-scrollbar-rifles {
  stroke: #666666;
  stroke-width: 1px;
}

.highcharts-scrollbar-track {
  fill: #f2f2f2;
  stroke: #f2f2f2;
  stroke-width: 1px;
}

.highcharts-button {
  fill: #f7f7f7;
  stroke: #cccccc;
  cursor: default;
  stroke-width: 1px;
  transition: fill 250ms;
}

.highcharts-button text {
  fill: #333333;
}

.highcharts-button-hover {
  transition: fill 0ms;
  fill: #e6e6e6;
  stroke: #cccccc;
}

.highcharts-button-hover text {
  fill: #333333;
}

.highcharts-button-pressed {
  font-weight: bold;
  fill: #e6ebf5;
  stroke: #cccccc;
}

.highcharts-button-pressed text {
  fill: #333333;
  font-weight: bold;
}

.highcharts-button-disabled text {
  fill: #333333;
}

.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0px;
}

.highcharts-range-label rect {
  fill: none;
}

.highcharts-range-label text {
  fill: #666666;
}

.highcharts-range-input rect {
  fill: none;
}

.highcharts-range-input text {
  fill: #333333;
}

.highcharts-range-input {
  stroke-width: 1px;
  stroke: #cccccc;
}

input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px; /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em; /* #4798 */
}

.highcharts-crosshair-label text {
  fill: #ffffff;
  font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
  stroke: #000000;
  stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
  fill: #ffffff;
}

.highcharts-hollowcandlestick-series .highcharts-point-down {
  fill: #f21313;
  stroke: #f21313;
}

.highcharts-hollowcandlestick-series .highcharts-point-down-bearish-up {
  fill: #06b535;
  stroke: #06b535;
}

.highcharts-hollowcandlestick-series .highcharts-point-up {
  fill: transparent;
  stroke: #06b535;
}

.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: #999999;
  fill: #ffffff;
  transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: #000000;
  fill: #ccd6eb;
}

.highcharts-flags-series .highcharts-point text {
  fill: #000000;
  font-size: 0.9em;
  font-weight: bold;
}

/* Highcharts Maps */
.highcharts-map-series .highcharts-point {
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: #cccccc;
  stroke-width: inherit;
}

.highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
}

.highcharts-mapline-series .highcharts-point {
  fill: none;
}

.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}

.highcharts-mapview-inset-border {
  stroke: #cccccc;
  stroke-width: 1px;
  fill: none;
}

.highcharts-coloraxis {
  stroke-width: 0;
}

.highcharts-coloraxis-marker {
  fill: #999999;
}

.highcharts-null-point {
  fill: #f7f7f7;
}

/* 3d charts */
.highcharts-3d-frame {
  fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
  fill: #ffffff; /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;
}

.highcharts-contextbutton:hover {
  fill: #e6e6e6;
  stroke: #e6e6e6;
}

.highcharts-button-symbol {
  stroke: #666666;
  stroke-width: 3px;
}

.highcharts-menu {
  border: 1px solid #999999;
  background: #ffffff;
  padding: 5px 0;
  box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: #333333;
  cursor: pointer;
  transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
  background: #335cad;
  color: #ffffff;
}

/* Breadcrumbs */
.highcharts-breadcrumbs-button {
  fill: none;
  stroke-width: 0;
  cursor: pointer;
}

.highcharts-breadcrumbs-separator {
  fill: #666666;
}

/* Drilldown module */
.highcharts-drilldown-point {
  cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: #003399;
  font-weight: bold;
  text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
  font-weight: bold;
  font-size: 12px;
  fill: #666666;
}

/* Drag-panes module */
.highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
  stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: #333333;
}

.highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: #333333;
}

/* Annotations module */
.highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: #000000;
  fill: #000000;
  fill-opacity: 0.75;
}

.highcharts-annotation-label text {
  fill: #e6e6e6;
}

/* A11y module */
.highcharts-a11y-proxy-button {
  border-width: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  opacity: 0.001;
  z-index: 999;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: block;
  position: absolute;
}

.highcharts-a11y-proxy-group li {
  list-style: none;
}

.highcharts-visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(1px, 1px, 1px, 1px);
  margin-top: -3px;
  opacity: 0.01;
}

.highcharts-a11y-invisible {
  visibility: hidden;
}

.highcharts-a11y-proxy-container,
.highcharts-a11y-proxy-container-before,
.highcharts-a11y-proxy-container-after {
  position: absolute;
  white-space: nowrap;
}

g.highcharts-series, .highcharts-markers, .highcharts-point {
  outline: none;
}

/* Gantt */
.highcharts-treegrid-node-collapsed, .highcharts-treegrid-node-expanded {
  cursor: pointer;
}

.highcharts-point-connecting-path {
  fill: none;
}

.highcharts-grid-axis .highcharts-tick {
  stroke-width: 1px;
}

.highcharts-grid-axis .highcharts-axis-line {
  stroke-width: 1px;
}

highcharts-chart {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ng-select.ng-invalid.ng-touched:not(.ng-select-opened) .ng-select-container, .ng-select.ng-select-multiple.ng-invalid.ng-touched:not(.ng-select-opened) .ng-select-container {
  border-color: var(--bs-danger);
}
.ng-select .ng-select-container, .ng-select.ng-select-multiple .ng-select-container {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-radius: 0.6rem;
  border: 1px solid var(--bs-gray-400);
}
.ng-select .ng-select-container .ng-value-container .ng-input > input, .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder, .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--bs-body-color);
  margin-top: 0.25rem;
}
.ng-select .ng-value-container .hide-on-trigger, .ng-select.ng-select-multiple .ng-value-container .hide-on-trigger {
  display: none;
}
.ng-select.ng-select-opened > .ng-select-container, .ng-select.ng-select-multiple.ng-select-opened > .ng-select-container {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
}
.ng-select.ng-select-disabled > .ng-select-container, .ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container {
  background-color: var(--bs-body-bg-strong);
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container, .ng-select.ng-select-multiple.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-component-active-bg-rgb), 0.25);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--bs-body-bg-muted);
  color: var(--bs-link-color-hover);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--bs-component-active-bg);
  color: var(--bs-component-active-color);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-weight: 600;
  font-size: 100%;
}

.ng-dropdown-panel .scroll-host {
  --scrollbar-bg: var(--bs-body-bg);
  --scrollbar-thumb-bg: var(--bs-gray-400);
  --scrollbar-thumb-bg-hover: var(--bs-gray-500);
}

.ng-dropdown-footer {
  background-color: var(--bs-body-bg);
}

.ng-optgroup .ng-option-label {
  font-weight: bold;
}

.input-group .ng-select,
.input-group .ng-select-container {
  position: unset;
}
.input-group:not(.has-validation) > .ng-select:not(:last-child) .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group:not(.has-validation) > .ng-select:not(:first-child) .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select-options-wrap .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
}
.select-options-wrap.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  display: flex;
  white-space: unset;
}
.select-options-wrap.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: flex;
  align-items: center;
}

ngx-dropzone {
  border-color: var(--bs-border-color) !important;
}

ngx-dropzone-preview {
  overflow: hidden;
}

ngx-dropzone-label {
  max-width: 100%;
}

ngx-dropzone,
ngx-dropzone-label {
  background-color: var(--bs-body-bg-muted) !important;
  color: var(--bs-body-color) !important;
}

editor .tox-tinymce {
  border: 1px solid var(--bs-gray-400);
  border-radius: 0.6rem;
  color: var(--bs-body-color);
}
editor .tox .tox-toolbar-overlord,
editor .tox .tox-edit-area__iframe,
editor .tox .tox-edit-area__iframe .mce-content-body,
editor .tox .tox-sidebar,
editor .tox .tox-statusbar {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
}
editor .tox .tox-toolbar,
editor .tox .tox-editor-header,
editor .tox .tox-toolbar-overlord > .tox-toolbar__primary, editor .tox:not(.tiny-mce-inline) .tox-editor-header {
  background-color: var(--bs-body-bg-muted);
  color: var(--bs-body-color);
}
editor .tox .tox-editor-header, editor .tox:not(.tiny-mce-inline) .tox-editor-header {
  box-shadow: none;
  border-bottom: 1px solid var(--bs-gray-400);
}
editor .tox .tox-tbtn,
editor .tox .tox-tbtn:hover,
editor .tox .tox-tbtn--enabled {
  color: var(--bs-body-color);
}
editor .tox .tox-tbtn svg,
editor .tox .tox-tbtn:hover svg,
editor .tox .tox-tbtn--enabled svg {
  fill: var(--bs-body-color);
}
editor .tox .tox-tbtn {
  background-color: var(--bs-body-bg);
}
editor .tox .tox-tbtn:hover,
editor .tox .tox-tbtn--enabled {
  background-color: var(--bs-body-bg-strong);
}
editor .tox .tox-tbtn--disabled svg,
editor .tox .tox-tbtn--disabled:hover svg,
editor .tox .tox-tbtn:disabled svg,
editor .tox .tox-tbtn:disabled:hover svg {
  fill: var(--bs-body-color);
}
editor .tox .tox-split-button.tox-tbtn--disabled, editor .tox .tox-split-button.tox-tbtn--disabled:focus, editor .tox .tox-split-button.tox-tbtn--disabled:hover,
editor .tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:focus,
editor .tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:hover,
editor .tox .tox-split-button.tox-tbtn--disabled svg,
editor .tox .tox-tbtn--disabled,
editor .tox .tox-tbtn--disabled:focus,
editor .tox .tox-tbtn--disabled:hover,
editor .tox .tox-tbtn--disabled .tox-tbtn:focus,
editor .tox .tox-tbtn--disabled .tox-tbtn:hover,
editor .tox .tox-tbtn--disabled svg,
editor .tox .tox-tbtn:disabled,
editor .tox .tox-tbtn:disabled:focus,
editor .tox .tox-tbtn:disabled:hover,
editor .tox .tox-tbtn:disabled .tox-tbtn:focus,
editor .tox .tox-tbtn:disabled .tox-tbtn:hover,
editor .tox .tox-tbtn:disabled svg {
  background: var(--bs-body-bg);
}